import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import LoadingModal from "../../../loader/LoadingModal";
import {
  AddRecipeURL,
  GetProductAndVariantsURL,
  ScriptId,
  ScriptSRC,
} from "../../../assets/api/BaseURL";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";
import Select from "react-select";

const areaOptions = [
  { value: "dine_in", label: "Dine in" },
  { value: "delivery", label: "Delivery" },
  { value: "take_away", label: "Take away" },
];

const AddRecipe = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [recipeItems, setRecipeItems] = useState([]);
  const [rawMaterialsOptions, setRawMaterialsOptions] = useState([]);
  // const [measurementOptions, setMeasurementOptions] = useState([]);
  const [variantsOption, setVariantsOption] = useState([]);
  const [formData, setFormData] = useState({
    product: "",
    variant: "",
    status: true,
  });
  const [recipeItem, setRecipeItem] = useState({
    raw_material_id: "",
    raw_material_name: "",
    quantity: "",
    unit: "",
    unit_name: "",
    area: [],
  });
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleAreaChange = (selected) => {
    setRecipeItem((prevData) => ({
      ...prevData,
      area: selected,
    }));
    setError("");
    setSuccess("");
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/recipes");
  };

  // const handleProductChange = (e) => {
  //   const selectedProduct = e.target.value;
  //   setFormData({
  //     ...formData,
  //     product: selectedProduct,
  //     variant: "",
  //   });

  //   const selectedProductData = data.find(
  //     (item) => item.product_id === parseInt(selectedProduct)
  //   );

  //   if (selectedProductData) {
  //     setVariantsOption(selectedProductData.variants || []);
  //   } else {
  //     setVariantsOption([]);
  //   }
  //   setError("");
  //   setSuccess("");
  // };

  const handleSelectProduct = (selectedOption) => {
    setSelectedProduct(selectedOption);

    setFormData({
      ...formData,
      product: selectedOption.product_id,
      variant: "",
    });

    if (selectedOption) {
      setVariantsOption(selectedOption.variants || []);
    } else {
      setVariantsOption([]);
    }

    setError("");
    setSuccess("");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setError("");
    setSuccess("");
  };

  const handleItemChange = (e) => {
    const { name, value } = e.target;

    if (name === "raw_material_id") {
      const selectedRawMaterial = rawMaterialsOptions.find(
        (item) => item.id === parseInt(value)
      );
      const updatedUnit = selectedRawMaterial
        ? selectedRawMaterial.consumption_unit
        : "";
      const updatedUnitName = selectedRawMaterial
        ? selectedRawMaterial.unit_name
        : "";
      const materialName = selectedRawMaterial
        ? selectedRawMaterial.material_name
        : "";
      setRecipeItem((prevData) => ({
        ...prevData,
        [name]: value,
        unit: updatedUnit,
        raw_material_name: materialName,
        unit_name: updatedUnitName,
      }));
    } else {
      setRecipeItem((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setError("");
    setSuccess("");
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...recipeItems];
    updatedItems.splice(index, 1);
    setRecipeItems(updatedItems);
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleAddItemClick = (e) => {
    e.preventDefault();

    if (recipeItem.raw_material_id === "") {
      setError("Please select raw material");
      setSuccess("");
    } else if (
      recipeItems.some(
        (item) => item.raw_material_id === recipeItem.raw_material_id
      )
    ) {
      setError("An item with the same raw material has already been added");
      setSuccess("");
    } else if (recipeItem.quantity === "") {
      setError("Quantity can not be empty");
      setSuccess("");
    } else if (!isValidNumber(recipeItem.quantity)) {
      setError("Please enter valid Quantity");
      setSuccess("");
    } else if (recipeItem.unit === "") {
      setError("Please select measurement unit");
      setSuccess("");
    } else {
      recipeItems.push(recipeItem);
      setRecipeItem({
        raw_material_id: "",
        raw_material_name: "",
        quantity: "",
        unit: "",
        unit_name: "",
        area: [],
      });
    }
  };

  const handleAddRecipe = async (e) => {
    e.preventDefault();

    if (formData.product === "") {
      setError("Please select product");
      setSuccess("");
    } else if (recipeItems.length === 0) {
      setError("Please add recipe items");
      setSuccess("");
    } else {
      const data = {
        product_id: formData.product,
        variant_id: formData.variant,
        status: formData.status ? 1 : 0,
        recipes_items: recipeItems,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddRecipeURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setRecipeItems([]);
          setSelectedProduct(null);
          setVariantsOption([]);
          setFormData({
            product: "",
            variant: "",
            status: true,
          });
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetProductAndVariantsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.productList) {
            const modifiedData = response.data.productList.map((item) => ({
              ...item,
              value: item.product_id,
              label: item.product_name,
            }));
            setData(modifiedData);
            setRawMaterialsOptions(response.data.rawMaterialsList);
            // setMeasurementOptions(response.data.measurement_unit);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="recipes" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Recipes</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    Add Recipe
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-danger mb-0 me-0"
                                    onClick={handleBackClick}
                                  >
                                    <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                    Go Back
                                  </span>
                                </div>
                              </div>
                              <form className="forms-sample">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label>
                                        Select Product
                                        <span className="text-danger">*</span>
                                      </label>
                                      {/* <select
                                        className="form-select"
                                        name="product"
                                        value={formData.product}
                                        onChange={handleProductChange}
                                      >
                                        <option value="">Select Product</option>
                                        {data.map((item) => (
                                          <option
                                            key={item.product_id}
                                            value={item.product_id}
                                          >
                                            {item.product_name}
                                          </option>
                                        ))}
                                      </select> */}
                                      <Select
                                        options={data}
                                        value={selectedProduct}
                                        onChange={handleSelectProduct}
                                      />
                                    </div>
                                  </div>
                                  {variantsOption.length !== 0 && (
                                    <div className="col-6">
                                      <div className="form-group">
                                        <label>Select Variant</label>
                                        <select
                                          className="form-select"
                                          name="variant"
                                          value={formData.variant}
                                          onChange={handleInputChange}
                                        >
                                          <option value="">
                                            Select Variant
                                          </option>
                                          {variantsOption.map((item) => (
                                            <option
                                              key={item.variant_id}
                                              value={item.variant_id}
                                            >
                                              {/* {item.variant_name} */}
                                              {item.combination_details.map(
                                                (detail, index) => (
                                                  <React.Fragment key={index}>
                                                    {detail.attribute_name}(
                                                    {
                                                      detail.attribute_value_name
                                                    }
                                                    )
                                                    {index !==
                                                      item.combination_details
                                                        .length -
                                                      1 && " - "}
                                                  </React.Fragment>
                                                )
                                              )}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {recipeItems.map((item, index) => (
                                  <div key={index} className="row">
                                    <div className="col-lg-2 col-md-2">
                                      {/* <div className="form-group">
                                        <select
                                          className="form-select"
                                          name="raw_material_id"
                                          value={item.raw_material_id}
                                          readOnly={true}
                                        >
                                          <option value="">
                                            Select Raw Material
                                          </option>
                                          {rawMaterialsOptions.map((item) => (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.material_name}
                                            </option>
                                          ))}
                                        </select>
                                      </div> */}
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={item.raw_material_name}
                                          readOnly={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={item.quantity}
                                          readOnly={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                      {/* <div className="form-group">
                                        <select
                                          className="form-select"
                                          name="unit"
                                          value={item.unit}
                                          readOnly={true}
                                        >
                                          <option value="">
                                            Select Measurement Unit
                                          </option>
                                          {measurementOptions.map((item) => (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.unit_name}
                                            </option>
                                          ))}
                                        </select>
                                      </div> */}
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={item.unit_name}
                                          readOnly={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                      <div className="form-group">
                                        <Select
                                          value={item.area}
                                          isMulti
                                          isDisabled
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                      <button
                                        className="btn btn btn-danger btn-sm btnMarginTop0"
                                        onClick={() => handleDeleteItem(index)}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                ))}

                                <div className="row">
                                  <div className="col-lg-2 col-md-2">
                                    <div className="form-group">
                                      <select
                                        className="form-select"
                                        name="raw_material_id"
                                        value={recipeItem.raw_material_id}
                                        onChange={handleItemChange}
                                      >
                                        <option value="">
                                          Select Raw Material
                                        </option>
                                        {rawMaterialsOptions.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.material_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Quantity"
                                        name="quantity"
                                        value={recipeItem.quantity}
                                        onChange={handleItemChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    {/* <div className="form-group">
                                      <select
                                        className="form-select"
                                        name="unit"
                                        value={recipeItem.unit}
                                        readOnly={true}
                                        // onChange={handleItemChange}
                                      >
                                        <option value="">
                                          Select Measurement Unit
                                        </option>
                                        {measurementOptions.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.unit_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div> */}
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={recipeItem.unit_name}
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <Select
                                        value={recipeItem.area}
                                        onChange={handleAreaChange}
                                        options={areaOptions}
                                        placeholder="Select Area"
                                        isMulti
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <button
                                      className="btn btn-info btn-sm btnMarginTop0 mb-2"
                                      onClick={handleAddItemClick}
                                    >
                                      <i className="menu-icon mdi mdi-plus iconalg"></i>
                                      Add
                                    </button>
                                  </div>
                                </div>
                                {/* <div className="row">
                                  <div className="col-6">
                                    <div className="form-group togglecss">
                                      <label>Status</label>
                                      <div className="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          name="status"
                                          checked={formData.status}
                                          onChange={handleInputChange}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleAddRecipe}
                                >
                                  Submit
                                </button>
                                <button
                                  className="btn btn btn-secondary"
                                  onClick={handleBackClick}
                                >
                                  Cancel
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default AddRecipe;
