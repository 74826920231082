import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import LoadingModal from "../../../loader/LoadingModal";
import {
  AddRawMaterialURL,
  GetRawMaterialUnitListURL,
  ScriptId,
  ScriptSRC,
} from "../../../assets/api/BaseURL";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";

const AddRawMaterial = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [measurementOptions, setMeasurementOptions] = useState([]);
  const [taxOptions, setTaxOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [openingStockSubmit, setOpeningStockSubmit] = useState(false);
  const [openingStockCheckModel, setOpeningStockCheckModel] = useState(false);



  const [formData, setFormData] = useState({
    categoryType: "",
    measurementUnit: "",
    taxType: "",
    materialName: "",
    purchasePrice: "",
    salesPrice: "0",
    expiryDate: "",
    minimumStock: "",
    hsnCode: "",
    brand: "",
    conversionRate: "",
    consumptionUnit: "",
    status: true,
    description: "",
    expiryAlert: "",
    addToStock: true,
    openingStock: "0",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/raw_material");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox" && name === "addToStock" && !checked) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
        openingStock: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
    setError("");
    setSuccess("");
  };

  // function getToday() {
  //   const today = new Date();
  //   const yyyy = today.getFullYear();
  //   let mm = today.getMonth() + 1;
  //   let dd = today.getDate();

  //   if (mm < 10) mm = "0" + mm;
  //   if (dd < 10) dd = "0" + dd;

  //   return `${yyyy}-${mm}-${dd}`;
  // }

  function formatDate(inputDate) {
    const dateParts = inputDate.split("-");
    if (dateParts.length === 3) {
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const formattedDate = `${day} ${months[parseInt(month) - 1]} ${year}`;
      return formattedDate;
    }

    return inputDate;
  }

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleAddRawMaterial = async (e) => {
    try{
      e.preventDefault();
    }catch(err){

    }
  

    console.log("formData")
    console.log(formData)


    if (formData.materialName === "") {
      setError("Material Name can not be empty");
      setSuccess("");
    } else if (formData.categoryType === "") {
      setError("Category Type can not be empty");
      setSuccess("");
    } else if (formData.measurementUnit === "") {
      setError("Intake Measurement Unit can not be empty");
      setSuccess("");
    } else if (formData.consumptionUnit === "") {
      setError("Consumption Unit can not be empty");
      setSuccess("");
    } else if (formData.conversionRate === "") {
      setError("Conversion Rate can not be empty");
      setSuccess("");
    } else if (!isValidNumber(formData.conversionRate)) {
      setError("Please enter valid Conversion Rate number");
      setSuccess("");
    } else if (formData.taxType === "") {
      setError("Tax Type can not be empty");
      setSuccess("");
    } else if (formData.minimumStock === "") {
      setError("Minimum Stock can not be empty");
      setSuccess("");
    } else if (!isValidNumber(formData.minimumStock)) {
      setError("Please enter valid Minimum Stock number");
      setSuccess("");
    } else if (formData.purchasePrice === "") {
      setError("Purchase Price can not be empty");
      setSuccess("");
    } else if (!isValidNumber(formData.purchasePrice)) {
      setError("Please enter valid Purchase Price");
      setSuccess("");
    } else if (formData.hsnCode === "") {
      setError("HSN Code can not be empty");
      setSuccess("");
    }
    // else if (formData.brand === "") {
    //   setError("Brand can not be empty");
    //   setSuccess("");
    // } else if (formData.expiryDate === "") {
    //   setError("Expiry Date can not be empty");
    //   setSuccess("");
    // } else if (formData.expiryAlert === "") {
    //   setError("Expiry Alert Before can not be empty");
    //   setSuccess("");
    // }
    else if (formData.expiryAlert && !isValidNumber(formData.expiryAlert)) {
      setError("Please enter valid No. of days for Expiry Alert");
      setSuccess("");
    } else if (formData.addToStock && formData.openingStock === "") {
      setError("Opening Stock can not be empty");
      setSuccess("");
    } else if (formData.addToStock && !isValidNumber(formData.openingStock)) {
      setError("Please enter valid Opening Stock number");
      setSuccess("");
    } else {
      const data = {
        material_name: formData.materialName,
        description: formData.description,
        category_id: formData.categoryType,
        measurement_unit_id: formData.measurementUnit,
        consumption_unit: formData.consumptionUnit,
        conversion_rate: formData.conversionRate,
        tax_id: formData.taxType,
        minimum_stock: formData.minimumStock,
        purchase_price: formData.purchasePrice,
        sales_price: formData.salesPrice,
        hsn_code: formData.hsnCode,
        brand: formData.brand,
        expiry_date: formData.expiryDate ? formatDate(formData.expiryDate) : "",
        status: formData.status ? 1 : 0,
        expiry_alert_before: formData.expiryAlert,
        opening_stock: parseFloat(formData.openingStock) <= 0 ? 0 : parseFloat(formData.openingStock),
      };

      if (openingStockSubmit || parseFloat(formData.openingStock) > 0) {
        try {
          const response = await axios({
            method: "post",
            url: AddRawMaterialURL,
            data: data,
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          });
          setOpeningStockCheckModel(false)
          setOpeningStockSubmit(false)

          if (response.status === 200 && response.data.message) {
            setError("");
            setSuccess(response.data.message);
            setFormData({
              categoryType: "",
              measurementUnit: "",
              taxType: "",
              materialName: "",
              purchasePrice: "",
              salesPrice: "0",
              expiryDate: "",
              minimumStock: "",
              hsnCode: "",
              brand: "",
              conversionRate: "",
              consumptionUnit: "",
              status: true,
              description: "",
              expiryAlert: "",
              addToStock: true,
              openingStock: "",
            });
          }
          // setOpeningStockCheckModel(false)
          // setOpeningStockSubmit(false)
        } catch (error) {
           setOpeningStockCheckModel(false)
          setOpeningStockSubmit(false)
          if (error.response && error.response.data.message) {
            setError(error.response.data.message);
            setSuccess("");
          } else {
            setError(error.message);
            setSuccess("");
          }
          console.log(error);
        }
      } else {
        if (parseFloat(formData.openingStock) <= 0) {
          setOpeningStockCheckModel(true)
        }
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetRawMaterialUnitListURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data) {
            setCategoryOptions(response.data.rawMaterialsCategoryList);
            setMeasurementOptions(response.data.measurementUnitList);
            setTaxOptions(response.data.taxList);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  useEffect(() => {
    if (openingStockSubmit) {
      handleAddRawMaterial()
       
    }
  }, [openingStockSubmit]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="raw_material" toggleCanvas={canvas} />

              <div className="main-panel">

                {openingStockCheckModel &&

                  <div className="popup-modal">
                    <div className="popup-modal-content">
                      <h4 className="content">The Default Value of Opening Stock is 0. <br/>Do you want to proceed ?</h4>
                      <div className="popup_btn_container">
                        <button className="btn btn-primary me-2" onClick={()=>{setOpeningStockSubmit(true)}}>Yes</button>
                        <button className="btn btn btn-secondary" onClick={()=>{setOpeningStockCheckModel(false)}}>No</button>
                      </div>
                    </div>
                  </div>
                }


                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Raw Material</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    Add Raw Materials
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-danger mb-0 me-0"
                                    onClick={handleBackClick}
                                  >
                                    <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                    Go Back
                                  </span>
                                </div>
                              </div>
                              <form className="forms-sample">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Material Name
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Material Name"
                                        name="materialName"
                                        value={formData.materialName}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Select Category Type
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="categoryType"
                                        value={formData.categoryType}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">
                                          Select Category Type
                                        </option>
                                        {categoryOptions.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.category_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Description</label>
                                      <textarea
                                        className="form-control"
                                        placeholder="Enter Description"
                                        rows="1"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Select Tax Type
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="taxType"
                                        value={formData.taxType}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">
                                          Select Tax Type
                                        </option>
                                        {taxOptions.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.tax_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Minimum Stock
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Minimum Stock"
                                        name="minimumStock"
                                        value={formData.minimumStock}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Purchase Price
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Purchase Price"
                                          name="purchasePrice"
                                          value={formData.purchasePrice}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Sales Price
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Sales Price"
                                          name="salesPrice"
                                          value={formData.salesPrice}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        HSN Code
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter HSN Code"
                                        name="hsnCode"
                                        value={formData.hsnCode}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Brand
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Brand"
                                        name="brand"
                                        value={formData.brand}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Intake Measurement Unit
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="measurementUnit"
                                        value={formData.measurementUnit}
                                        onChange={handleInputChange}
                                      >
                                        <option value="" disabled={true}>
                                          Select Measurement Unit
                                        </option>
                                        {measurementOptions.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.unit_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Consumption Unit
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="consumptionUnit"
                                        value={formData.consumptionUnit}
                                        onChange={handleInputChange}
                                      >
                                        <option value="" disabled={true}>
                                          Select Measurement Unit
                                        </option>
                                        {measurementOptions.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.unit_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Conversion Rate
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Conversion Rate"
                                        name="conversionRate"
                                        value={formData.conversionRate}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Expiry Date
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        name="expiryDate"
                                        value={formData.expiryDate}
                                        onChange={handleInputChange}
                                        min={getToday()}
                                      />
                                    </div>
                                  </div> */}
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Expiry Alert Before(No. of Days)
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter No. of Days"
                                        name="expiryAlert"
                                        value={formData.expiryAlert}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    {/* <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="addToStock"
                                        checked={formData.addToStock}
                                        onChange={handleInputChange}
                                      />
                                      <label className="form-check-label">
                                        Opening Stock
                                      </label>
                                    </div> */}
                                    {formData.addToStock && (
                                      <>
                                        <div className="form-group mt-2">
                                          <label className="form-check-label">
                                            Opening Stock
                                            <span className="text-danger">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Opening Stock"
                                            name="openingStock"
                                            value={formData.openingStock}
                                            onChange={handleInputChange}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group togglecss">
                                      <label>Status</label>
                                      <div className="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          name="status"
                                          checked={formData.status}
                                          onChange={handleInputChange}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>

                                  <label className="text-danger form-group">
                                    Please fill these fields (Intake Measurement Unit, Consumption Unit, and Conversion Rate) very carefully, these fields will not change in future.
                                  </label>


                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleAddRawMaterial}
                                >
                                  Submit
                                </button>
                                <button
                                  className="btn btn btn-secondary"
                                  onClick={handleBackClick}
                                >
                                  Cancel
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default AddRawMaterial;
