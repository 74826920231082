import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {
  GetManageTablesURL,
  UpdateManageTableNameURL,
  // UpdateQrEnableURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import QRCodeGenerator from "../other/QRCodeGenerator";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const ManageTables = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [planDetails, setPlanDetails] = useState(null);
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [data, setData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [qrEnable, setQrEnable] = useState(true);
  const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const availableRecordsPerPageOptions = [10, 25, 50, 100];
  const [totalPages, setTotalPages] = useState(1);
  const [totalrecords, setTotalRecords] = useState(0);
  const [pageNumbersBatch, setPageNumbersBatch] = useState([]);

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateStartSerialNumber = () => {
    return (currentPage - 1) * recordsPerPage + 1;
  };

  const handleInputChange = (id, event) => {
    setData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return { ...item, table_no: event.target.value };
        }
        return item;
      });
    });
    setError("");
    setSuccess("");
  };

  const handleCategoryChange = (id, event) => {
    setData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return { ...item, categoryId: event.target.value };
        }
        return item;
      });
    });
    setError("");
    setSuccess("");
  };

  const handleTableTypeChange = (id, event) => {
    setData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return { ...item, type: event.target.value };
        }
        return item;
      });
    });
    setError("");
    setSuccess("");
  };

  const handleTableNameChange = (id, event) => {
    setData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return { ...item, table_name: event.target.value };
        }
        return item;
      });
    });
    setError("");
    setSuccess("");
  };

  const handleAddTableClick = (e) => {
    e.preventDefault();
    navigate("/add_manage_tables");
  };

  const handleUpdateTableName = async (e, item) => {
    e.preventDefault();

    if (item.table_no === "") {
      setError("Table Number can not be empty");
      setSuccess("");
    } else if (item.type === null || item.type === "") {
      setError("Please select Table Type");
      setSuccess("");
    }
    // else if (item.categoryId === null || item.categoryId === "") {
    //   setError("Please select Table Category");
    //   setSuccess("");
    // }
    else {
      const data = {
        id: item.id,
        table_name: item.table_name,
        table_no: item.table_no,
        category_id: item.categoryId,
        type: item.type,
      };
      try {
        const response = await axios({
          method: "put",
          url: UpdateManageTableNameURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  // const handleToggleQrEnable = async (e) => {
  //   const dataU = { enable_qr: e.target.checked ? 1 : 0 };

  //   setIsLoading(true);
  //   try {
  //     const response = await axios({
  //       method: "put",
  //       url: UpdateQrEnableURL,
  //       data: dataU,
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (response.status === 200) {
  //       // setFlag(!flag);
  //       setQrEnable(dataU.enable_qr ? true : false);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data.message) {
  //       addToast("error", error.response.data.message);
  //     } else {
  //       addToast("error", error.message);
  //     }
  //     console.log(error);
  //   }
  //   setIsLoading(false);
  // };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("planDetails")) {
      setPlanDetails(JSON.parse(localStorage.getItem("planDetails")));
    }
  }, []);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetManageTablesURL, {
            params: {
              page: currentPage,
              limit: recordsPerPage,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setCategoryList(response.data.tableCategoryData);
            setTotalRecords(response.data.totalRecord);
            setQrEnable(response.data.enableQr ? true : false);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
            setTotalRecords(0);
            setQrEnable(response.data.enableQr ? true : false);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, currentPage, recordsPerPage, addToast]);

  useEffect(() => {
    if (totalrecords) {
      const totalPages = Math.ceil(totalrecords / recordsPerPage);
      setTotalPages(totalPages);

      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      } else if (currentPage < 1) {
        setCurrentPage(1);
      }

      const maxPageNumbers = 1;
      let startPage = Math.max(currentPage - maxPageNumbers, 1);
      let endPage = Math.min(currentPage + maxPageNumbers, totalPages);

      if (endPage - startPage + 1 <= maxPageNumbers * 2) {
        if (startPage === 1) {
          endPage = Math.min(
            endPage + (maxPageNumbers * 2 - endPage + startPage - 1),
            totalPages
          );
        } else if (endPage === totalPages) {
          startPage = Math.max(
            startPage - (maxPageNumbers * 2 - endPage + startPage - 1),
            1
          );
        }
      }

      if (currentPage === 1) {
        endPage = Math.min(3, totalPages);
      }

      if (currentPage === totalPages && totalPages >= 3) {
        startPage = Math.max(currentPage - 2, 1);
        endPage = totalPages;
      }

      const batch = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
      setPageNumbersBatch(batch);
    }
  }, [currentPage, recordsPerPage, totalrecords, totalPages]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="manage_tables" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Manage tables</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    All Manage tables
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  {/* <div className="form-group togglecss mb-0">
                                    <label>QR Enable</label>
                                    <div className="button r" id="button-1">
                                      <input
                                        type="checkbox"
                                        className="checkbox"
                                        name="qrEnable"
                                        checked={qrEnable}
                                        onChange={(e) =>
                                          handleToggleQrEnable(e)
                                        }
                                      />
                                      <div className="knobs"></div>
                                      <div className="layer"></div>
                                    </div>
                                  </div> */}
                                  {!qrEnable && (
                                    <>
                                      {planDetails &&
                                        planDetails.callwaiter_settings ===
                                        1 && (
                                          <h6>
                                            QR Code is not enable to enable{" "}
                                            <Link
                                              to="/qrcode_settings"
                                              style={{ color: "#0d6efd" }}
                                            >
                                              click
                                            </Link>{" "}
                                            here
                                          </h6>
                                        )}
                                    </>
                                  )}
                                </div>
                                <div className="vsalign">
                                  {planDetails &&
                                    totalrecords <
                                    planDetails.number_of_qrcode && (
                                      <span
                                        className="btn btn-primary text-white mb-0 me-0"
                                        onClick={handleAddTableClick}
                                      >
                                        <i className="menu-icon mdi mdi-table vsalign"></i>
                                        Add Table
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="table-responsive">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div>
                                  <label>Show records per page:</label>
                                  <select
                                    value={recordsPerPage}
                                    onChange={(e) =>
                                      setRecordsPerPage(
                                        parseInt(e.target.value)
                                      )
                                    }
                                  >
                                    {availableRecordsPerPageOptions.map(
                                      (option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <table className="table select-table">
                                  <thead>
                                    <tr>
                                      <th>S.No.</th>
                                      <th>Table Name</th>
                                      <th>Table Number</th>
                                      <th>Table Type</th>
                                      <th>Table Category</th>
                                      <th>QR Code</th>
                                      <th className="text-right">Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {data.length === 0 ? (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No data found
                                        </td>
                                      </tr>
                                    ) : (
                                      data.map((item, index) => (
                                        <tr key={item.id}>
                                          <td>
                                            {calculateStartSerialNumber() +
                                              index}
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Name"
                                              value={item.table_name || ""}
                                              onChange={(e) =>
                                                handleTableNameChange(
                                                  item.id,
                                                  e
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Number"
                                              value={item.table_no}
                                              onChange={(e) =>
                                                handleInputChange(item.id, e)
                                              }
                                            />
                                          </td>
                                          <td>
                                            <select
                                              className="form-select"
                                              value={item.type}
                                              onChange={(e) =>
                                                handleTableTypeChange(
                                                  item.id,
                                                  e
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select Table Type
                                              </option>
                                              <option value="0">Table</option>
                                              <option value="1">Counter</option>
                                            </select>
                                          </td>
                                          <td>
                                            <select
                                              className="form-select"
                                              value={item.categoryId || ""}
                                              onChange={(e) =>
                                                handleCategoryChange(item.id, e)
                                              }
                                            >
                                              <option value="">
                                                Select Table Category
                                              </option>
                                              {categoryList &&
                                                categoryList.map((item) => (
                                                  <option
                                                    key={item.id}
                                                    value={item.id}
                                                  >
                                                    {item.table_categoryName}
                                                  </option>
                                                ))}
                                            </select>
                                          </td>
                                          <td>
                                            {item.link && item.table_no && (
                                              <QRCodeGenerator
                                                link={item.link}
                                                qrId={item.table_no}
                                              />
                                            )}
                                          </td>
                                          <td className="text-right pe-0">
                                            <span
                                              className="btn btn-primary btn-sm"
                                              onClick={(e) =>
                                                handleUpdateTableName(e, item)
                                              }
                                            >
                                              <span className="mdi mdi-content-save-settings vsalign"></span>
                                              &nbsp;Save
                                            </span>
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  {data.length > 0 && (
                                    <p>
                                      Showing{" "}
                                      {(currentPage - 1) * recordsPerPage + 1}{" "}
                                      to{" "}
                                      {(currentPage - 1) * recordsPerPage +
                                        data.length}{" "}
                                      of {totalrecords} entries
                                    </p>
                                  )}
                                </div>
                                <div className="col-6">
                                  <div className="pagination">
                                    <button
                                      onClick={handlePrevPage}
                                      disabled={currentPage === 1}
                                      className={
                                        currentPage === 1 ? "disabled" : ""
                                      }
                                    >
                                      {`<<`}
                                    </button>
                                    {pageNumbersBatch.map((pageNumber) => (
                                      <button
                                        key={pageNumber}
                                        onClick={() =>
                                          handlePageClick(pageNumber)
                                        }
                                        className={
                                          pageNumber === currentPage
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        {pageNumber}
                                      </button>
                                    ))}
                                    <button
                                      onClick={handleNextPage}
                                      disabled={currentPage === totalPages}
                                      className={
                                        currentPage === totalPages
                                          ? "disabled"
                                          : ""
                                      }
                                    >
                                      {`>>`}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default ManageTables;
