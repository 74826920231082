import React from "react";
import "./deleteModal.css";

const DeleteModal = ({handleModal,handleDelete}) => {
  return (
    <>
      <div className="popup-modal">
        <div className="popup-modal-content">
          <h4 className="content">Are you sure ? You want to delete this.</h4>
          <div className="popup_btn_container">
            <button
              className="btn btn-primary me-2"
              onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
            >
              Yes
            </button>
            <button
              className="btn btn btn-secondary"
              onClick={() => handleModal(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
