import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {
  GetCategoryMenuListURL,
  AddDishesProductURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import Select from "react-select";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const AddProduct = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const productImageRef = useRef(null);
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [productImage, setProductImage] = useState("");
  const maxImageSize = 1024 * 1024;
  const maxImageWidth = 500;
  const maxImageHeight = 500;
  const [product, setProduct] = useState({
    menu_id: "",
    name: "",
    price: "",
    proprice: "",
    tax: "",
    tax_type: "",
    status: true,
    picture: "",
    product_type: "",
    top_rated: false,
    best_seller: false,
    most_ordered: false,
    dine_in: false,
    delivery: false,
    pick_up: false,
    is_addon: false,
    feature: false,
    isVariant: false,
    minQuantity: "1",
    preparation_time: "5",
    description: "",
    packaging_charges: "",
  });

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleTagInputChange = (e) => {
    setInputValue(e.target.value);
    setError("");
    setSuccess("");
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
      setError("");
      setSuccess("");
    }
  };

  const handleTagClick = (tagIndex) => {
    const newTags = [...tags];
    newTags.splice(tagIndex, 1);
    setTags(newTags);
    setError("");
    setSuccess("");
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleDishesProductsClick = (e) => {
    e.preventDefault();
    navigate("/view_products");
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: checked,
      }));
      setError("");
      setSuccess("");
    } else if (type === "radio" && parseInt(value) === 3) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
        delivery: false,
        pick_up: false,
      }));
      setError("");
      setSuccess("");
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
      setError("");
      setSuccess("");
    }
  };

  const handleSelectMenu = (selectedOption) => {
    setSelectedMenu(selectedOption);
    setError("");
    setSuccess("");
  };

  function isImageSizeValid(file) {
    if (file && file.size) {
      return file.size <= maxImageSize;
    }
    return false;
  }

  function isImageDimensionsValid(file) {
    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      return new Promise((resolve) => {
        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const isValidDimensions =
            width <= maxImageWidth && height <= maxImageHeight;

          resolve(isValidDimensions);
        };
      });
    }

    return false;
  }

  const handleAddProduct = async (e) => {
    e.preventDefault();

    if (selectedMenu === null) {
      setError("Menu/Category can not be empty");
      setSuccess("");
    } else if (product.name === "") {
      setError("Name can not be empty");
      setSuccess("");
    } else if (product.product_type === "") {
      setError("Dish Type can not be empty");
      setSuccess("");
    } else if (product.preparation_time === "") {
      setError("Time for preparation can not be empty");
      setSuccess("");
    } else if (product.price === "") {
      setError("Base Price can not be empty");
      setSuccess("");
    } else if (!isValidNumber(product.price)) {
      setError("Please enter valid Base Price");
      setSuccess("");
    } else if (product.proprice && !isValidNumber(product.proprice)) {
      setError("Please enter valid Promo Price");
      setSuccess("");
    } else if (product.proprice && parseFloat(product.proprice) <= 0) {
      setError("Promo Price should be greater than 0");
      setSuccess("");
    } else if (
      product.proprice &&
      parseFloat(product.proprice) > parseFloat(product.price)
    ) {
      setError("Promo Price can not be greater than Base Price");
      setSuccess("");
    } else if (product.tax === "") {
      setError("Tax can not be empty");
      setSuccess("");
    } else if (product.tax_type === "") {
      setError("Tax Type can not be empty");
      setSuccess("");
    } else if (
      !product.dine_in &&
      !product.delivery &&
      !product.pick_up &&
      !product.is_addon
    ) {
      setError("Select atleast one service type");
      setSuccess("");
    } else if (product.minQuantity && !isValidNumber(product.minQuantity)) {
      setError("Please enter valid Minimum Order Quantity");
      setSuccess("");
    }else if (!(parseInt(product.minQuantity) > 0)) {
      setError("Please enter valid Minimum Order Quantity");
      setSuccess("");
  
    } else if (
      product.packaging_charges &&
      !isValidNumber(product.packaging_charges)
    ) {
      setError("Please enter valid Packaging Charges");
      setSuccess("");
    } else if (productImage && !isImageSizeValid(productImage)) {
      setError(`Image size can not be greater than ${maxImageSize / 1024}kb`);
      productImageRef.current.value = "";
      setProductImage("");
      setSuccess("");
    } else if (productImage && !(await isImageDimensionsValid(productImage))) {
      setError(
        `Image Dimensions can not be greater than ${maxImageWidth}*${maxImageHeight}`
      );
      productImageRef.current.value = "";
      setProductImage("");
      setSuccess("");
    } else {
      const dataToSend = new FormData();
      dataToSend.append("menu_id", selectedMenu.value);
      dataToSend.append("name", product.name);
      dataToSend.append("price", product.price);
      dataToSend.append("proprice", product.proprice);
      dataToSend.append("tax_percent", product.tax);
      dataToSend.append("tax_type", product.tax_type);
      dataToSend.append("tags", tags.join(", "));
      dataToSend.append("status", product.status ? 1 : 0);
      dataToSend.append(
        "picture",
        productImage ? productImage : product.picture
      );
      dataToSend.append("product_type", product.product_type);
      dataToSend.append("top_rated", product.top_rated ? 1 : 0);
      dataToSend.append("best_seller", product.best_seller ? 1 : 0);
      dataToSend.append("most_ordered", product.most_ordered ? 1 : 0);
      dataToSend.append("dine_in_service", product.dine_in ? 1 : 0);
      dataToSend.append("delivery_service", product.delivery ? 1 : 0);
      dataToSend.append("pick_up_service", product.pick_up ? 1 : 0);
      dataToSend.append("is_addon", product.is_addon ? 1 : 0);
      dataToSend.append("min_order_quantity", product.minQuantity);
      dataToSend.append("feature", product.feature ? 1 : 0);
      dataToSend.append("preparation_time", product.preparation_time);
      dataToSend.append("description", product.description);
      dataToSend.append("packaging_charges", product.packaging_charges);

      try {
        const response = await axios({
          method: "post",
          url: AddDishesProductURL,
          data: dataToSend,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setProduct({
            menu_id: "",
            name: "",
            price: "",
            proprice: "",
            tax: "",
            tax_type: "",
            status: true,
            picture: "",
            product_type: "",
            top_rated: false,
            best_seller: false,
            most_ordered: false,
            dine_in: false,
            delivery: false,
            pick_up: false,
            is_addon: false,
            feature: false,
            isVariant: false,
            minQuantity: "1",
            preparation_time: "5",
            description: "",
            packaging_charges: "",
          });
          setTags([]);
          setInputValue("");
          setSelectedMenu(null);
          setProductImage("");
          productImageRef.current.value = "";
          if (product.isVariant) {
            localStorage.setItem("productID", response.data.id);
            setTimeout(() => {
              navigate("/manage_variant");
            }, 3000);
          }
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  // const handleAddProductandVariants = async (e) => {
  //   e.preventDefault();

  //   if (selectedMenu === null) {
  //     setError("Menu/Category can not be empty");
  //     setSuccess("");
  //   } else if (product.name === "") {
  //     setError("Name can not be empty");
  //     setSuccess("");
  //   } else if (product.product_type === "") {
  //     setError("Dish Type can not be empty");
  //     setSuccess("");
  //   } else if (product.preparation_time === "") {
  //     setError("Time for preparation can not be empty");
  //     setSuccess("");
  //   } else if (product.price === "") {
  //     setError("Price can not be empty");
  //     setSuccess("");
  //   } else if (productImage && !isImageSizeValid(productImage)) {
  //     setError(
  //       `Image size can not be greater than ${maxImageSize / 1024}kb`
  //     );
  //     productImageRef.current.value = "";
  //     setProductImage("");
  //     setSuccess("");
  //   } else if (productImage && !(await isImageDimensionsValid(productImage))) {
  //     setError(
  //       `Image Dimensions can not be greater than ${maxImageWidth}*${maxImageHeight}`
  //     );
  //     productImageRef.current.value = "";
  //     setProductImage("");
  //     setSuccess("");
  //   } else {
  //     const dataToSend = new FormData();
  //     dataToSend.append("menu_id", selectedMenu.value);
  //     dataToSend.append("name", product.name);
  //     dataToSend.append("price", product.price);
  //     dataToSend.append("proprice", product.proprice);
  //     dataToSend.append("tags", tags.join(", "));
  //     dataToSend.append("status", product.status ? 1 : 0);
  //     dataToSend.append(
  //       "picture",
  //       productImage ? productImage : product.picture
  //     );
  //     dataToSend.append("product_type", product.product_type);
  //     dataToSend.append("top_rated", product.top_rated ? 1 : 0);
  //     dataToSend.append("best_seller", product.best_seller ? 1 : 0);
  //     dataToSend.append("most_ordered", product.most_ordered ? 1 : 0);
  //     dataToSend.append("feature", product.feature ? 1 : 0);
  //     dataToSend.append("preparation_time", product.preparation_time);
  //     dataToSend.append("description", product.description);

  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: AddDishesProductURL,
  //         data: dataToSend,
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (
  //         response.status === 200 &&
  //         response.data.message &&
  //         response.data.id
  //       ) {
  //         setError("");
  //         setSuccess(response.data.message);
  //         setProduct({
  //           menu_id: "",
  //           name: "",
  //           price: "",
  //           proprice: "",
  //           status: true,
  //           picture: "",
  //           product_type: "",
  //           top_rated: false,
  //           best_seller: false,
  //           most_ordered: false,
  //           preparation_time: "5",
  //           description: "",
  //         });
  //         setTags([]);
  //         setInputValue("");
  //         setSelectedMenu(null);
  //         setProductImage("");
  //         productImageRef.current.value = "";
  //         localStorage.setItem("productID", response.data.id);
  //         navigate(`/edit_product`);
  //       }
  //     } catch (error) {
  //       if (error.response && error.response.data.message) {
  //         setError(error.response.data.message);
  //         setSuccess("");
  //       } else {
  //         setError(error.message);
  //         setSuccess("");
  //       }
  //       console.log(error);
  //     }
  //   }
  // };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getMenuList() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetCategoryMenuListURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            const modifiedData = response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            setMenuList(modifiedData);
            setTaxList(response.data.taxList);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getMenuList();
    }
  }, [token, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_products" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Products</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    Add Dishes/Products
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-danger mb-0 me-0"
                                    onClick={handleDishesProductsClick}
                                  >
                                    <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                    Go Back
                                  </span>
                                </div>
                              </div>
                              <form className="forms-sample">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Menu/Category
                                        <span className="text-danger">*</span>
                                      </label>
                                      {/* <select
                                    className="form-select"
                                    name="menu_id"
                                    value={product.menu_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">
                                      Select Menu/Category
                                    </option>
                                    {menuList &&
                                      menuList.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}{" "}
                                        </option>
                                      ))}
                                  </select> */}
                                      <Select
                                        options={menuList}
                                        value={selectedMenu}
                                        onChange={handleSelectMenu}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Name
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Menu Name"
                                        name="name"
                                        value={product.name}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Dish Type
                                        <span className="text-danger">*</span>
                                      </label>

                                      <div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="veg"
                                            name="product_type"
                                            value="0"
                                            checked={
                                              parseInt(product.product_type) ===
                                              0
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="veg"
                                          >
                                            Veg
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="nonVeg"
                                            name="product_type"
                                            value="1"
                                            checked={
                                              parseInt(product.product_type) ===
                                              1
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="nonVeg"
                                          >
                                            Non-Veg
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="egg"
                                            name="product_type"
                                            value="2"
                                            checked={
                                              parseInt(product.product_type) ===
                                              2
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="egg"
                                          >
                                            Egg
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="alcohol"
                                            name="product_type"
                                            value="3"
                                            checked={
                                              parseInt(product.product_type) ===
                                              3
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="alcohol"
                                          >
                                            Alcohol
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Time for preparation(min)
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="preparation_time"
                                        value={product.preparation_time}
                                        onChange={handleInputChange}
                                      >
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                        <option value="30">30</option>
                                        <option value="35">35</option>
                                        <option value="40">40</option>
                                        <option value="45">45</option>
                                        <option value="50">50</option>
                                        <option value="55">55</option>
                                        <option value="60">60</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Base Price
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Price"
                                          name="price"
                                          value={product.price}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>Promo Price </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Promo Price"
                                          name="proprice"
                                          value={product.proprice}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Tax
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="tax"
                                        value={product.tax}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">Select Tax</option>
                                        {taxList.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item.tax_percentage}
                                          >
                                            {item.tax_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Tax Type
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="tax_type"
                                        value={product.tax_type}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">
                                          Select Tax Type
                                        </option>
                                        <option value="0">Exclude</option>
                                        <option value="1">Include</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group togglecss">
                                      <label>Status</label>
                                      <div
                                        className={`button r ${product.status ? "active" : ""
                                          }`}
                                        id="button-1"
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          name="status"
                                          checked={product.status}
                                          onChange={handleInputChange}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-4">
                                    <div className="form-group">
                                      <label>Rank</label>
                                      <div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="top_rated"
                                            checked={product.top_rated}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Top Rated
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="best_seller"
                                            checked={product.best_seller}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Most Ordered
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="most_ordered"
                                            checked={product.most_ordered}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Best Seller
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Service Type
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dine_in"
                                            checked={product.dine_in}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Dine In
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="delivery"
                                            disabled={
                                              parseInt(product.product_type) ===
                                              3
                                            }
                                            checked={product.delivery}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Delivery
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="pick_up"
                                            disabled={
                                              parseInt(product.product_type) ===
                                              3
                                            }
                                            checked={product.pick_up}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Pick Up
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="is_addon"
                                            checked={product.is_addon}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Is Addon
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Minimum Order Qunatity
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Minimum Order Qunatity"
                                        name="minQuantity"
                                        value={product.minQuantity}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>Packaging Charges</label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Packaging Charges"
                                          name="packaging_charges"
                                          value={product.packaging_charges}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Tags</label>
                                      <div id="tags">
                                        {tags.map((tag, index) => (
                                          <span
                                            key={index}
                                            onClick={() =>
                                              handleTagClick(index)
                                            }
                                          >
                                            {tag}
                                          </span>
                                        ))}
                                        <input
                                          type="text"
                                          className=""
                                          value={inputValue}
                                          placeholder="Add a tag & hit Enter"
                                          onChange={handleTagInputChange}
                                          onKeyDown={handleInputKeyPress}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Description</label>
                                      <textarea
                                        className="form-control"
                                        placeholder="Enter Description"
                                        name="description"
                                        value={product.description}
                                        onChange={handleInputChange}
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="row">
                                      <div className="col-4">
                                        <div className="form-group">
                                          <label> Choose Dish Image </label>
                                          <input
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            accept=".jpeg, .jpg, .png"
                                            ref={productImageRef}
                                            onChange={(e) =>
                                              setProductImage(e.target.files[0])
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="isVariant"
                                          checked={product.isVariant}
                                          onChange={handleInputChange}
                                        />
                                        <label className="form-check-label">
                                          Manage Variant(s)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="feature"
                                          checked={product.feature}
                                          onChange={handleInputChange}
                                        />
                                        <label className="form-check-label">
                                          Feature
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleAddProduct}
                                >
                                  Submit
                                </button>
                                {/* <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleAddProductandVariants}
                                >
                                  Submit & Continue with Variants
                                </button> */}
                                <button
                                  className="btn btn btn-secondary"
                                  onClick={handleDishesProductsClick}
                                >
                                  Cancel
                                </button>
                              </form>
                              {/* <h4 className="card-title card-title-dash mt-5">
                            Manage Product Variants and Price
                          </h4>
                          <form className="forms-sample mt-2">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label> Variant Name 1 </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Variant Name 1"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label> Variant Name 2 </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Variant Name 2"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label> Variant Name 3 </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Variant Name 3"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label> Variant Name 4 </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Variant Name 5"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label> Variant Name 5 </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Variant Name 5"
                                  />
                                </div>
                              </div>
                            </div>

                            <button
                              // type="submit"
                              className="btn btn-primary me-2"
                            >
                              Submit
                            </button>
                            <button className="btn btn btn-secondary">
                              Cancel
                            </button>
                          </form> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default AddProduct;
