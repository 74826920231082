import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {
  GetCategoryMenuListURL,
  GetEditDishesProductURL,
  EditDishesProductURL,
  ScriptId,
  ScriptSRC,
  // AddNewProductPropertyURL,
  // AddNewPropertyAttributeURL,
  // DeleteNewPropertyAttributeURL,
  // DeleteProductPropertyURL,
  // AddProductPropertyCombinationPriceURL,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
// import DeleteModal from "../../deleteModal/DeleteModal";
import Select from "react-select";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const EditProduct = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const productImageRef = useRef(null);
  const [token, setToken] = useState("");
  const [productID, setProductID] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [flag, setFlag] = useState(false);
  // const [deleteId, setDeleteId] = useState();
  // const [deletePropertyId, setDeletePropertyId] = useState();
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [showPropertyDeleteModal, setShowPropertyDeleteModal] = useState(false);
  const [productImage, setProductImage] = useState("");
  const maxImageSize = 1024 * 1024;
  const maxImageWidth = 500;
  const maxImageHeight = 500;
  const [menuList, setMenuList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [product, setProduct] = useState({
    menu_id: "",
    name: "",
    price: "",
    proprice: "",
    tax: "",
    tax_type: "",
    status: true,
    picture: "",
    product_type: "",
    top_rated: false,
    best_seller: false,
    most_ordered: false,
    dine_in: false,
    delivery: false,
    pick_up: false,
    is_addon: false,
    feature: false,
    minQuantity: "1",
    preparation_time: "5",
    description: "",
    packaging_charges: "0",
    variant1: "",
    variant2: "",
    variant3: "",
    variant4: "",
  });

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const [properties, setProperties] = useState([]);
  // const [pricingData, setPricingData] = useState([]);
  // const [pricingCombinationData, setPricingCombinationData] = useState([]);
  // const [showAddNew, setShowAddNew] = useState(properties.map(() => false));
  // const [showAddNewProperty, setShowAddNewProperty] = useState(false);
  // const [newAttribute, setNewAttribute] = useState("");
  // const [newPropertyDetails, setNewPropertyDetails] = useState({
  //   property_name: "",
  //   value1: "",
  //   value2: "",
  // });

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleTagInputChange = (e) => {
    setInputValue(e.target.value);
    setError("");
    setSuccess("");
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
      setError("");
      setSuccess("");
    }
  };

  const handleTagClick = (tagIndex) => {
    const newTags = [...tags];
    newTags.splice(tagIndex, 1);
    setTags(newTags);
    setError("");
    setSuccess("");
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleDishesProductsClick = (e) => {
    e.preventDefault();
    navigate("/view_products");
  };

  const handleManageVariantClick = (e) => {
    e.preventDefault();
    navigate("/manage_variant");
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: checked,
      }));
      setError("");
      setSuccess("");
    } else if (type === "radio" && parseInt(value) === 3) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
        delivery: false,
        pick_up: false,
      }));
      setError("");
      setSuccess("");
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
      setError("");
      setSuccess("");
    }
  };

  const handleSelectMenu = (selectedOption) => {
    setSelectedMenu(selectedOption);
    setError("");
    setSuccess("");
  };

  // const handleDelete = (e, id, item) => {
  //   e.preventDefault();
  //   if (item.values.length === 1) {
  //     setSuccess("");
  //     setError("Please add more property values to delete this!");
  //     scrollToTop();
  //   } else {
  //     setDeleteId([id]);
  //     setShowDeleteModal(!showDeleteModal);
  //   }
  // };

  // const handleDeleteProductVariant = async () => {
  //   try {
  //     const response = await axios({
  //       method: "delete",
  //       url: DeleteNewPropertyAttributeURL,
  //       data: deleteId,
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.status === 200 && response.data.message) {
  //       addToast("success", response.data.message);
  //       setFlag(!flag);
  //       setShowDeleteModal(!showDeleteModal);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data.message) {
  //       addToast("error", error.response.data.message);
  //     } else {
  //       addToast("error", error.message);
  //     }
  //     console.log(error);
  //   }
  // };

  // const handleDeleteProperty = (e, id) => {
  //   e.preventDefault();
  //   setDeletePropertyId(id);
  //   setShowPropertyDeleteModal(!showPropertyDeleteModal);
  // };

  // const handleDeleteProductProperty = async () => {
  //   try {
  //     const response = await axios({
  //       method: "delete",
  //       url: DeleteProductPropertyURL,
  //       data: { id: deletePropertyId },
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.status === 200 && response.data.message) {
  //       addToast("success", response.data.message);
  //       setFlag(!flag);
  //       setShowPropertyDeleteModal(!showPropertyDeleteModal);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data.message) {
  //       addToast("error", error.response.data.message);
  //     } else {
  //       addToast("error", error.message);
  //     }
  //     console.log(error);
  //   }
  // };

  function isImageSizeValid(file) {
    if (file && file.size) {
      return file.size <= maxImageSize;
    }
    return false;
  }

  function isImageDimensionsValid(file) {
    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      return new Promise((resolve) => {
        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const isValidDimensions =
            width <= maxImageWidth && height <= maxImageHeight;

          resolve(isValidDimensions);
        };
      });
    }

    return false;
  }

  const handleSaveProduct = async (e) => {
    e.preventDefault();

    if (selectedMenu === null) {
      setError("Menu/Category can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (product.name === "") {
      setError("Name can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (product.product_type === "") {
      setError("Dish Type can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (product.preparation_time === "") {
      setError("Time for preparation can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (product.price === "") {
      setError("Base Price can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (!isValidNumber(product.price)) {
      setError("Please enter valid Base Price");
      setSuccess("");
      scrollToTop();
    } else if (product.proprice && !isValidNumber(product.proprice)) {
      setError("Please enter valid Promo Price");
      setSuccess("");
      scrollToTop();
    } else if (product.proprice && parseFloat(product.proprice) <= 0) {
      setError("Promo Price should be greater than 0");
      setSuccess("");
    } else if (
      product.proprice &&
      parseFloat(product.proprice) > parseFloat(product.price)
    ) {
      setError("Promo Price can not be greater than Base Price");
      setSuccess("");
      scrollToTop();
    } else if (product.tax === "") {
      setError("Tax can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (product.tax_type === "") {
      setError("Tax Type can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (
      !product.dine_in &&
      !product.delivery &&
      !product.pick_up &&
      !product.is_addon
    ) {
      setError("Select atleast one service type");
      setSuccess("");
      scrollToTop();
    } else if ((product.minQuantity && !isValidNumber(product.minQuantity))) {
      setError("Please enter valid Minimum Order Quantity");
      setSuccess("");
      scrollToTop();
    } else if (!(parseInt(product.minQuantity) > 0)) {
      setError("Please enter valid Minimum Order Quantity");
      setSuccess("");
      scrollToTop();
    } else if (
      product.packaging_charges &&
      !isValidNumber(product.packaging_charges)
    ) {
      setError("Please enter valid Packaging Charges");
      setSuccess("");
      scrollToTop();
    } else if (productImage && !isImageSizeValid(productImage)) {
      setError(`Image size can not be greater than ${maxImageSize / 1024}kb`);
      productImageRef.current.value = "";
      setProductImage("");
      setSuccess("");
    } else if (productImage && !(await isImageDimensionsValid(productImage))) {
      setError(
        `Image Dimensions can not be greater than ${maxImageWidth}*${maxImageHeight}`
      );
      productImageRef.current.value = "";
      setProductImage("");
      setSuccess("");
    } else {
      const dataToSend = new FormData();
      dataToSend.append("id", productID);
      dataToSend.append("menu_id", selectedMenu.value);
      dataToSend.append("name", product.name);
      dataToSend.append("price", product.price);
      dataToSend.append("proprice", product.proprice);
      dataToSend.append("tax_percent", product.tax);
      dataToSend.append("tax_type", product.tax_type);
      dataToSend.append("tags", tags.join(", "));
      dataToSend.append("status", product.status ? 1 : 0);
      dataToSend.append(
        "picture",
        productImage ? productImage : product.picture
      );
      dataToSend.append("product_type", product.product_type);
      dataToSend.append("top_rated", product.top_rated ? 1 : 0);
      dataToSend.append("best_seller", product.best_seller ? 1 : 0);
      dataToSend.append("most_ordered", product.most_ordered ? 1 : 0);
      dataToSend.append("dine_in_service", product.dine_in ? 1 : 0);
      dataToSend.append("delivery_service", product.delivery ? 1 : 0);
      dataToSend.append("pick_up_service", product.pick_up ? 1 : 0);
      dataToSend.append("is_addon", product.is_addon ? 1 : 0);
      dataToSend.append("min_order_quantity", product.minQuantity);
      dataToSend.append("feature", product.feature ? 1 : 0);
      dataToSend.append("preparation_time", product.preparation_time);
      dataToSend.append("description", product.description);
      dataToSend.append("packaging_charges", product.packaging_charges ? product.packaging_charges : 0);

      setIsLoading(true);
      try {
        const response = await axios({
          method: "put",
          url: EditDishesProductURL,
          data: dataToSend,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          scrollToTop();
          setProductImage("");
          productImageRef.current.value = "";
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
          scrollToTop();
        } else {
          setError(error.message);
          setSuccess("");
          scrollToTop();
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  // const handleAddNewClick = (index) => {
  //   setNewAttribute("");
  //   const newShowAddNew = [...showAddNew];
  //   newShowAddNew[index] = true;
  //   setShowAddNew(newShowAddNew);
  // };

  // const handleNewPropetyDetailsChange = (event) => {
  //   const { name, value } = event.target;

  //   setNewPropertyDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  //   setError("");
  //   setSuccess("");
  // };

  // const handleAddNewProperty = async () => {
  //   if (
  //     newPropertyDetails.property_name === "" ||
  //     newPropertyDetails.property_name.trim() === ""
  //   ) {
  //     setError("Property name can not be empty");
  //     setSuccess("");
  //     scrollToTop();
  //   } else if (
  //     newPropertyDetails.value1 === "" ||
  //     newPropertyDetails.value1.trim() === ""
  //   ) {
  //     setError("Property value 1 name can not be empty");
  //     setSuccess("");
  //     scrollToTop();
  //   } else {
  //     const dataToSend = {
  //       product_id: productID,
  //       display_name: newPropertyDetails.property_name,
  //       attribute_value: [
  //         { value: newPropertyDetails.value1 },
  //         { value: newPropertyDetails.value2 },
  //       ],
  //       price: product.price,
  //     };

  //     setIsLoading(true);
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: AddNewProductPropertyURL,
  //         data: dataToSend,
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (response.status === 200 && response.data.message) {
  //         setError("");
  //         setSuccess(response.data.message);
  //         setShowAddNewProperty(false);
  //         setFlag(!flag);
  //         scrollToTop();
  //       }
  //     } catch (error) {
  //       if (error.response && error.response.data.message) {
  //         setError(error.response.data.message);
  //         setSuccess("");
  //         scrollToTop();
  //       } else {
  //         setError(error.message);
  //         setSuccess("");
  //         scrollToTop();
  //       }
  //       console.log(error);
  //     }
  //     setIsLoading(false);
  //   }
  // };

  // const handleAddNewAttribute = async (id, index) => {
  //   if (newAttribute === "" || newAttribute.trim() === "") {
  //     setError("Property value name can not be empty");
  //     setSuccess("");
  //     scrollToTop();
  //   } else {
  //     const dataToSend = {
  //       attribute_id: id,
  //       attribute_value: newAttribute,
  //       price: product.price,
  //     };

  //     setIsLoading(true);
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: AddNewPropertyAttributeURL,
  //         data: dataToSend,
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (response.status === 200 && response.data.message) {
  //         setError("");
  //         setSuccess(response.data.message);
  //         setShowAddNewProperty(false);
  //         setShowAddNew([
  //           ...showAddNew.slice(0, index),
  //           false,
  //           ...showAddNew.slice(index + 1),
  //         ]);
  //         setFlag(!flag);
  //         scrollToTop();
  //       }
  //     } catch (error) {
  //       if (error.response && error.response.data.message) {
  //         setError(error.response.data.message);
  //         setSuccess("");
  //         scrollToTop();
  //       } else {
  //         setError(error.message);
  //         setSuccess("");
  //         scrollToTop();
  //       }
  //       console.log(error);
  //     }
  //     setIsLoading(false);
  //   }
  // };

  // const handleChangePriceForSingleProperty = (index, newValue) => {
  //   const updatedValues = [...pricingData];
  //   updatedValues[index].price = newValue;
  //   setPricingData(updatedValues);
  //   setError("");
  //   setSuccess("");
  // };

  // const handleChangePriceForProperties = (index, comboIndex, newValue) => {
  //   const updatedValues = [...pricingData];
  //   updatedValues[index] = {
  //     ...updatedValues[index],
  //     combination: updatedValues[index].combination.map((comboItem, i) =>
  //       i === comboIndex ? { ...comboItem, price: newValue } : comboItem
  //     ),
  //   };
  //   setPricingData(updatedValues);
  // };

  // const handleSubmitPriceForSingleProperty = async () => {
  //   const allPricesValid = pricingData.every((item) =>
  //     isValidNumber(item.price)
  //   );
  //   if (!allPricesValid) {
  //     setError("Please enter valid variant prices");
  //     setSuccess("");
  //     scrollToTop();
  //   } else {
  //     const combinationData = pricingData.map((item) => ({
  //       combination: { [item.attribute_id]: item.id },
  //       price: item.price,
  //     }));

  //     const dataToSend = {
  //       product_id: productID,
  //       combinationData: combinationData,
  //     };

  //     setIsLoading(true);
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: AddProductPropertyCombinationPriceURL,
  //         data: dataToSend,
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (response.status === 200 && response.data.message) {
  //         setError("");
  //         setSuccess(response.data.message);
  //         setFlag(!flag);
  //         scrollToTop();
  //       }
  //     } catch (error) {
  //       if (error.response && error.response.data.message) {
  //         setError(error.response.data.message);
  //         setSuccess("");
  //         scrollToTop();
  //       } else {
  //         setError(error.message);
  //         setSuccess("");
  //         scrollToTop();
  //       }
  //       console.log(error);
  //     }
  //     setIsLoading(false);
  //   }
  // };

  // const handleSubmitPriceForProperties = async () => {
  //   let allPricesValid = true;

  //   pricingData.forEach((item) => {
  //     item.combination.forEach((comboItem) => {
  //       if (!isValidNumber(comboItem.price)) {
  //         allPricesValid = false;
  //       }
  //     });
  //   });

  //   if (!allPricesValid) {
  //     setError("Please enter valid variant prices");
  //     setSuccess("");
  //     scrollToTop();
  //   } else {
  //     const combinationData = [];

  //     pricingData.forEach((item) => {
  //       item.combination.forEach((combination) => {
  //         const combinationObj = {
  //           combination: {
  //             [item.attribute_id]: item.id,
  //             [combination.attribute_id]: combination.id,
  //           },
  //           price: combination.price,
  //         };
  //         combinationData.push(combinationObj);
  //       });
  //     });

  //     const dataToSend = {
  //       product_id: productID,
  //       combinationData: combinationData,
  //     };

  //     setIsLoading(true);
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: AddProductPropertyCombinationPriceURL,
  //         data: dataToSend,
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (response.status === 200 && response.data.message) {
  //         setError("");
  //         setSuccess(response.data.message);
  //         setFlag(!flag);
  //         scrollToTop();
  //       }
  //     } catch (error) {
  //       if (error.response && error.response.data.message) {
  //         setError(error.response.data.message);
  //         setSuccess("");
  //         scrollToTop();
  //       } else {
  //         setError(error.message);
  //         setSuccess("");
  //         scrollToTop();
  //       }
  //       console.log(error);
  //     }
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    setProductID(localStorage.getItem("productID"));
  }, [productID]);

  useEffect(() => {
    if (token) {
      async function getMenuList() {
        try {
          const response = await axios.get(GetCategoryMenuListURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            const modifiedData = response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            setMenuList(modifiedData);
            setTaxList(response.data.taxList);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      }
      getMenuList();
    }
  }, [token, addToast]);

  useEffect(() => {
    if (token && productID) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(
            GetEditDishesProductURL + `?id=${productID}`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          );

          if (response.status === 200 && response.data.data) {
            setProduct({
              menu_id: response.data.data.menu_id,
              name: response.data.data.product_name,
              price: response.data.data.price,
              proprice: response.data.data.proprice,
              tax: response.data.data.tax_percent,
              tax_type: response.data.data.tax_type,
              status: response.data.data.status === 1 ? true : false,
              picture: response.data.data.picture,
              product_type: response.data.data.product_type,
              top_rated: response.data.data.top_rated === 1 ? true : false,
              best_seller: response.data.data.best_seller === 1 ? true : false,
              most_ordered:
                response.data.data.most_ordered === 1 ? true : false,
              dine_in: response.data.data.dine_in_service === 1 ? true : false,
              delivery:
                response.data.data.delivery_service === 1 ? true : false,
              pick_up: response.data.data.pick_up_service === 1 ? true : false,
              is_addon: response.data.data.is_addon === 1 ? true : false,
              feature: response.data.data.feature === 1 ? true : false,
              minQuantity: response.data.data.min_order_quantity,
              preparation_time: response.data.data.preparation_time,
              description: response.data.data.description,
              packaging_charges: response.data.data.packaging_charges,
              variant1: response.data.data.variant1 || "",
              variant2: response.data.data.variant2 || "",
              variant3: response.data.data.variant3 || "",
              variant4: response.data.data.variant4 || "",
            });
            setTags(
              response.data.data.product_tags
                ? response.data.data.product_tags.split(", ")
                : []
            );
            // setProperties(response.data.property ? response.data.property : []);
            // setPricingCombinationData(
            //   response.data.combinationData ? response.data.combinationData : []
            // );
            // setPricingData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, productID, flag, addToast]);

  useEffect(() => {
    if (product.menu_id && menuList) {
      const menu = menuList.filter((item) => item.value === product.menu_id);
      setSelectedMenu(menu[0]);
    }
  }, [product.menu_id, menuList]);

  // useEffect(() => {
  //   if (properties.length === 2) {
  //     if (pricingCombinationData.length > 0) {
  //       const modifiedProperty = properties[0].values.map((sizeValue) => {
  //         return {
  //           ...sizeValue,
  //           combination: properties[1].values.map((layerValue) => ({
  //             ...layerValue,
  //             price:
  //               pricingCombinationData.find(
  //                 (item) =>
  //                   item.combination[properties[0].id.toString()] ===
  //                     sizeValue.id.toString() &&
  //                   item.combination[properties[1].id.toString()] ===
  //                     layerValue.id.toString()
  //               )?.price || layerValue.price,
  //           })),
  //         };
  //       });

  //       setPricingData(modifiedProperty);
  //     } else {
  //       const modifiedProperty = properties[0].values;
  //       modifiedProperty.forEach((sizeValue) => {
  //         sizeValue.combination = properties[1].values;
  //       });
  //       setPricingData(modifiedProperty);
  //     }
  //   } else if (properties.length === 1) {
  //     if (pricingCombinationData.length > 0) {
  //       const modifiedProperty = properties[0].values;

  //       const updatedData = modifiedProperty.map((item) => {
  //         const priceToUpdate = pricingCombinationData.find(
  //           (updatedItem) =>
  //             updatedItem.combination[item.attribute_id.toString()] ===
  //             item.id.toString()
  //         );
  //         if (priceToUpdate) {
  //           item.price = priceToUpdate.price;
  //         }
  //         return item;
  //       });

  //       setPricingData(updatedData);
  //     } else {
  //       const modifiedProperty = properties[0].values;
  //       setPricingData(modifiedProperty);
  //     }
  //   }
  // }, [properties, pricingCombinationData]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_products" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Products</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    Edit Dishes/Products
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-danger mb-0 me-0"
                                    onClick={handleDishesProductsClick}
                                  >
                                    <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                    Go Back
                                  </span>
                                </div>
                              </div>
                              <form className="forms-sample">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Menu/Category
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Select
                                        options={menuList}
                                        value={selectedMenu}
                                        onChange={handleSelectMenu}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Name
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Menu Name"
                                        name="name"
                                        value={product.name}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Dish Type
                                        <span className="text-danger">*</span>
                                      </label>

                                      <div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="veg"
                                            name="product_type"
                                            value="0"
                                            checked={
                                              parseInt(product.product_type) ===
                                              0
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="veg"
                                          >
                                            Veg
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="nonVeg"
                                            name="product_type"
                                            value="1"
                                            checked={
                                              parseInt(product.product_type) ===
                                              1
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="nonVeg"
                                          >
                                            Non-Veg
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="egg"
                                            name="product_type"
                                            value="2"
                                            checked={
                                              parseInt(product.product_type) ===
                                              2
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="egg"
                                          >
                                            Egg
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="alcohol"
                                            name="product_type"
                                            value="3"
                                            checked={
                                              parseInt(product.product_type) ===
                                              3
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="alcohol"
                                          >
                                            Alcohol
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Time for preparation
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="preparation_time"
                                        value={product.preparation_time}
                                        onChange={handleInputChange}
                                      >
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                        <option value="30">30</option>
                                        <option value="35">35</option>
                                        <option value="40">40</option>
                                        <option value="45">45</option>
                                        <option value="50">50</option>
                                        <option value="55">55</option>
                                        <option value="60">60</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Base Price
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Price"
                                          name="price"
                                          value={product.price}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>Promo Price </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Promo Price"
                                          name="proprice"
                                          value={product.proprice}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Tax
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="tax"
                                        value={product.tax}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">Select Tax</option>
                                        {taxList.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item.tax_percentage}
                                          >
                                            {item.tax_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Tax Type
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="tax_type"
                                        value={product.tax_type}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">
                                          Select Tax Type
                                        </option>
                                        <option value="0">Exclude</option>
                                        <option value="1">Include</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group togglecss">
                                      <label>Status</label>
                                      <div
                                        className={`button r ${product.status ? "active" : ""
                                          }`}
                                        id="button-1"
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          name="status"
                                          checked={product.status}
                                          onChange={handleInputChange}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-4">
                                    <div className="form-group">
                                      <label>Rank</label>
                                      <div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="top_rated"
                                            checked={product.top_rated}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Top Rated
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="best_seller"
                                            checked={product.best_seller}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Most Ordered
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="most_ordered"
                                            checked={product.most_ordered}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Best Seller
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Service Type
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dine_in"
                                            checked={product.dine_in}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Dine In
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="delivery"
                                            disabled={
                                              parseInt(product.product_type) ===
                                              3
                                            }
                                            checked={product.delivery}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Delivery
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="pick_up"
                                            disabled={
                                              parseInt(product.product_type) ===
                                              3
                                            }
                                            checked={product.pick_up}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Pick Up
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="is_addon"
                                            checked={product.is_addon}
                                            onChange={handleInputChange}
                                          />
                                          <label className="form-check-label">
                                            Is Addon
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>
                                        Minimum Order Qunatity
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Minimum Order Qunatity"
                                        name="minQuantity"
                                        value={product.minQuantity}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>Packaging Charges</label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Packaging Charges"
                                          name="packaging_charges"
                                          value={product.packaging_charges}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Tags</label>
                                      <div id="tags">
                                        {tags.map((tag, index) => (
                                          <span
                                            key={index}
                                            onClick={() =>
                                              handleTagClick(index)
                                            }
                                          >
                                            {tag}
                                          </span>
                                        ))}
                                        <input
                                          type="text"
                                          className=""
                                          value={inputValue}
                                          placeholder="Add a tag & hit Enter"
                                          onChange={handleTagInputChange}
                                          onKeyDown={handleInputKeyPress}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Description</label>
                                      <textarea
                                        className="form-control"
                                        placeholder="Enter Description"
                                        name="description"
                                        value={product.description}
                                        onChange={handleInputChange}
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="row">
                                      <div className="col-4">
                                        {product.picture ? (
                                          <img
                                            src={product.picture}
                                            alt="Product Img"
                                            style={{ width: "20%" }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <div className="form-group">
                                          <label> Choose Dish Image </label>
                                          <input
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            accept=".jpeg, .jpg, .png"
                                            ref={productImageRef}
                                            onChange={(e) =>
                                              setProductImage(e.target.files[0])
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="feature"
                                          checked={product.feature}
                                          onChange={handleInputChange}
                                        />
                                        <label className="form-check-label">
                                          Feature
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleSaveProduct}
                                >
                                  Submit
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleManageVariantClick}
                                >
                                  Manage Variant(s)
                                </button>
                                <button
                                  className="btn btn btn-secondary"
                                  onClick={handleDishesProductsClick}
                                >
                                  Cancel
                                </button>
                              </form>

                              {/* <div className="home-tab mt-4">
                                <div className="d-sm-flex align-items-center justify-content-between border-bottom">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <a
                                        className="nav-link ps-0 active"
                                        data-bs-toggle="tab"
                                        href="#properties"
                                        role="tab"
                                        aria-controls="properties"
                                        aria-selected="true"
                                      >
                                        Properties({properties.length})
                                      </a>
                                    </li>
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <a
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        href="#addon"
                                        role="tab"
                                        aria-selected="false"
                                        tabIndex="-1"
                                      >
                                        Add-Ons (0)
                                      </a>
                                    </li>
                                  </ul>
                                  <div></div>
                                </div>
                                <div className="tab-content tab-content-basic accordvrt">
                                  <div
                                    className="tab-pane fade active show"
                                    id="properties"
                                    role="tabpanel"
                                    aria-labelledby="properties"
                                  >
                                    <div className="ordertbl">
                                      <div className="row">
                                        <div className="col-md-7">
                                          <h4 className="card-title-dash mb-4">
                                            {" "}
                                            Variants of this items can be create
                                            by defining its properties below
                                          </h4>
                                          <div className="row">
                                            {properties.map((item, index) => (
                                              <div
                                                key={index}
                                                className="col-6 grid-margin"
                                              >
                                                <div className="card card-rounded">
                                                  <div className="card-body vrtcrd">
                                                    <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                                                      <div className="col-10">
                                                        <i className="mdi mdi-apps menu-icon vsalign"></i>
                                                        &nbsp;&nbsp;
                                                        <b>
                                                          {item.display_name}
                                                        </b>
                                                        ({item.values.length}{" "}
                                                        Variants)
                                                      </div>
                                                      <div className="col-2 text-right">
                                                        <i
                                                          className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) =>
                                                            handleDeleteProperty(
                                                              e,
                                                              item.id
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    </div>
                                                    <div>
                                                      {item.values.map(
                                                        (subItem, index) => (
                                                          <div
                                                            key={index}
                                                            className="input-group mb-2"
                                                          >
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Value"
                                                              value={
                                                                subItem.attribute_value
                                                              }
                                                              readOnly={true}
                                                            />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">
                                                                <i
                                                                  className="fs-20 mdi mdi-archive text-danger"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    handleDelete(
                                                                      e,
                                                                      subItem.id,
                                                                      item
                                                                    )
                                                                  }
                                                                ></i>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    <div>
                                                      {showAddNew[index] ? (
                                                        <>
                                                          <div className="input-group mb-2">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Enter New Value"
                                                              value={
                                                                newAttribute
                                                              }
                                                              onChange={(e) => {
                                                                setError("");
                                                                setSuccess("");
                                                                setNewAttribute(
                                                                  e.target.value
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <button
                                                            type="button"
                                                            className="btn btn-outline-info btn-fw col-12"
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              handleAddNewAttribute(
                                                                item.id,
                                                                index
                                                              );
                                                            }}
                                                          >
                                                            <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                            &nbsp;Save New{" "}
                                                            {item.display_name}
                                                          </button>
                                                        </>
                                                      ) : (
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw mt-2 col-12"
                                                          onClick={() =>
                                                            handleAddNewClick(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Add New{" "}
                                                          {item.display_name}
                                                        </button>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}

                                            {properties.length < 2 && (
                                              <div className="col-6 grid-margin">
                                                {showAddNewProperty ? (
                                                  <div className="card card-rounded">
                                                    <div className="card-body vrtcrd">
                                                      <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                                                        <div className="col-10">
                                                          <div className="input-group mb-2">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Property"
                                                              name="property_name"
                                                              value={
                                                                newPropertyDetails.property_name
                                                              }
                                                              onChange={
                                                                handleNewPropetyDetailsChange
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-2 text-right">
                                                          <i
                                                            className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              setShowAddNewProperty(
                                                                false
                                                              );
                                                            }}
                                                          ></i>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div className="input-group mb-2">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Value 1"
                                                            name="value1"
                                                            value={
                                                              newPropertyDetails.value1
                                                            }
                                                            onChange={
                                                              handleNewPropetyDetailsChange
                                                            }
                                                          />
                                                        </div>
                                                        <div className="input-group mb-2">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Value 2"
                                                            name="value2"
                                                            value={
                                                              newPropertyDetails.value2
                                                            }
                                                            onChange={
                                                              handleNewPropetyDetailsChange
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw mt-2 col-12"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleAddNewProperty();
                                                          }}
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Add New Property
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <button
                                                      type="button"
                                                      className="btn btn-outline-info btn-fw mt-2 col-12"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowAddNewProperty(
                                                          true
                                                        );
                                                        setNewPropertyDetails({
                                                          property_name: "",
                                                          value1: "",
                                                          value2: "",
                                                        });
                                                      }}
                                                    >
                                                      <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                      &nbsp;Add New Property
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {properties.length !== 0 && (
                                          <div className="col-md-5">
                                            <h4 className="card-title-dash mt-3 mb-3">
                                              {" "}
                                              Variant Pricing
                                            </h4>
                                            <h5>Enter Variant pricing here</h5>

                                            <div className="mb-3 mt-4 proscroll">
                                              <>
                                                {properties.length === 1 && (
                                                  <div className="accordion-body">
                                                    <div className="row mb-2  mr-3">
                                                      <div className="valign col-6">
                                                        <h5>Type </h5>
                                                      </div>
                                                      <div className="vsalign  col-4">
                                                        <h5 className="">
                                                          Price
                                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </h5>
                                                      </div>
                                                      <div className="text-right col-2"></div>
                                                    </div>
                                                    {pricingData &&
                                                      pricingData.map(
                                                        (item, index) => (
                                                          <div
                                                            key={index}
                                                            className="row mb-2 varwdt"
                                                          >
                                                            <div className="col-6">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=""
                                                                value={
                                                                  item.attribute_value
                                                                }
                                                                readOnly={true}
                                                              />
                                                            </div>
                                                            <div className="col-4">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="0"
                                                                value={
                                                                  item.price
                                                                }
                                                                onChange={(e) =>
                                                                  handleChangePriceForSingleProperty(
                                                                    index,
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                            <div className="col-2 vsalign">
                                                              {isValidNumber(
                                                                item.price
                                                              ) && (
                                                                <i className="fs-25 mdi mdi-check-circle-outline text-success vsalign"></i>
                                                              )}
                                                              {!isValidNumber(
                                                                item.price
                                                              ) && (
                                                                <i className="fs-25 mdi mdi-close-circle-outline text-danger vsalign"></i>
                                                              )}
                                                            </div>
                                                          </div>
                                                        )
                                                      )}

                                                    <button
                                                      type="button"
                                                      className="btn btn-outline-info btn-fw col-12"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSubmitPriceForSingleProperty();
                                                      }}
                                                    >
                                                      <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                      &nbsp;Save
                                                    </button>
                                                  </div>
                                                )}
                                              </>
                                              <div
                                                className="accordion"
                                                id="accordionPanelsStayOpenExample"
                                              >
                                                <>
                                                  {properties &&
                                                    properties?.length ===
                                                      2 && (
                                                      <>
                                                        {pricingData &&
                                                          pricingData.map(
                                                            (item, index) => (
                                                              <div
                                                                key={index}
                                                                className="accordion-item mb-3"
                                                              >
                                                                <h2
                                                                  className="accordion-header"
                                                                  id="panelsStayOpen-headingOne"
                                                                >
                                                                  <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                                    aria-expanded="true"
                                                                    aria-controls="panelsStayOpen-collapseOne"
                                                                  >
                                                                    <b>
                                                                      {
                                                                        item.attribute_value
                                                                      }
                                                                    </b>
                                                                    ({" "}
                                                                    {
                                                                      item
                                                                        ?.combination
                                                                        ?.length
                                                                    }{" "}
                                                                    Variant)
                                                                  </button>
                                                                </h2>
                                                                <div
                                                                  id="panelsStayOpen-collapseOne"
                                                                  className="accordion-collapse collapse show"
                                                                  aria-labelledby="panelsStayOpen-headingOne"
                                                                >
                                                                  <div className="accordion-body">
                                                                    <div className="row mb-2  mr-3">
                                                                      <div className="valign col-6">
                                                                        <h5>
                                                                          Type{" "}
                                                                        </h5>
                                                                      </div>
                                                                      <div className="vsalign  col-4">
                                                                        <h5 className="">
                                                                          Price
                                                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        </h5>
                                                                      </div>
                                                                      <div className="text-right col-2"></div>
                                                                    </div>
                                                                    {item.combination.map(
                                                                      (
                                                                        comboItem,
                                                                        comboIndex
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            comboIndex
                                                                          }
                                                                          className="row mb-2 varwdt"
                                                                        >
                                                                          <div className="col-6">
                                                                            <input
                                                                              type="text"
                                                                              className="form-control"
                                                                              placeholder=""
                                                                              value={
                                                                                comboItem.attribute_value
                                                                              }
                                                                              readOnly={
                                                                                true
                                                                              }
                                                                            />
                                                                          </div>
                                                                          <div className="col-4">
                                                                            <input
                                                                              type="text"
                                                                              className="form-control"
                                                                              placeholder=""
                                                                              value={
                                                                                comboItem.price
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                handleChangePriceForProperties(
                                                                                  index,
                                                                                  comboIndex,
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              }
                                                                            />
                                                                          </div>
                                                                          <div className="col-2 vsalign">
                                                                            {isValidNumber(
                                                                              comboItem.price
                                                                            ) && (
                                                                              <i className="fs-25 mdi mdi-check-circle-outline text-success vsalign"></i>
                                                                            )}
                                                                            {!isValidNumber(
                                                                              comboItem.price
                                                                            ) && (
                                                                              <i className="fs-25 mdi mdi-close-circle-outline text-danger vsalign"></i>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw col-12"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSubmitPriceForProperties();
                                                          }}
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Save
                                                        </button>
                                                      </>
                                                    )}
                                                </>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {showDeleteModal && (
            <DeleteModal
              handleModal={setShowDeleteModal}
              handleDelete={handleDeleteProductVariant}
            />
          )}

          {showPropertyDeleteModal && (
            <DeleteModal
              handleModal={setShowPropertyDeleteModal}
              handleDelete={handleDeleteProductProperty}
            />
          )} */}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default EditProduct;
