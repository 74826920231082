import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import LoadingModal from "../../../loader/LoadingModal";
import DeleteModal from "../../../deleteModal/DeleteModal";
import {
  AddInvoiceItemURL,
  DeleteInvoiceItemURL,
  GetSingleInvoiceDataURL,
  GetSupplierAndRawMaterialListURL,
  ScriptId,
  ScriptSRC,
  UpdateInvoiceItemURL,
  UpdateInvoiceURL,
} from "../../../assets/api/BaseURL";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";

const EditPurchase = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [invoiceID, setInvoiceID] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState({});
  const [suppliersList, setSuppliersList] = useState([]);
  const [rawMaterialsList, setRawMaterialsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [formData, setFormData] = useState({
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    supplier: "",
    referenceNumber: "",
    note: "",
    items: [],
    subtotal: "",
    tax: "",
    total: "",
  });
  const [productData, setProductData] = useState({
    productId: "",
    productName: "",
    quantity: "1",
    purchasePrice: "",
    expiryDate: "",
    basePrice: "",
    discount: "",
    taxable: "",
    taxableValue: "",
    tax: "",
    taxAmount: "",
    amount: "",
    closingStock: "",
  });
  const [isVisible, setIsVisible] = useState(false);
  const [productEditData, setProductEditData] = useState({
    id: "",
    productId: "",
    productName: "",
    quantity: "1",
    purchasePrice: "",
    expiryDate: "",
    basePrice: "",
    discount: "",
    taxable: "",
    taxableValue: "",
    tax: "",
    taxAmount: "",
    amount: "",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/view_purchase");
  };

  function convertDateFormat(inputDate) {
    if (!inputDate || inputDate.trim() === "") {
      return "";
    }

    const parts = inputDate.split("-");
    const outputDate = parts[2] + "-" + parts[1] + "-" + parts[0];

    return outputDate;
  }

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError("");
    setSuccess("");
  };

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "productId") {
      if (value === "") {
        setProductData((prevData) => ({
          ...prevData,
          productId: "",
          productName: "",
          purchasePrice: "",
          quantity: "1",
          amount: "",
          closingStock: "",
        }));
      } else {
        const selectedProduct = rawMaterialsList.find(
          (item) => item.id === parseInt(value)
        );

        if (selectedProduct) {
          const quantity = productData.quantity;
          const purchasePrice = selectedProduct.purchase_price;
          const amount = parseFloat(quantity) * parseFloat(purchasePrice);

          setProductData((prevData) => ({
            ...prevData,
            productId: value,
            productName: selectedProduct.material_name,
            purchasePrice: selectedProduct.purchase_price,
            closingStock: selectedProduct.closing_stock,
            amount: isNaN(amount) ? "" : amount.toFixed(2),
          }));
        }
      }
    } else if (name === "quantity" || name === "purchasePrice") {
      const quantity = name === "quantity" ? value : productData.quantity;
      const purchasePrice =
        name === "purchasePrice" ? value : productData.purchasePrice;
      const amount = parseFloat(quantity) * parseFloat(purchasePrice);

      setProductData((prevData) => ({
        ...prevData,
        [name]: value,
        amount: isNaN(amount) ? "" : amount.toFixed(2),
      }));
    } else {
      setProductData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setError("");
    setSuccess("");
  };

  const handleProductEditInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "productId") {
      if (value === "") {
        setProductEditData((prevData) => ({
          ...prevData,
          productId: "",
          productName: "",
          purchasePrice: "",
          quantity: "1",
          amount: "",
          closingStock: "",
        }));
      } else {
        const selectedProduct = rawMaterialsList.find(
          (item) => item.id === parseInt(value)
        );

        if (selectedProduct) {
          const quantity = productEditData.quantity;
          const purchasePrice = selectedProduct.purchase_price;
          const amount = parseFloat(quantity) * parseFloat(purchasePrice);

          setProductEditData((prevData) => ({
            ...prevData,
            productId: value,
            productName: selectedProduct.material_name,
            purchasePrice: selectedProduct.purchase_price,
            closingStock: selectedProduct.closing_stock,
            amount: isNaN(amount) ? "" : amount.toFixed(2),
          }));
        }
      }
    } else if (name === "quantity" || name === "purchasePrice") {
      const quantity = name === "quantity" ? value : productEditData.quantity;
      const purchasePrice =
        name === "purchasePrice" ? value : productEditData.purchasePrice;
      const amount = parseFloat(quantity) * parseFloat(purchasePrice);

      setProductEditData((prevData) => ({
        ...prevData,
        [name]: value,
        amount: isNaN(amount) ? "" : amount.toFixed(2),
      }));
    } else {
      setProductEditData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setError("");
    setSuccess("");
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (formData && !(formData.items.length > 1)) {
      addToast("error", "Please add more items to delete this item!");
    } else {
      setDeleteId([id]);
      setShowDeleteModal(!showDeleteModal);
    }
  };

  const handleDeleteInvoiceItem = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteInvoiceItemURL,
        data: deleteId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setFlag(!flag);
        setShowDeleteModal(!showDeleteModal);
        addToast("success", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const handleAddProductClick = async () => {
    if (productData.productId === "") {
      setError("Product can not be empty");
      setSuccess("");
    } else if (
      formData.items.some(
        (item) => item.productId === Number(productData.productId)
      )
    ) {
      setError("The same product has already been added");
      setSuccess("");
    } else if (productData.quantity === "") {
      setError("Quantity can not be empty");
      setSuccess("");
    } else if (!isValidNumber(productData.quantity)) {
      setError("Please enter valid Quantity");
      setSuccess("");
    } else if (productData.purchasePrice === "") {
      setError("Purchase Price can not be empty");
      setSuccess("");
    } else if (!isValidNumber(productData.purchasePrice)) {
      setError("Please enter valid Purchase Price");
      setSuccess("");
    }
    // else if (productData.expiryDate === "") {
    //   setError("Expiry Date can not be empty");
    //   setSuccess("");
    // }
    else {
      const dataToSend = {
        invoice_id: invoiceID,
        ...productData,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddInvoiceItemURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setProductData({
            productId: "",
            productName: "",
            quantity: "1",
            purchasePrice: "",
            expiryDate: "",
            basePrice: "",
            discount: "",
            taxable: "",
            taxableValue: "",
            tax: "",
            taxAmount: "",
            amount: "",
            closingStock: "",
          });
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  const handleEditClick = (item) => {
    setProductEditData(item);
    setIsVisible(true);
  };

  const handleSaveProductClick = async () => {
    if (productEditData.quantity === "") {
      setError("Quantity can not be empty");
      setSuccess("");
    } else if (!isValidNumber(productEditData.quantity)) {
      setError("Please enter valid Quantity");
      setSuccess("");
    } else if (productEditData.purchasePrice === "") {
      setError("Purchase Price can not be empty");
      setSuccess("");
    } else if (!isValidNumber(productEditData.purchasePrice)) {
      setError("Please enter valid Purchase Price");
      setSuccess("");
    } else {
      const dataToSend = {
        id: productEditData.id,
        quantity: productEditData.quantity,
        price: productEditData.purchasePrice,
        amount: productEditData.amount,
        expiry_date: productEditData.expiryDate,
      };

      try {
        const response = await axios({
          method: "put",
          url: UpdateInvoiceItemURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setProductEditData({
            id: "",
            productId: "",
            productName: "",
            quantity: "1",
            purchasePrice: "",
            expiryDate: "",
            basePrice: "",
            discount: "",
            taxable: "",
            taxableValue: "",
            tax: "",
            taxAmount: "",
            amount: "",
          });
          setIsVisible(false);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  const handleUpdateInvoice = async () => {
    if (formData.invoiceNumber === "" || formData.invoiceNumber.trim() === "") {
      setError("Purchase Number can not be empty");
      setSuccess("");
    } else if (formData.invoiceDate === "") {
      setError("Purchase order Date can not be empty");
      setSuccess("");
    } else if (formData.dueDate === "") {
      setError("Due Date can not be empty");
      setSuccess("");
    } else if (new Date(formData.dueDate) < new Date(formData.invoiceDate)) {
      setError("Due Date can not be before Purchase order Date");
      setSuccess("");
    } else if (formData.supplier === "") {
      setError("Please select supplier");
      setSuccess("");
    } else if (formData.items.length === 0) {
      setError("Please add atleast one product to create invoice");
      setSuccess("");
    } else {
      const dataToSend = {
        invoice_id: invoiceID,
        ...formData,
      };

      try {
        const response = await axios({
          method: "put",
          url: UpdateInvoiceURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    setInvoiceID(localStorage.getItem("invoiceID"));
  }, [invoiceID]);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetSupplierAndRawMaterialListURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data) {
            setSuppliersList(
              response.data.supplierList ? response.data.supplierList : []
            );
            setRawMaterialsList(
              response.data.rawMaterialsList
                ? response.data.rawMaterialsList
                : []
            );
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, addToast]);

  useEffect(() => {
    if (token && invoiceID) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(
            GetSingleInvoiceDataURL + `?id=${invoiceID}`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          );

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setFormData({
              invoiceNumber: response.data.data.invoiceNumber
                ? response.data.data.invoiceNumber
                : "",
              invoiceDate: response.data.data.invoiceDate
                ? response.data.data.invoiceDate
                : "",
              dueDate: response.data.data.dueDate
                ? response.data.data.dueDate
                : "",
              supplier: response.data.data.supplier
                ? response.data.data.supplier
                : "",
              referenceNumber: response.data.data.referenceNumber
                ? response.data.data.referenceNumber
                : "",
              note: response.data.data.note ? response.data.data.note : "",
              items: response.data.data.items ? response.data.data.items : [],
              subtotal: response.data.data.subtotal
                ? response.data.data.subtotal
                : "",
              tax: response.data.data.tax ? response.data.data.tax : "",
              total: response.data.data.total ? response.data.data.total : "",
            });
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, invoiceID, flag, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  // useEffect(() => {
  //   if (formData.items) {
  //     const subtotal = formData.items.reduce(
  //       (acc, item) => acc + parseFloat(item.taxableValue),
  //       0
  //     );

  //     const taxTotal = formData.items.reduce(
  //       (acc, item) => acc + parseFloat(item.taxAmount),
  //       0
  //     );

  //     const total = subtotal + taxTotal;

  //     setFormData((prevData) => ({
  //       ...prevData,
  //       subtotal: subtotal,
  //       tax: taxTotal,
  //       total: total,
  //     }));
  //   }
  // }, [formData.items]);

  useEffect(() => {
    if (formData.items) {
      const subtotal = formData.items.reduce(
        (acc, item) => acc + parseFloat(item.amount),
        0
      );

      setFormData((prevData) => ({
        ...prevData,
        total: subtotal,
      }));
    }
  }, [formData.items]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_purchase" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="d-flex justify-content-between align-items-start mb-2">
                        <div className="vsalign">
                          <h4 className="card-title card-title-dash">
                            Edit Purchase
                          </h4>
                        </div>
                        <div className="vsalign">
                          <span
                            className="btn btn-danger mb-0 me-0"
                            onClick={handleBackClick}
                          >
                            <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                            Go Back
                          </span>
                        </div>
                      </div>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <form className="">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label>
                                        Purchase Number
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="invoiceNumber"
                                        value={formData.invoiceNumber}
                                        readOnly={true}
                                      // onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label>
                                        Purchase order Date
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="date"
                                        placeholder="Purchase order Date"
                                        name="invoiceDate"
                                        value={formData.invoiceDate}
                                        onChange={handleInputChange}
                                        readOnly={data && data.status === 1}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label>
                                        Due Date
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="date"
                                        placeholder="Due Date"
                                        name="dueDate"
                                        value={formData.dueDate}
                                        onChange={handleInputChange}
                                        readOnly={data && data.status === 1}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label>
                                        Suppliers
                                        <span className="text-danger">*</span>
                                      </label>
                                      {data && data.status === 1 && (
                                        <>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={data.suppliers_name}
                                            readOnly={true}
                                          />
                                        </>
                                      )}
                                      {data && data.status === 0 && (
                                        <>
                                          <select
                                            className="form-select"
                                            name="supplier"
                                            value={formData.supplier}
                                            onChange={handleInputChange}
                                          >
                                            <option value="">
                                              Select Supplier
                                            </option>
                                            {suppliersList.map((item) => (
                                              <option
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item.suppliers_name}
                                              </option>
                                            ))}
                                          </select>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label> Reference Number</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Reference Number"
                                        name="referenceNumber"
                                        value={formData.referenceNumber}
                                        onChange={handleInputChange}
                                        readOnly={data && data.status === 1}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Note</label>
                                      <textarea
                                        className="form-control"
                                        placeholder="Enter Note"
                                        rows="3"
                                        name="note"
                                        value={formData.note}
                                        onChange={handleInputChange}
                                        readOnly={data && data.status === 1}
                                      ></textarea>
                                    </div>
                                  </div>

                                  {data && data.status === 0 && (
                                    <>
                                      <hr className="mt-2" />

                                      <div className="table-responsive  mt-1">
                                        <table className="table select-table itemtb">
                                          <thead>
                                            <tr>
                                              <th>Product</th>
                                              <th>
                                                Purchase Price ({currencySymbol}
                                                )
                                              </th>
                                              <th>Quantity</th>
                                              <th>Expiry Date</th>
                                              <th>Amount ({currencySymbol})</th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {isVisible ? (
                                              <>
                                                <tr>
                                                  <td>
                                                    <h6>
                                                      {
                                                        productEditData.productName
                                                      }
                                                    </h6>
                                                  </td>
                                                  <td>
                                                    <input
                                                      className="form-control"
                                                      type="text"
                                                      placeholder=""
                                                      name="purchasePrice"
                                                      value={
                                                        productEditData.purchasePrice
                                                      }
                                                      onChange={
                                                        handleProductEditInputChange
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      className="form-control"
                                                      type="text"
                                                      placeholder=""
                                                      name="quantity"
                                                      value={
                                                        productEditData.quantity
                                                      }
                                                      onChange={
                                                        handleProductEditInputChange
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      className="form-control"
                                                      type="date"
                                                      placeholder=""
                                                      name="expiryDate"
                                                      value={
                                                        productEditData.expiryDate
                                                      }
                                                      onChange={
                                                        handleProductEditInputChange
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      className="form-control"
                                                      type="text"
                                                      placeholder=""
                                                      name="amount"
                                                      value={
                                                        productEditData.amount
                                                      }
                                                      readOnly={true}
                                                    />
                                                  </td>
                                                  <td>
                                                    <button
                                                      className="btn btn-primary btn-sm me-2"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSaveProductClick();
                                                      }}
                                                    >
                                                      Save
                                                    </button>
                                                    <button
                                                      className="btn btn btn-secondary btn-sm"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        setProductEditData({
                                                          id: "",
                                                          productId: "",
                                                          productName: "",
                                                          quantity: "1",
                                                          purchasePrice: "",
                                                          expiryDate: "",
                                                          basePrice: "",
                                                          discount: "",
                                                          taxable: "",
                                                          taxableValue: "",
                                                          tax: "",
                                                          taxAmount: "",
                                                          amount: "",
                                                        });
                                                        setIsVisible(false);
                                                      }}
                                                    >
                                                      Cancel
                                                    </button>
                                                  </td>
                                                </tr>
                                              </>
                                            ) : (
                                              <>
                                                <tr>
                                                  <td>
                                                    <select
                                                      className="form-select"
                                                      name="productId"
                                                      value={
                                                        productData.productId
                                                      }
                                                      onChange={
                                                        handleProductInputChange
                                                      }
                                                    >
                                                      <option value="">
                                                        Select Product
                                                      </option>
                                                      {rawMaterialsList.map(
                                                        (item) => (
                                                          <option
                                                            key={item.id}
                                                            value={item.id}
                                                          >
                                                            {item.material_name}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                    {productData &&
                                                      productData.closingStock && (
                                                        <label className="stocklb">
                                                          Stock :{" "}
                                                          {
                                                            productData.closingStock
                                                          }
                                                        </label>
                                                      )}
                                                  </td>
                                                  <td>
                                                    <input
                                                      className="form-control"
                                                      type="text"
                                                      placeholder=""
                                                      name="purchasePrice"
                                                      value={
                                                        productData.purchasePrice
                                                      }
                                                      onChange={
                                                        handleProductInputChange
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      className="form-control"
                                                      type="text"
                                                      placeholder=""
                                                      name="quantity"
                                                      value={
                                                        productData.quantity
                                                      }
                                                      onChange={
                                                        handleProductInputChange
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      className="form-control"
                                                      type="date"
                                                      placeholder=""
                                                      name="expiryDate"
                                                      value={
                                                        productData.expiryDate
                                                      }
                                                      onChange={
                                                        handleProductInputChange
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      className="form-control"
                                                      type="text"
                                                      placeholder=""
                                                      name="amount"
                                                      value={productData.amount}
                                                      readOnly={true}
                                                    />
                                                  </td>
                                                  <td>
                                                    <button
                                                      className="btn btn-primary btn-sm"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddProductClick();
                                                      }}
                                                    >
                                                      Add
                                                    </button>
                                                  </td>
                                                </tr>
                                              </>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </>
                                  )}

                                  <hr className="mt-3" />

                                  {formData &&
                                    formData.items &&
                                    formData.items.length > 0 && (
                                      <>
                                        <div className="table-responsive  mt-1">
                                          <table className="table select-table itemtb">
                                            <thead>
                                              <tr>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Expiry Date</th>
                                                <th>Amount</th>
                                                <th>&nbsp;</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {formData &&
                                                formData.items &&
                                                formData.items.map(
                                                  (item, index) => (
                                                    <tr key={index}>
                                                      <td>
                                                        {item.productName}
                                                      </td>
                                                      <td>
                                                        Rs{" "}
                                                        {parseFloat(
                                                          item.purchasePrice
                                                        ).toFixed(2)}
                                                      </td>
                                                      <td>{item.quantity}</td>
                                                      <td>
                                                        {convertDateFormat(
                                                          item.expiryDate
                                                        )}
                                                      </td>
                                                      <td>
                                                        Rs{" "}
                                                        {parseFloat(
                                                          item.amount
                                                        ).toFixed(2)}
                                                      </td>
                                                      <td>
                                                        {data &&
                                                          data.status === 0 && (
                                                            <>
                                                              <i
                                                                className="fs-20 mdi mdi-content-save-edit-outline text-success"
                                                                title="Edit"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  handleEditClick(
                                                                    item
                                                                  );
                                                                }}
                                                              ></i>
                                                              &nbsp;&nbsp;
                                                              <i
                                                                className="fs-20 mdi mdi-archive text-danger"
                                                                title="Delete"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  handleDelete(
                                                                    e,
                                                                    item.id
                                                                  );
                                                                }}
                                                              ></i>
                                                            </>
                                                          )}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                            </tbody>
                                            <tfoot id="">
                                              <tr>
                                                <td colSpan="3"></td>
                                                <td>
                                                  <b>Total</b>
                                                </td>
                                                <td colSpan="2">
                                                  <b>
                                                    Rs{" "}
                                                    {parseFloat(
                                                      formData.total
                                                    ).toFixed(2)}
                                                  </b>
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      </>
                                    )}
                                </div>

                                <div className="mt-3">
                                  {data && data.status === 0 && (
                                    <>
                                      <button
                                        type="submit"
                                        className="btn btn-primary me-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleUpdateInvoice();
                                        }}
                                      >
                                        Submit
                                      </button>
                                    </>
                                  )}

                                  <button
                                    className="btn btn btn-secondary"
                                    onClick={handleBackClick}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showDeleteModal && (
            <DeleteModal
              handleModal={setShowDeleteModal}
              handleDelete={handleDeleteInvoiceItem}
            />
          )}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default EditPurchase;
