import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingModal from "../../loader/LoadingModal";
import {
  // GetCityListURL,
  GetStateListURL,
  SignupURL,
} from "../../assets/api/BaseURL";
import { useToast } from "../../../context/ToastContext";
// import LiveChat from "../other/LiveChat";

const Signup = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  // const [cityList, setCityList] = useState([]);
  const [formData, setFormData] = useState({
    fooderName: "",
    fooderType: "",
    fooderState: "",
    fooderCity: "",
    contactName: "",
    contactEmail: "",
    registerMobile: "",
    username: "",
    password: "",
    retypePassword: "",
    address: "",
    zipcode: "",
    isGSTRegistered: false,
    gstin: "",
    gst_type: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox" && name === "isGSTRegistered" && !checked) {
      setFormData({
        ...formData,
        [name]: checked,
        gst_type: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
    setError("");
    setSuccess("");
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileNumberPattern = /^\d{10}$/;
    const zipcodePattern = /^[1-9][0-9]{5}$/;

    if (formData.fooderName === "" || formData.fooderName.trim() === "") {
      setError("Fooder's/Outlet name can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (
      formData.fooderType === "" ||
      formData.fooderType.trim() === ""
    ) {
      setError("Fooder's/Outlet Type can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (
      formData.fooderState === "" ||
      formData.fooderState.trim() === ""
    ) {
      setError("Fooder's/Outlet state can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (
      formData.fooderCity === "" ||
      formData.fooderCity.trim() === ""
    ) {
      setError("Fooder's/Outlet city can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (
      formData.contactName === "" ||
      formData.contactName.trim() === ""
    ) {
      setError("Contact name can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (
      formData.contactEmail === "" ||
      formData.contactEmail.trim() === ""
    ) {
      setError("Contact email can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (!email_pattern.test(formData.contactEmail)) {
      setError("Please Enter valid email");
      setSuccess("");
      scrollToTop();
    } else if (
      formData.registerMobile === "" ||
      formData.registerMobile.trim() === ""
    ) {
      setError("Register Mobile Number can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (!mobileNumberPattern.test(formData.registerMobile)) {
      setError("Please enter a valid 10-digit Register mobile number.");
      setSuccess("");
      scrollToTop();
    } else if (formData.username === "" || formData.username.trim() === "") {
      setError("Username can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (formData.password === "" || formData.password.trim() === "") {
      setError("Password can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (
      formData.retypePassword === "" ||
      formData.retypePassword.trim() === ""
    ) {
      setError("Re-type Password can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (formData.password !== formData.retypePassword) {
      setError("Passwords do not match.");
      setSuccess("");
      scrollToTop();
    } else if (formData.address === "" || formData.address.trim() === "") {
      setError("Address can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (formData.zipcode === "" || formData.zipcode.trim() === "") {
      setError("Zip Code can not be empty");
      setSuccess("");
      scrollToTop();
    } else if (!zipcodePattern.test(formData.zipcode)) {
      setError("Please Enter a valid 6-digit Zip Code");
      setSuccess("");
      scrollToTop();
    } else if (
      formData.isGSTRegistered &&
      (formData.gstin === "" || formData.gstin.trim() === "")
    ) {
      setError("GSTIN can not be empty");
      setSuccess("");
      scrollToTop();
    } else {
      const data = {
        name: formData.fooderName,
        type: formData.fooderType,
        state: formData.fooderState,
        city: formData.fooderCity,
        contact_name: formData.contactName,
        login_email: formData.contactEmail,
        login_mobile_number: formData.registerMobile,
        username: formData.username,
        password: formData.password,
        confirmPassword: formData.retypePassword,
        address: formData.address,
        zipcode: formData.zipcode,
        gstin: formData.isGSTRegistered ? formData.gstin : "",
        gst_type: formData.isGSTRegistered ? (formData.gst_type ? 1 : 0) : 0,
      };
      setIsLoading(true);

      try {
        const response = await axios({
          method: "post",
          url: SignupURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 201 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          scrollToTop();
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
        setIsLoading(false);

      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          scrollToTop();
          setSuccess("");
        } else {
          setError(error.message);
          scrollToTop();
          setSuccess("");
        }
        console.log(error);
        setIsLoading(false);

      }
    }
  };

  useEffect(() => {
    async function getStateList() {
      setIsLoading(true);
      try {
        const response = await axios.get(GetStateListURL);

        if (response.status === 200 && response.data.data) {
          setStateList(response.data.data);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
      setIsLoading(false);
    }
    getStateList();
  }, [addToast]);

  // useEffect(() => {
  //   if (formData.fooderState) {
  //     async function getStateList() {
  //       setIsLoading(true);

  //       try {
  //         const response = await axios.get(
  //           GetCityListURL + `?state_id=${formData.fooderState}`
  //         );

  //         if (response.status === 200 && response.data.data) {
  //           setCityList(response.data.data);
  //         }
  //       } catch (error) {
  //         if (error.response && error.response.data.message) {
  //           addToast("error", error.response.data.message);
  //         } else {
  //           addToast("error", error.message);
  //         }
  //         console.log(error);
  //       }
  //       setIsLoading(false);
  //     }
  //     getStateList();
  //   }
  // }, [formData.fooderState, addToast]);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper px-0">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="text-center mb-2">
                <img
                  src="images/Khateraho_logo.png"
                  alt="logo"
                  className="loginlogo"
                />
              </div>
              <div className="col-lg-8 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <h4>New Registration</h4>
                  <h6 className="fw-light">Signup to continue.</h6>
                  <form className="pt-3">
                    {error && (
                      <div className="d-flex justify-content-between align-items-start alert alert-danger">
                        <div className="vsalign">{error}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setError("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}
                    {success && (
                      <div className="d-flex justify-content-between align-items-start alert alert-success">
                        <div className="vsalign">{success}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSuccess("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Fooder's/Outlet name"
                          name="fooderName"
                          value={formData.fooderName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <select
                          style={{ padding: "0.94rem 1.94rem" }}
                          className={
                            formData.fooderType
                              ? "form-control form-control-lg text-dark"
                              : "form-control form-control-lg"
                          }
                          name="fooderType"
                          value={formData.fooderType}
                          onChange={handleInputChange}
                        >
                          <option value="">Fooder's/Outlet Type</option>
                          <option value="1">Restaurant</option>
                          <option value="2">Confectioners (Sweets Shop)</option>
                          <option value="3">Bakers</option>
                          <option value="4">Multiplex</option>
                        </select>
                      </div>

                      <div className="col-lg-6 form-group">
                        <select
                          style={{ padding: "0.94rem 1.94rem" }}
                          className={
                            formData.fooderState
                              ? "form-control form-control-lg text-dark"
                              : "form-control form-control-lg"
                          }
                          name="fooderState"
                          value={formData.fooderState}
                          onChange={handleInputChange}
                        >
                          <option value="">Fooder's/Outlet state</option>
                          {stateList &&
                            stateList.map((item) => (
                              <option key={item.id} value={`${item.id}`}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      {/* <div className="col-lg-6 form-group">
                        <select
                          style={{ padding: "0.94rem 1.94rem" }}
                          className={
                            formData.fooderCity
                              ? "form-control form-control-lg text-dark"
                              : "form-control form-control-lg"
                          }
                          name="fooderCity"
                          value={formData.fooderCity}
                          onChange={handleInputChange}
                        >
                          <option value="">Fooder's/Outlet city</option>
                          {cityList &&
                            cityList.map((item) => (
                              <option key={item.id} value={`${item.id}`}>
                                {item.city}
                              </option>
                            ))}
                        </select>
                      </div> */}
                      <div className="col-lg-6 form-group">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Fooder's/Outlet city"
                          name="fooderCity"
                          value={formData.fooderCity}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Contact name"
                          name="contactName"
                          value={formData.contactName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          placeholder="Contact email"
                          name="contactEmail"
                          value={formData.contactEmail}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Register Mobile Number"
                          maxLength={10}
                          name="registerMobile"
                          value={formData.registerMobile}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Username"
                          name="username"
                          value={formData.username}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="password"
                          className="form-control form-control-lg"
                          placeholder="Password"
                          name="password"
                          value={formData.password}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="password"
                          className="form-control form-control-lg"
                          placeholder="Re-type Password"
                          name="retypePassword"
                          value={formData.retypePassword}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Address"
                          name="address"
                          value={formData.address}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Zip Code"
                          name="zipcode"
                          value={formData.zipcode}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="isGSTRegistered"
                            checked={formData.isGSTRegistered}
                            onChange={handleInputChange}
                          />
                          <label className="form-check-label text-muted">
                            Is GST Registered
                          </label>
                        </div>
                        {formData.isGSTRegistered && (
                          <>
                            <div className="form-group mb-2">
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter GSTIN"
                                name="gstin"
                                value={formData.gstin}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="gst_type"
                                checked={formData.gst_type}
                                onChange={handleInputChange}
                              />
                              <label className="form-check-label text-muted">
                                Composition Scheme
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="mt-2 text-right">
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={handleSubmit}
                      >
                        SIGN UP
                      </button>
                    </div>
                    {/* <div className="my-2 d-flex justify-content-between align-items-center">
                      <div className="form-check"></div>
                      <Link to="/forgot_password">
                        <span className="auth-link text-black">
                          Forgot password?
                        </span>
                      </Link>
                    </div> */}
                    <div className="text-right mt-4 fw-light">
                      Have an account?{" "}
                      <Link to="/">
                        <span className="text-primary">Login</span>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <LiveChat /> */}

      <LoadingModal isLoading={isLoading} />
    </>
  );
};

export default Signup;
