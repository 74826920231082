import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {


    ScriptId,
    ScriptSRC,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const VarUpdates = () => {
    const navigate = useNavigate();

    const [token, setToken] = useState("");
    const [toggleSideBar, setToggleSideBar] = useState(false);
    const [canvas, setCanvas] = useState(false);


    const [isLoading, setIsLoading] = useState(false);


    const handleToggleSidebar = (e) => {
        e.preventDefault();
        setToggleSideBar(!toggleSideBar);
    };

    const handleToggleOffCanvas = (e) => {
        e.preventDefault();
        setCanvas(!canvas);
    };





    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        setToken(localStorage.getItem("token"));
    }, [token]);

    useEffect(() => {
        const existingScript = document.getElementById(ScriptId);
        if (!existingScript && localStorage.getItem("userId")) {
            const userId = localStorage.getItem("userId");
            const script = document.createElement("script");
            script.src = ScriptSRC + userId.replace(/"/g, "");
            script.defer = true;
            script.id = ScriptId;
            document.head.appendChild(script);
        }
    }, []);




    return (
        <>
            {token && (
                <>
                    <div
                        className={
                            toggleSideBar
                                ? "container-scroller sidebar-icon-only"
                                : "container-scroller"
                        }
                    >
                        <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
                            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                                    <img src="images/mini-kr-logo.svg" alt="logo" />
                                </Link>
                                <div className="me-1">
                                    <button
                                        className={
                                            toggleSideBar
                                                ? "navbar-toggler navbar-toggler align-self-center"
                                                : "navbar-toggler navbar-toggler align-self-center text-white"
                                        }
                                        type="button"
                                        onClick={handleToggleSidebar}
                                    >
                                        <span className="icon-menu"></span>
                                    </button>
                                </div>
                                <div>
                                    <Link to="/live_orders">
                                        <span className="navbar-brand brand-logo">
                                            <img src="images/Khateraho_logo_white.png" alt="logo" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <NavBar handleToggle={handleToggleOffCanvas} />
                        </nav>
                        <div className="container-fluid page-body-wrapper px-0">
                            <SideBar active="update" toggleCanvas={canvas} />

                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="ukhd mb-3">Updates</h3>
                                            <div className="row flex-grow">
                                                <div className="col-12 grid-margin">

                                                    {/*Version 3.4<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 3.0.1</b></h6>
                                                                            <h6>What's New in Version 3.0.1</h6>
                                                                            <h6><i>DATE: 5 Sep 2024</i></h6>

                                                                            <ul className="kr-none">
                                                                            <li className="kr-none">Add Minimum Item Quantity on Pos.</li>
                                                                            <li className="kr-none">Bug Fixes on Table committed.</li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 2.4<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 2.0.4</b></h6>
                                                                             <h6><i>DATE: 1 July 2024</i></h6>

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Search bar added on Inventory.</li>
                                                                                <li className="kr-none">Add Table Engaged Functionality in POS.</li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 2.3<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 2.0.3</b></h6>
                                                                            <h6><i>DATE: 04 June  2024</i></h6>


                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Add cancel KOT Functionality on order cancel.</li>
                                                                                <li className="kr-none">Show Round off amount on Bill.</li>
                                                                                <li className="kr-none">Add Close Button POS items search bar.</li>
                                                                                <li className="kr-none">Bug Fixes on Paid and Cancelled orders.</li>
                                                                            </ul>

                                                                            <h6><i>DATE: 05 June  2024</i></h6>

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Add functionality to print multiple bills in a single click.</li>
                                                                            </ul>

                                                                            <h6><i>DATE: 17 June  2024</i></h6>

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Add item wise Sales Report.</li>
                                                                                <li className="kr-none">Add functionality to Switch table in POS.</li>
                                                                                <li className="kr-none">Bug Fixes on Live orders.</li>
                                                                                <li className="kr-none">Payment method can be change on orders.</li>
                                                                            </ul>




                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 2.2<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 2.0.2</b></h6>
                                                                            <h6><i>DATE: 20 May  2024</i></h6>
                                                                            {/* <h6>What's New in Version 2.0.2</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Variant and Addons Integration in POS.</li>
                                                                                <li className="kr-none">Product Wise Tax Functionality Added.</li>
                                                                                <li className="kr-none">Button Added to Clear Table and Logout.</li>
                                                                            </ul>
                                                                            <h6><i>DATE: 22 May  2024</i></h6>
                                                                            <ul>
                                                                                <li className="kr-none">Kitchen Display System Integration.</li>

                                                                            </ul>
                                                                            <h6><i>DATE: 29 May  2024</i></h6>
                                                                            <ul>
                                                                                <li className="kr-none">Add Round off Functionality.</li>


                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 2.1<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 2.0.1</b></h6>
                                                                            <h6><i>DATE: 2 May  2024</i></h6>
                                                                            {/* <h6>What's New in Version 2.1</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Bug Fixes on Live Orders Payment Description.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 2.0<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 2.0.0</b></h6>
                                                                            <h6><i>DATE: 23 April  2024</i></h6>
                                                                            {/* <h6>What's New in Version 2.0</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Filters Added in Order Type Reports.</li>
                                                                                <li className="kr-none">Custom Sales Reports Added with Filters.</li>

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/*Version 1.9<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.9</b></h6>
                                                                            <h6><i>DATE: 15 April  2024</i></h6>
                                                                            {/* <h6>What's New in Version 1.9</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Add New Functionality to Pay the Due Amount on Live Orders.</li>

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 1.8<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.8</b></h6>
                                                                            <h6><i>DATE: 11 April  2024</i></h6>
                                                                            {/* <h6>What's New in Version 1.8</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Partial Payment Functionality Added in POS, Live Orders and Order Details.</li>
                                                                                <li className="kr-none">NEFT Payment Method Added.</li>
                                                                                <li className="kr-none">Extra Item KOT Generation Functionality Added on QR Code Orders.</li>
                                                                                <li className="kr-none">Functionality Added to Record Transaction / Reference Number.</li>
                                                                                <li className="kr-none">Functionality Added to Stop Bill Issuance on Cancelled or Rejected Orders Via QR Code.</li>



                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/*Version 1.7<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.7</b></h6>
                                                                            <h6><i>DATE: 1 April  2024</i></h6>
                                                                            {/* <h6>What's New in Version 1.7</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Invoice Number Added on Order Details and Bills.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 1.6<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.6</b></h6>
                                                                            <h6><i>DATE: 19 March  2024</i></h6>
                                                                            {/* <h6>What's New in Version 1.6</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Include Payment Breakdown in Daily Sales Report.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 1.5<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.5</b></h6>
                                                                            <h6><i>DATE: 18 March  2024</i></h6>
                                                                            {/* <h6>What's New in Version 1.5</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Billing Note Added in Fooders CP.</li>
                                                                                <li className="kr-none">Fooder Address and Phone Number Added on Bills.</li>
                                                                                <li className="kr-none">Payment Details and Billing Note Added on Bills.</li>

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 1.4<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.4</b></h6>
                                                                            <h6><i>DATE: 14 March  2024</i></h6>
                                                                            {/* <h6>What's New in Version 1.4</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Daily Sales Report Added.</li>
                                                                                <li className="kr-none">Restaurants Time Range Added in Fooders CP.</li>
                                                                                <li className="kr-none">Item Wise Instruction Added In KOT Prints.</li>


                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/*Version 1.3<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.3</b></h6>
                                                                            <h6><i>DATE: 11 March  2024</i></h6>
                                                                            {/* <h6>What's New in Version 1.3</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">In POS, Product Name UI Changes.</li>

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/*Version 1.2<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.2</b></h6>
                                                                            <h6><i>DATE: 1 March  2024</i></h6>
                                                                            {/* <h6>What's New in Version 1.2</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Purchase Order Functionality Added in Inventory.</li>
                                                                                <li className="kr-none">Purchase Return Functionality Added in Inventory.</li>
                                                                                <li className="kr-none">Stock List Added in Inventory.</li>
                                                                                <li className="kr-none">Raw Material Menu Update.</li>
                                                                                <li className="kr-none">Bug Fixes in Stock Increment.</li>
                                                                                <li className="kr-none">Bug Fixes in Stock Deduction.</li>
                                                                                <li className="kr-none">In POS, Product Name UI Changes.</li>
                                                                                <li className="kr-none">Changes in Recipes.</li>
                                                                                <li className="kr-none">Area-Based Stock Management.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    {/*Version 1.1<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.1</b></h6>
                                                                            <h6><i>DATE: 19 Jan 2024 </i></h6>
                                                                            {/* <h6>What's New in Version 1.1</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">Live Orders: Display Order Numbers.</li>
                                                                                <li className="kr-none">Online Orders: Exclude Counter.</li>
                                                                                <li className="kr-none">Order Cancelled Shown in Order Details Page.</li>
                                                                                <li className="kr-none">Order Cancelation Reason Shown in Order Details Page.</li>

                                                                                <li className="kr-none">In Add Table Page, The Required Condition of Table Name and Word 'Table' are Removed.</li>
                                                                                <li className="kr-none">Reports: Back Button Functionality Added.</li>
                                                                                <li className="kr-none">In POS: Adjust KOT Button.</li>
                                                                                <li className="kr-none">Version Design Changed.</li>
                                                                                <li className="kr-none">Registration: Add Contact Details for Notifications.</li>

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Version 1.0<*/}
                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-between">

                                                                        <div className="col-12">
                                                                            <h6 className="update-ver-color"><b>Version 1.0.0</b></h6>
                                                                            <h6><i>DATE: 18 Jan 2024 </i></h6>
                                                                            {/* <h6>What's New in Version 1.0</h6> */}

                                                                            <ul className="kr-none">
                                                                                <li className="kr-none">KOT Print Update and UI Changes.</li>
                                                                                <li className="kr-none">POS KOT UI Changes.</li>
                                                                                <li className="kr-none">Category Delete, New Functionality Added.</li>
                                                                                <li className="kr-none">Bug Fixes in Price and Promo Price, and Requires the Selection of At Least One Service Type.</li>

                                                                                <li className="kr-none">Daily Hour and URL Generation Added At The Time of Registration.</li>
                                                                                <li className="kr-none">Functionality Added to Cancel Order in Order Details Page.</li>
                                                                                <li className="kr-none">On the Fooders CP Page, The GSTIN Field is Editable, and the GST Type is Shown.</li>
                                                                                <li className="kr-none">Required Condition for Table Category is Removed.</li>
                                                                                <li className="kr-none">Bug Fixes in QR Code.</li>
                                                                                <li className="kr-none">In The POS Table List, Tables Without a Category are Shown Under "Other."</li>
                                                                                <li className="kr-none">FASSAI Number Added in Print Bill.</li>
                                                                                <li className="kr-none">Cancelled Orders are Excluded from GST Reports.</li>
                                                                                <li className="kr-none">Support Number Shown in Header.</li>
                                                                                <li className="kr-none">Live Chat Integration.</li>
                                                                                <li className="kr-none">Report Page UI Changes.</li>
                                                                                <li className="kr-none">Order Report Updated.</li>
                                                                                <li className="kr-none">Export to Excel Feature Added in Reports Section.</li>
                                                                                <li className="kr-none">In POS, Products are Shown by Order Type.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LoadingModal isLoading={isLoading} />

                    <Notification />
                </>
            )}
        </>
    );
};

export default VarUpdates;
