import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ForgotURL } from "../../assets/api/BaseURL";
// import LiveChat from "../other/LiveChat";

const ForgotPage = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleInput = (event) => {
    setUsername(event.target.value);
    setError("");
    setSuccess("");
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (username === "" || username.trim() === "") {
      setError("Username / Email can not be empty");
      setSuccess("");
    } else {
      try {
        const response = await axios({
          method: "post",
          url: ForgotURL,
          data: { username: username },
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setUsername("");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper px-0">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="text-center mb-2">
                <img
                  src="images/Khateraho_logo.png"
                  alt="logo"
                  className="loginlogo"
                />
              </div>
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <h4>Forgot Your Password?</h4>
                  <h6 className="fw-light">Welcome Back</h6>
                  <form className="pt-3">
                    {error && (
                      <div className="d-flex justify-content-between align-items-start alert alert-danger">
                        <div className="vsalign">{error}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setError("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}

                    {success && (
                      <div className="d-flex justify-content-between align-items-start alert alert-success">
                        <div className="vsalign">{success}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSuccess("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Username / Email"
                        name="username"
                        value={username}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={handleForgotPassword}
                      >
                        SUBMIT
                      </button>
                      <Link to="/">
                        <span className="text-primary">Back To Login</span>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <LiveChat /> */}
    </>
  );
};

export default ForgotPage;
