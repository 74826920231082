import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ResetURL } from "../../assets/api/BaseURL";
// import LiveChat from "../other/LiveChat";

const ResetPage = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setError("");
    setSuccess("");
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setError("");
    setSuccess("");
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (password === "" || password.trim() === "") {
      setError("Password can not be empty");
      setSuccess("");
    } else if (confirmPassword === "" || confirmPassword.trim() === "") {
      setError("Confirm Password can not be empty");
      setSuccess("");
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setSuccess("");
    } else {
      try {
        const response = await axios.post(ResetURL, {
          token: token,
          password: password,
          confirmPassword: confirmPassword,
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setPassword("");
          setConfirmPassword("");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setPassword("");
          setConfirmPassword("");
        } else {
          setError(error.message);
          setPassword("");
          setConfirmPassword("");
        }
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper px-0">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="text-center mb-2">
                <img
                  src="https://fooders.khateraho.com/images/Khateraho_logo.png"
                  alt="logo"
                  className="loginlogo"
                />
              </div>
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <h4>Forgot Your Password?</h4>
                  <h6 className="fw-light">Welcome Back</h6>
                  <form className="pt-3">
                    {error && (
                      <div className="d-flex justify-content-between align-items-start alert alert-danger">
                        <div className="vsalign">{error}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setError("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}

                    {success && (
                      <div className="d-flex justify-content-between align-items-start alert alert-success">
                        <div className="vsalign">{success}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSuccess("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        placeholder="Enter Password"
                        value={password}
                        onChange={handlePassword}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        placeholder="Re-type Password"
                        value={confirmPassword}
                        onChange={handleConfirmPassword}
                      />
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={handleResetPassword}
                      >
                        SUBMIT
                      </button>
                      <Link to="/">
                        <span className="text-primary">Back To Login</span>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <LiveChat /> */}
    </>
  );
};

export default ResetPage;
