import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import {
  GetGSTReportURL,
  ScriptId,
  ScriptSRC,
} from "../../../assets/api/BaseURL";
import LoadingModal from "../../../loader/LoadingModal";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const GSTReports = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [data, setData] = useState([]);
  const [reportData, setReportData] = useState({
    totalSubtotal: "",
    totalDiscount: "",
    totalTotal: "",
    totalPackingCharges:"",
    totalServiceCharge: "",
    totalTax: "",
    currency_symbol: "",
    start_date: "",
    end_date: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/reports");
  };

  function formatDateRange(inputDate) {
    const parsedDate = new Date(inputDate);
    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear().toString();

    return day + month + year;
  }

  function getToday() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (mm < 10) mm = "0" + mm;
    if (dd < 10) dd = "0" + dd;

    return `${yyyy}-${mm}-${dd}`;
  }

  function getCurrentDateFormatted() {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    const formattedDate = `${day < 10 ? "0" : ""}${day}/${
      month < 10 ? "0" : ""
    }${month}/${year}`;

    return formattedDate;
  }

  function formatDate(inputDate) {
    const [year, month, day] = inputDate.split("-");

    const date = new Date(year, month - 1, day);

    const formattedDay = date.getDate().toString().padStart(2, "0");
    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    const formattedYear = date.getFullYear();

    const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;

    return formattedDate;
  }

  const handleResetClick = (e) => {
    e.preventDefault();
    setFormData({
      start_date: "",
      end_date: "",
    });
    setData([]);
    setReportData({
      totalSubtotal: "",
      totalDiscount: "",
      totalTotal: "",
      totalPackingCharges:"",
      totalServiceCharge: "",
      totalTax: "",
      currency_symbol: "",
      start_date: "",
      end_date: "",
    });
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();

    if (formData.start_date === "") {
      addToast("error", "Start Date can not be empty");
    } else if (new Date(formData.start_date) > new Date(getToday())) {
      addToast("error", "Start Date can not be future Date");
    } else if (formData.end_date === "") {
      addToast("error", "End Date can not be empty");
    } else if (new Date(formData.end_date) > new Date(getToday())) {
      addToast("error", "End Date can not be future Date");
    } else if (new Date(formData.start_date) > new Date(formData.end_date)) {
      addToast("error", "End Date can not be previous of Start Date");
    } else {
      setIsLoading(true);
      try {
        const response = await axios.get(GetGSTReportURL, {
          params: {
            start_date: formData.start_date,
            end_date: formData.end_date,
          },
          headers: { authorization: `Bearer ${token}` },
        });

        if (response.status === 200 && response.data.data) {
          setData(response.data.data);
          setReportData({
            totalSubtotal: response.data.totalSubtotal,
            totalDiscount: response.data.totalDiscount,
            totalTotal: response.data.totalTotal,
            totalPackingCharges:response.data.totalPackingCharges,
            totalServiceCharge: response.data.totalServiceCharge,
            totalTax: response.data.totalTax,
            currency_symbol: response.data.currency_symbol,
            start_date: response.data.start_date,
            end_date: response.data.end_date,
          });
        } else if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          setData([]);
          setReportData({
            totalSubtotal: "",
            totalDiscount: "",
            totalTotal: "",
            totalServiceCharge: "",
            totalTax: "",
            currency_symbol: "",
            start_date: "",
            end_date: "",
          });
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const downloadPdfDocument = () => {
    const doc = new jsPDF();

    doc.autoTable({
      html: "#pdfContent",
      theme: "grid",
      styles: {
        font: "times",
        fontSize: 9,
        cellPadding: 1,
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
        fontStyle: "bold",
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        halign: "left",
        valign: "middle",
      },
      footStyles: {
        fillColor: [220, 220, 220],
        textColor: [0, 0, 0],
        halign: "left",
        valign: "middle",
        fontStyle: "bold",
      },
      columnStyles: {
        0: { halign: "left", cellWidth: 35 },
        1: { halign: "left", cellWidth: 20 },
        2: { halign: "left" },
        3: { halign: "left" },
        4: { halign: "left" },
        5: { halign: "left" },
        6: { halign: "left", cellWidth: 20 },
        7: { halign: "left" },
      },
      margin: { top: 10, left: 10, right: 10 },
      didParseCell: function (data) {
        if (data.row.section === "body" && data.column.dataKey === 0) {
          data.cell.styles.halign = "left";
        }
      },
    });

    doc.save(
      `GST_Report-${formatDateRange(reportData.start_date)}-${formatDateRange(
        reportData.end_date
      )}.pdf`
    );
  };

  const exportToExcel = () => {
    var table_elt = document.getElementById("pdfContent");

    var workbook = XLSX.utils.table_to_book(table_elt);

    XLSX.writeFile(
      workbook,
      `GST_Report-${formatDateRange(reportData.start_date)}-${formatDateRange(
        reportData.end_date
      )}.xlsx`
    );
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);


  const handleDetailsClick = (id) => {
     
    // localStorage.setItem("orderID", id);
    // navigate(`/order_details`);
    window.open(`/order_details?id=${id}`, '_blank');

  };

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
              <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="reports" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="d-flex justify-content-between align-items-start mb-2">
                        <div className="vsalign">
                          <h3 className="ukhd mb-3">GST Report</h3>
                        </div>
                        <div className="vsalign">
                          <span
                            className="btn btn-danger mb-0 me-0"
                            onClick={handleBackClick}
                          >
                            <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                            Go Back
                          </span>
                        </div>
                      </div>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-3 vsalign">
                                  <div className="form-group">
                                    <label>
                                      Start Date
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="start_date"
                                      max={getToday()}
                                      value={formData.start_date}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-3 vsalign">
                                  <div className="form-group">
                                    <label>
                                      End Date
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="end_date"
                                      max={getToday()}
                                      value={formData.end_date}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-6 vsalign">
                                  <button
                                    type="submit"
                                    className="btn btn-primary me-2"
                                    onClick={handleSubmitClick}
                                  >
                                    Submit
                                  </button>
                                  <button
                                    className="btn btn btn-secondary"
                                    onClick={handleResetClick}
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                              {data && data.length !== 0 && (
                                <>
                                  <div className="d-sm-flex justify-content-between align-items-start">
                                    <div className="vsalign"></div>
                                    <div className="vsalign">
                                      <button
                                        type="submit"
                                        className="btn btn-primary me-2"
                                        onClick={downloadPdfDocument}
                                      >
                                        Generate PDF
                                      </button>
                                      <button
                                        type="submit"
                                        className="btn btn-primary me-2"
                                        onClick={exportToExcel}
                                      >
                                        Export To Excel
                                      </button>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="table-responsive mt-1">
                                      <table
                                        id="pdfContent"
                                        className="table table-bordered"
                                      >
                                        <thead>
                                          <tr className="text-center">
                                            <th colSpan={9}>
                                              <h4 className="p-0 mb-0">
                                                GST Report
                                              </h4>
                                            </th>
                                          </tr>
                                          <tr className="text-center">
                                            <th colSpan={9}>
                                              Report Date :{" "}
                                              {getCurrentDateFormatted()}
                                            </th>
                                          </tr>
                                          <tr className="text-center">
                                            <th colSpan={9}>
                                              Sales date range:{" "}
                                              {reportData.start_date &&
                                                formatDate(
                                                  reportData.start_date
                                                )}{" "}
                                              -{" "}
                                              {reportData.end_date &&
                                                formatDate(reportData.end_date)}
                                            </th>
                                          </tr>
                                          <tr>
                                            <th>Order Date</th>
                                            <th>Bill No</th>
                                            <th>Status</th>
                                            <th>Subtotal</th>
                                            <th>Discount</th>
                                            <th>Service Charge</th>
                                            <th>Tax</th>
                                            <th>Packaging Charge</th>
                                            <th>Paid Amount</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <>
                                            {data.length === 0 ? (
                                              <tr>
                                                <td
                                                  colSpan="8"
                                                  className="text-center"
                                                >
                                                  No data found
                                                </td>
                                              </tr>
                                            ) : (
                                              <>
                                                {data.map((item, index) => (
                                                  <tr key={index}>
                                                    <td>
                                                      {
                                                        item.creation_date_formatted
                                                      }
                                                    </td>
                                                    <td className="kr-mouse-pointer kr-hyper-link" onClick={()=>handleDetailsClick(item.id)}>
                                                      
                                                      {item.invoice_no}
                                                    </td>
                                                    <td>
                                                      {item.order_status_lable}
                                                    </td>
                                                    <td>Rs.{parseFloat(item.subtotal).toFixed(2)}</td>
                                                    <td>
                                                      Rs.
                                                      {parseFloat(item.discount).toFixed(2)}
                                                        
                                                    </td>
                                                    <td>
                                                      Rs.
                                                      {parseFloat(item.service_charge).toFixed(2)}
                                                     
                                                    </td>
                                                    <td>
                                                      Rs.
                                                      {parseFloat(item.tax).toFixed(2)}
                                                       
                                                    </td>
                                                    <td>
                                                      Rs.
                                                      {parseFloat(item.packaging_fee).toFixed(2)}
                                                    
                                                    </td>
                                                    <td>
                                                      Rs.
                                                      {parseFloat(item.total).toFixed(2)}

                                                    </td>
                                                  </tr>
                                                ))}
                                                <tr>
                                                  <th colSpan={3}>Total</th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Subtotal)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalSubtotal}
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Discount)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalDiscount}
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Service Charge)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {
                                                      reportData.totalServiceCharge
                                                    }
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Tax)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalTax}
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                    (Packaging Charges)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalPackingCharges}
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Paid Amount)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalTotal}
                                                  </th>
                                                </tr>
                                              </>
                                            )}
                                          </>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default GSTReports;
