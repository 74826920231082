import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import {
  ScriptId,
  ScriptSRC,
  GetCouponCodeListURL,
  AddCouponCodeURL,
  UpdateCouponCodeURL,
  UpdateCouponCodeStatusURL,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const ManageCoupons = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const availableRecordsPerPageOptions = [10, 25, 50, 100];
  const [isLoading, setIsLoading] = useState(false);
  const [isAddCouponModal, setIsAddCouponModal] = useState(false);
  const [isEditCouponModal, setIsEditCouponModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalrecords, setTotalRecords] = useState(0);
  const [pageNumbersBatch, setPageNumbersBatch] = useState([]);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    coupon_code: "",
    minimum_order: "",
    start_date: "",
    expiry_date: "",
    status: true,
    type: 0,
    amount: "0",
  });
  const [formDataEdit, setFormDataEdit] = useState({
    id: "",
    coupon_code: "",
    minimum_order: "",
    start_date: "",
    expiry_date: "",
    status: true,
    type: 0,
    amount: "0",
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateStartSerialNumber = () => {
    return (currentPage - 1) * recordsPerPage + 1;
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleStatusToggle = async (e, itemId) => {
    setIsLoading(true);

    const dataU = { id: itemId, status: e.target.checked ? 1 : 0 };

    try {
      const response = await axios({
        method: "put",
        url: UpdateCouponCodeStatusURL,
        data: dataU,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setFlag(!flag);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleAddCouponClick = (e) => {
    e.preventDefault();
    setFormData({
      coupon_code: "",
      minimum_order: "",
      start_date: "",
      expiry_date: "",
      status: true,
      type: 0,
      amount: "0",
    });
    setError("");
    setSuccess("");
    setIsAddCouponModal(true);
  };

  const handleEditClick = (e, item) => {
    e.preventDefault();
    setFormDataEdit({
      id: item.id ? item.id : "",
      coupon_code: item.coupon_code ? item.coupon_code : "",
      minimum_order: item.minimum_order ? item.minimum_order : "",
      start_date: item.start_date ? item.start_date : "",
      expiry_date: item.expiry_date ? item.expiry_date : "",
      status: item.status === 1 ? true : false,
      type: item.type ? item.type : 0,
      amount: item.amount ? item.amount : "0",
    });
    setIsEditCouponModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setError("");
    setSuccess("");
  };

  const handleEditInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormDataEdit((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setError("");
    setSuccess("");
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  function getToday() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (mm < 10) mm = "0" + mm;
    if (dd < 10) dd = "0" + dd;

    return `${yyyy}-${mm}-${dd}`;
  }

  const handleSubmitCouponClick = async (e) => {
    e.preventDefault();

    if (formData.coupon_code === "" || formData.coupon_code.trim() === "") {
      setError("Coupon Code can not be empty");
      setSuccess("");
    } else if (
      formData.minimum_order === "" ||
      formData.minimum_order.trim() === ""
    ) {
      setError("Minimum Order can not be empty");
      setSuccess("");
    } else if (!isValidNumber(formData.minimum_order)) {
      setError("Please Enter valid Minimum Order");
      setSuccess("");
    } else if (formData.start_date === "") {
      setError("Start Date can not be empty");
      setSuccess("");
    } else if (formData.expiry_date === "") {
      setError("Expiry Date can not be empty");
      setSuccess("");
    } else if (new Date(formData.start_date) > new Date(formData.expiry_date)) {
      setError("Expiry Date can not be previous of Start Date");
      setSuccess("");
    } else if (formData.amount === "") {
      setError("Discount Amount can not be empty");
      setSuccess("");
    } else if (!isValidNumber(formData.amount)) {
      setError("Please Enter valid Discount Amount");
      setSuccess("");
    } else {
      const data = {
        coupon_code: formData.coupon_code,
        minimum_order: formData.minimum_order,
        start_date: formData.start_date,
        expiry_date: formData.expiry_date,
        type: formData.type,
        amount: formData.amount,
        status: formData.status ? 1 : 0,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddCouponCodeURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setFlag(!flag);
          setError("");
          setSuccess(response.data.message);
          setFormData({
            coupon_code: "",
            minimum_order: "",
            start_date: "",
            expiry_date: "",
            status: true,
            type: 0,
            amount: "0",
          });
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  const handleUpdateCouponClick = async (e) => {
    e.preventDefault();

    if (
      formDataEdit.coupon_code === "" ||
      formDataEdit.coupon_code.trim() === ""
    ) {
      setError("Coupon Code can not be empty");
      setSuccess("");
    } else if (
      formDataEdit.minimum_order === "" ||
      formDataEdit.minimum_order.trim() === ""
    ) {
      setError("Minimum Order can not be empty");
      setSuccess("");
    } else if (!isValidNumber(formDataEdit.minimum_order)) {
      setError("Please Enter valid Minimum Order");
      setSuccess("");
    } else if (formDataEdit.start_date === "") {
      setError("Start Date can not be empty");
      setSuccess("");
    } else if (formDataEdit.expiry_date === "") {
      setError("Expiry Date can not be empty");
      setSuccess("");
    } else if (
      new Date(formDataEdit.start_date) > new Date(formDataEdit.expiry_date)
    ) {
      setError("Expiry Date can not be previous of Start Date");
      setSuccess("");
    } else if (formDataEdit.amount === "") {
      setError("Discount Amount can not be empty");
      setSuccess("");
    } else if (!isValidNumber(formDataEdit.amount)) {
      setError("Please Enter valid Discount Amount");
      setSuccess("");
    } else {
      const data = {
        id: formDataEdit.id,
        coupon_code: formDataEdit.coupon_code,
        minimum_order: formDataEdit.minimum_order,
        start_date: formDataEdit.start_date,
        expiry_date: formDataEdit.expiry_date,
        type: formDataEdit.type,
        amount: formDataEdit.amount,
        status: formDataEdit.status ? 1 : 0,
      };

      try {
        const response = await axios({
          method: "put",
          url: UpdateCouponCodeURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setFlag(!flag);
          setError("");
          setSuccess(response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetCouponCodeListURL, {
            params: {
              page: currentPage,
              limit: recordsPerPage,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setTotalRecords(response.data.totalRecord);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
            setTotalRecords(0);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, currentPage, recordsPerPage, addToast]);

  useEffect(() => {
    if (totalrecords) {
      const totalPages = Math.ceil(totalrecords / recordsPerPage);
      setTotalPages(totalPages);

      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      } else if (currentPage < 1) {
        setCurrentPage(1);
      }

      const maxPageNumbers = 1;
      let startPage = Math.max(currentPage - maxPageNumbers, 1);
      let endPage = Math.min(currentPage + maxPageNumbers, totalPages);

      if (endPage - startPage + 1 <= maxPageNumbers * 2) {
        if (startPage === 1) {
          endPage = Math.min(
            endPage + (maxPageNumbers * 2 - endPage + startPage - 1),
            totalPages
          );
        } else if (endPage === totalPages) {
          startPage = Math.max(
            startPage - (maxPageNumbers * 2 - endPage + startPage - 1),
            1
          );
        }
      }

      if (currentPage === 1) {
        endPage = Math.min(3, totalPages);
      }

      if (currentPage === totalPages && totalPages >= 3) {
        startPage = Math.max(currentPage - 2, 1);
        endPage = totalPages;
      }

      const batch = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
      setPageNumbersBatch(batch);
    }
  }, [currentPage, recordsPerPage, totalrecords, totalPages]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
              <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                <img src="images/mini-kr-logo.svg" alt="logo" />
              </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="manage_coupons" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Manage Coupons</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    All Coupons
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-primary text-white mb-0 me-0"
                                    onClick={handleAddCouponClick}
                                  >
                                    <i className="menu-icon mdi mdi-ticket-percent vsalign"></i>
                                    Add Coupon
                                  </span>
                                </div>
                              </div>
                              <div className="table-responsive mt-1">
                                <div>
                                  <label>Show records per page:</label>
                                  <select
                                    value={recordsPerPage}
                                    onChange={(e) =>
                                      setRecordsPerPage(
                                        parseInt(e.target.value)
                                      )
                                    }
                                  >
                                    {availableRecordsPerPageOptions.map(
                                      (option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <table className="table select-table">
                                  <thead>
                                    <tr>
                                      <th>S.No.</th>
                                      <th>Coupon Code</th>
                                      <th>Minimum Order</th>
                                      <th>Discount</th>
                                      <th>Start Date</th>
                                      <th>Expiry Date</th>
                                      <th>Status</th>
                                      <th className="text-right pe-0">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data.length === 0 ? (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No data found
                                        </td>
                                      </tr>
                                    ) : (
                                      data.map((item, index) => (
                                        <tr key={item.id}>
                                          <td>
                                            {calculateStartSerialNumber() +
                                              index}
                                          </td>
                                          <td>
                                            <h6>{item.coupon_code}</h6>
                                          </td>
                                          <td>
                                            <p>
                                              {currencySymbol}
                                              {item.minimum_order}
                                            </p>
                                          </td>
                                          <td>
                                            {item.type === 0 && (
                                              <p>{item.amount}%</p>
                                            )}
                                            {item.type === 1 && (
                                              <p>
                                                {currencySymbol}
                                                {item.amount}
                                              </p>
                                            )}
                                          </td>
                                          <td>
                                            <p>{item.start_date}</p>
                                          </td>
                                          <td>
                                            <p>{item.expiry_date}</p>
                                          </td>
                                          <td className="togglecss">
                                            <div
                                              className={`button r ${item.status === 1
                                                  ? "active"
                                                  : ""
                                                }`}
                                              id="button-1"
                                            >
                                              <input
                                                type="checkbox"
                                                className="checkbox"
                                                checked={item.status === 1}
                                                onChange={(e) =>
                                                  handleStatusToggle(e, item.id)
                                                }
                                              />
                                              <div className="knobs"></div>
                                              <div className="layer"></div>
                                            </div>
                                          </td>
                                          <td className="text-right pe-0">
                                            <i
                                              className="fs-20 mdi mdi-content-save-edit-outline text-success"
                                              title="Edit"
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) =>
                                                handleEditClick(e, item)
                                              }
                                            ></i>
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  {data.length > 0 && (
                                    <p>
                                      Showing{" "}
                                      {(currentPage - 1) * recordsPerPage + 1}{" "}
                                      to{" "}
                                      {(currentPage - 1) * recordsPerPage +
                                        data.length}{" "}
                                      of {totalrecords} entries
                                    </p>
                                  )}
                                </div>
                                <div className="col-6">
                                  <div className="pagination">
                                    <button
                                      onClick={handlePrevPage}
                                      disabled={currentPage === 1}
                                      className={
                                        currentPage === 1 ? "disabled" : ""
                                      }
                                    >
                                      {`<<`}
                                    </button>
                                    {pageNumbersBatch.map((pageNumber) => (
                                      <button
                                        key={pageNumber}
                                        onClick={() =>
                                          handlePageClick(pageNumber)
                                        }
                                        className={
                                          pageNumber === currentPage
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        {pageNumber}
                                      </button>
                                    ))}
                                    <button
                                      onClick={handleNextPage}
                                      disabled={currentPage === totalPages}
                                      className={
                                        currentPage === totalPages
                                          ? "disabled"
                                          : ""
                                      }
                                    >
                                      {`>>`}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              isAddCouponModal ? "modal fade show d-block" : "modal fade"
            }
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Coupon
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setFormData({
                        coupon_code: "",
                        minimum_order: "",
                        start_date: "",
                        expiry_date: "",
                        status: true,
                        type: 0,
                        amount: "0",
                      });
                      setError("");
                      setSuccess("");
                      setIsAddCouponModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="card-body">
                  <form className="forms-sample">
                    {error && (
                      <div className="d-flex justify-content-between align-items-start alert alert-danger">
                        <div className="vsalign">{error}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setError("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}
                    {success && (
                      <div className="d-flex justify-content-between align-items-start alert alert-success">
                        <div className="vsalign">{success}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSuccess("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Coupon Code
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Coupon Code"
                            name="coupon_code"
                            value={formData.coupon_code}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Minimum Order
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Minimum Order"
                            name="minimum_order"
                            value={formData.minimum_order}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Start Date
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder=""
                            name="start_date"
                            value={formData.start_date}
                            onChange={handleInputChange}
                            min={getToday()}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Expiry Date
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder=""
                            name="expiry_date"
                            value={formData.expiry_date}
                            onChange={handleInputChange}
                            min={getToday()}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Discount
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group mb-3 disselect">
                            <button
                              className="btn btn-outline-secondary dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {formData.type === 0 ? "%" : `${currencySymbol}`}
                            </button>
                            <ul className="dropdown-menu">
                              <li
                                onClick={() =>
                                  setFormData((prevValues) => ({
                                    ...prevValues,
                                    type: 0,
                                  }))
                                }
                              >
                                <span className="dropdown-item">%</span>
                              </li>
                              {/* <li
                                onClick={() =>
                                  setFormData((prevValues) => ({
                                    ...prevValues,
                                    type: 1,
                                  }))
                                }
                              >
                                <span className="dropdown-item">
                                  {currencySymbol}
                                </span>
                              </li> */}
                            </ul>
                            <input
                              type="text"
                              className="form-control discnt"
                              name="amount"
                              onChange={handleInputChange}
                              value={formData.amount}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group togglecss">
                          <label>Status</label>
                          <div className="button r" id="button-1">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name="status"
                              checked={formData.status}
                              onChange={handleInputChange}
                            />
                            <div className="knobs"></div>
                            <div className="layer"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={handleSubmitCouponClick}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setFormData({
                        coupon_code: "",
                        minimum_order: "",
                        start_date: "",
                        expiry_date: "",
                        status: true,
                        type: 0,
                        amount: "0",
                      });
                      setError("");
                      setSuccess("");
                      setIsAddCouponModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              isEditCouponModal ? "modal fade show d-block" : "modal fade"
            }
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit Coupon
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setFormDataEdit({
                        id: "",
                        coupon_code: "",
                        minimum_order: "",
                        start_date: "",
                        expiry_date: "",
                        status: true,
                        type: 0,
                        amount: "0",
                      });
                      setError("");
                      setSuccess("");
                      setIsEditCouponModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="card-body">
                  <form className="forms-sample">
                    {error && (
                      <div className="d-flex justify-content-between align-items-start alert alert-danger">
                        <div className="vsalign">{error}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setError("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}
                    {success && (
                      <div className="d-flex justify-content-between align-items-start alert alert-success">
                        <div className="vsalign">{success}</div>
                        <div className="vsalign">
                          <span
                            className=".h3 text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSuccess("")}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Coupon Code
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Coupon Code"
                            name="coupon_code"
                            value={formDataEdit.coupon_code}
                            onChange={handleEditInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Minimum Order
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Minimum Order"
                            name="minimum_order"
                            value={formDataEdit.minimum_order}
                            onChange={handleEditInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Start Date
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder=""
                            name="start_date"
                            value={formDataEdit.start_date}
                            onChange={handleEditInputChange}
                            min={getToday()}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Expiry Date
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder=""
                            name="expiry_date"
                            value={formDataEdit.expiry_date}
                            onChange={handleEditInputChange}
                            min={getToday()}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>
                            Discount
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group mb-3 disselect">
                            <button
                              className="btn btn-outline-secondary dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {formDataEdit.type === 0
                                ? "%"
                                : `${currencySymbol}`}
                            </button>
                            <ul className="dropdown-menu">
                              <li
                                onClick={() =>
                                  setFormDataEdit((prevValues) => ({
                                    ...prevValues,
                                    type: 0,
                                  }))
                                }
                              >
                                <span className="dropdown-item">%</span>
                              </li>
                              {/* <li
                                onClick={() =>
                                  setFormDataEdit((prevValues) => ({
                                    ...prevValues,
                                    type: 1,
                                  }))
                                }
                              >
                                <span className="dropdown-item">
                                  {currencySymbol}
                                </span>
                              </li> */}
                            </ul>
                            <input
                              type="text"
                              className="form-control discnt"
                              name="amount"
                              onChange={handleEditInputChange}
                              value={formDataEdit.amount}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group togglecss">
                          <label>Status</label>
                          <div className="button r" id="button-1">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name="status"
                              checked={formDataEdit.status}
                              onChange={handleEditInputChange}
                            />
                            <div className="knobs"></div>
                            <div className="layer"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={handleUpdateCouponClick}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setFormDataEdit({
                        id: "",
                        coupon_code: "",
                        minimum_order: "",
                        start_date: "",
                        expiry_date: "",
                        status: true,
                        type: 0,
                        amount: "0",
                      });
                      setError("");
                      setSuccess("");
                      setIsEditCouponModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default ManageCoupons;
