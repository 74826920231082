import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import {
  AddAssignTablesForStaffURL,
  GetAssignTablesDataForStaffURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const AssignTablesToStaff = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [staffID, setStaffID] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState([]);

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/view_staff");
  };

  const handleCheckboxChange = (e, id) => {
    setData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          item.checked = e.target.checked;
        }
        return item;
      });
    });
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    const checkedIds = data
      .filter((item) => item.checked)
      .map((item) => item.id);

    const dataToSend = {
      staff_id: staffID,
      table_ids: checkedIds,
    };

    setIsLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: AddAssignTablesForStaffURL,
        data: dataToSend,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.message) {
        setFlag(!flag);
        setError("");
        setSuccess(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        setError(error.response.data.message);
        setSuccess("");
      } else {
        setError(error.message);
        setSuccess("");
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    setStaffID(localStorage.getItem("staffID"));
  }, [staffID]);

  useEffect(() => {
    if (token && staffID) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetAssignTablesDataForStaffURL, {
            params: {
              staff_id: staffID,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            const updatedData = response.data.data.map((item) => ({
              ...item,
              checked: response.data.assignTable.some(
                (assignItem) => assignItem.table_id === item.id
              ),
            }));
            setData(updatedData);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, staffID, flag, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
            
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
              <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_staff" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      {/* <h3 className="ukhd mb-3">Assign Tables</h3> */}
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    Assign Tables
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-danger mb-0 me-0"
                                    onClick={handleBackClick}
                                  >
                                    <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                    Go Back
                                  </span>
                                </div>
                              </div>
                              <form className="forms-sample">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {data.map((item) => (
                                  <div className="row mt-2" key={item.id}>
                                    <div className="col-12 col-md-6 col-lg-6">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, item.id)
                                        }
                                      />
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <span className="cstnm">
                                        {item.table_no}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                                <button
                                  type="submit"
                                  className="btn btn-primary me-2 mt-4"
                                  onClick={handleSubmitClick}
                                >
                                  Submit
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default AssignTablesToStaff;
