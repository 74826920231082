import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import LoadingModal from "../../../loader/LoadingModal";
import {
  GetAllStockListURL,
  ScriptId,
  ScriptSRC,
} from "../../../assets/api/BaseURL";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";

const Stocks = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [data, setData] = useState([]);
  //   const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const availableRecordsPerPageOptions = [10, 25, 50, 100];
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalrecords, setTotalRecords] = useState(0);
  const [pageNumbersBatch, setPageNumbersBatch] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateStartSerialNumber = () => {
    return (currentPage - 1) * recordsPerPage + 1;
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        const searchTerm = searchInput.toLowerCase();

        setIsLoading(true);
        try {
          const response = await axios.get(GetAllStockListURL, {
            params: {
              page: currentPage,
              limit: recordsPerPage,
              searchTerm: searchTerm,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setTotalRecords(response.data.totalRecord);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
            setTotalRecords(0);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, currentPage, recordsPerPage, addToast, searchInput]);

  useEffect(() => {
    if (totalrecords) {
      const totalPages = Math.ceil(totalrecords / recordsPerPage);
      setTotalPages(totalPages);

      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      } else if (currentPage < 1) {
        setCurrentPage(1);
      }

      const maxPageNumbers = 1;
      let startPage = Math.max(currentPage - maxPageNumbers, 1);
      let endPage = Math.min(currentPage + maxPageNumbers, totalPages);

      if (endPage - startPage + 1 <= maxPageNumbers * 2) {
        if (startPage === 1) {
          endPage = Math.min(
            endPage + (maxPageNumbers * 2 - endPage + startPage - 1),
            totalPages
          );
        } else if (endPage === totalPages) {
          startPage = Math.max(
            startPage - (maxPageNumbers * 2 - endPage + startPage - 1),
            1
          );
        }
      }

      if (currentPage === 1) {
        endPage = Math.min(3, totalPages);
      }

      if (currentPage === totalPages && totalPages >= 3) {
        startPage = Math.max(currentPage - 2, 1);
        endPage = totalPages;
      }

      const batch = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
      setPageNumbersBatch(batch);
    }
  }, [currentPage, recordsPerPage, totalrecords, totalPages]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="stocks" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Stocks</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    All Stocks
                                  </h4>
                                </div>
                                <div className="vsalign"></div>
                              </div>
                              <div className="table-responsive mt-1">

                                <div className="d-flex justify-content-between align-items-start">

                                  <div className="vsalign">
                                    <label>Show records per page:</label>
                                    <select
                                      value={recordsPerPage}
                                      onChange={(e) =>
                                        setRecordsPerPage(
                                          parseInt(e.target.value)
                                        )
                                      }
                                    >
                                      {availableRecordsPerPageOptions.map(
                                        (option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>


                                  <div className="vsalign mt-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search Here"
                                      value={searchInput}
                                      onChange={(e) => {

                                        setSearchInput(e.target.value)

                                      }

                                      }
                                    />
                                  </div>
                                </div>

                                <table className="table select-table">
                                  <thead>
                                    <tr>
                                      <th>S.No.</th>
                                      <th>Material Name</th>
                                      {/* <th>Minimum Stock</th> */}
                                      <th>Opening Stock</th>
                                      <th>Purchase</th>
                                      <th>Sales</th>
                                      <th>Closing Stock</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data.length === 0 ? (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No data found
                                        </td>
                                      </tr>
                                    ) : (
                                      data.map((item, index) => (
                                        <tr key={item.id}>
                                          <td>
                                            {calculateStartSerialNumber() +
                                              index}
                                          </td>
                                          <td>
                                            <h6
                                              className={
                                                parseFloat(
                                                  item.closing_stock
                                                ) <=
                                                  parseFloat(item.minimum_stock)
                                                  ? "text-danger"
                                                  : ""
                                              }
                                            >
                                              {item.raw_material_name}
                                            </h6>
                                          </td>
                                          {/* <td>
                                            <p>{item.minimum_stock}</p>
                                          </td> */}
                                          <td>
                                            <p>{item.opening_stock}</p>
                                          </td>
                                          <td>
                                            <p>{item.purchase}</p>
                                          </td>
                                          <td>
                                            <p>{item.sales}</p>
                                          </td>
                                          <td>
                                            <p>{item.closing_stock}</p>
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  {data.length > 0 && (
                                    <p>
                                      Showing{" "}
                                      {(currentPage - 1) * recordsPerPage + 1}{" "}
                                      to{" "}
                                      {(currentPage - 1) * recordsPerPage +
                                        data.length}{" "}
                                      of {totalrecords} entries
                                    </p>
                                  )}
                                </div>
                                <div className="col-6">
                                  <div className="pagination">
                                    <button
                                      onClick={handlePrevPage}
                                      disabled={currentPage === 1}
                                      className={
                                        currentPage === 1 ? "disabled" : ""
                                      }
                                    >
                                      {`<<`}
                                    </button>
                                    {pageNumbersBatch.map((pageNumber) => (
                                      <button
                                        key={pageNumber}
                                        onClick={() =>
                                          handlePageClick(pageNumber)
                                        }
                                        className={
                                          pageNumber === currentPage
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        {pageNumber}
                                      </button>
                                    ))}
                                    <button
                                      onClick={handleNextPage}
                                      disabled={currentPage === totalPages}
                                      className={
                                        currentPage === totalPages
                                          ? "disabled"
                                          : ""
                                      }
                                    >
                                      {`>>`}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default Stocks;
