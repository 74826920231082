import React from "react";
import { QRCode } from "react-qrcode-logo";
import printJS from "print-js";

const QRCodeGenerator = ({ link, qrId }) => {
  const downloadCode = () => {
    const canvas = document.getElementById(`qr-${qrId}`);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `qrcode-${qrId}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const printCode = () => {
    printJS({
      printable: qrId,
      type: "html",
      targetStyles: ["*"],
    });
  };

  return (
    <>
      <div className="d-sm-flex justify-content-start align-items-center">
        <div>
          <QRCode
            value={link}
            ecLevel="H"
            logoImage="images/KR_logo.png"
            logoOpacity={1}
            enableCORS={true}
            logoWidth={40}
            logoHeight={40}
            removeQrCodeBehindLogo={true}
            id={qrId}
          />
        </div>
        <div style={{ display: "none" }}>
          <QRCode
            value={link}
            ecLevel="H"
            logoImage="images/KR_logo.png"
            logoOpacity={1}
            enableCORS={true}
            size={980}
            removeQrCodeBehindLogo={true}
            id={`qr-${qrId}`}
          />
        </div>
        <div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => downloadCode()}
          >
            <span className="mdi mdi-download"></span>
          </button>
          &nbsp;&nbsp;
          <button
            className="btn btn-primary btn-sm"
            onClick={() => printCode()}
          >
            <span className="mdi mdi-printer"></span>
          </button>
        </div>
      </div>
    </>
  );
};

export default QRCodeGenerator;
