import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import DeleteModal from "../../deleteModal/DeleteModal";
import Notification from "../other/Notification";
import {
  AddManageTableCategoryURL,
  AddManageTableURL,
  DeleteTableCategoryURL,
  GetManageTableCategoryURL,
  UpdateTableCategoryStatusURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import { useToast } from "../../../context/ToastContext";

const AddManageTables = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [categorySuccess, setCategorySuccess] = useState("");
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [flag, setFlag] = useState(false);
  const [formData, setFormData] = useState({
    tableName: "",
    tableNumber: "",
    tableCategory: "",
    tableType: "",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/manage_tables");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleStatusToggle = async (e, itemId) => {
    setIsLoading(true);

    const dataU = { id: itemId, status: e.target.checked ? 1 : 0 };

    try {
      const response = await axios({
        method: "put",
        url: UpdateTableCategoryStatusURL,
        data: dataU,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setFlag(!flag);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    setDeleteId([id]);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteTableCategory = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: "delete",
        url: DeleteTableCategoryURL,
        data: deleteId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.message) {
        setCategoryError("");
        setCategorySuccess(response.data.message);
        setFlag(!flag);
        setShowDeleteModal(!showDeleteModal);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        setCategorySuccess("");
        setCategoryError(error.response.data.message);
      } else {
        setCategorySuccess("");
        setCategoryError(error.message);
      }
      console.log(error);
      setShowDeleteModal(!showDeleteModal);
    }
    setIsLoading(false);
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();

    if (category === "") {
      setCategoryError("Category Name can not be empty");
      setCategorySuccess("");
    } else {
      try {
        const response = await axios({
          method: "post",
          url: AddManageTableCategoryURL,
          data: { table_categoryName: category },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setCategoryError("");
          setFlag(!flag);
          setCategorySuccess(response.data.message);
          setCategory("");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setCategoryError(error.response.data.message);
          setCategorySuccess("");
        } else {
          setCategoryError(error.message);
          setCategorySuccess("");
        }
        console.log(error);
      }
    }
  };

  const handleAddTable = async (e) => {
    e.preventDefault();

    // if (formData.tableName === "") {
    //   setError("Table Name can not be empty");
    //   setSuccess("");
    // } else
    if (formData.tableNumber === "") {
      setError("Table Number can not be empty");
      setSuccess("");
    }
    // else if (formData.tableCategory === "") {
    //   setError("Please select Table Category");
    //   setSuccess("");
    // }
    else if (formData.tableType === "") {
      setError("Please select Table Type");
      setSuccess("");
    } else {
      const data = {
        table_name: formData.tableName,
        table_no: formData.tableNumber,
        type: formData.tableType,
        category_id: formData.tableCategory,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddManageTableURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFormData({
            tableName: "",
            tableNumber: "",
            tableCategory: "",
            tableType: "",
          });
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getCategoryList() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetManageTableCategoryURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setCategoryList(response.data.data);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getCategoryList();
    }
  }, [token, flag, addToast]);

  useEffect(() => {
    if (error || success || categoryError || categorySuccess) {
      window.scrollTo(0, 0);
    }
  }, [error, success, categoryError, categorySuccess]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="manage_tables" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="d-flex justify-content-between align-items-start mb-2">
                        <div className="vsalign">
                          <h3 className="ukhd mb-3">Manage Tables</h3>
                        </div>
                        <div className="vsalign">
                          <span
                            className="btn btn-danger mb-0 me-0"
                            onClick={handleBackClick}
                          >
                            <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                            Go Back
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-12 grid-margin">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <h4 className="card-title">
                                    Add Table Details
                                  </h4>
                                  <form className="forms-sample">
                                    {error && (
                                      <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                        <div className="vsalign">{error}</div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setError("")}
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {success && (
                                      <div className="d-flex justify-content-between align-items-start alert alert-success">
                                        <div className="vsalign">{success}</div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setSuccess("")}
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    <div className="row">
                                      <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                          <label>
                                            Name
                                            {/* <span className="text-danger">
                                              *
                                            </span> */}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Table Name"
                                            name="tableName"
                                            value={formData.tableName}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                          <label>
                                            Number
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Table Number"
                                            name="tableNumber"
                                            value={formData.tableNumber}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                          <label>
                                            Category
                                            {/* <span className="text-danger">
                                              *
                                            </span> */}
                                          </label>
                                          <select
                                            className="form-select"
                                            name="tableCategory"
                                            value={formData.tableCategory}
                                            onChange={handleChange}
                                          >
                                            <option value="">
                                              Select Table Category
                                            </option>
                                            {categoryList &&
                                              categoryList
                                                .filter(
                                                  (item) => item.status === 1
                                                )
                                                .map((item) => (
                                                  <option
                                                    key={item.id}
                                                    value={item.id}
                                                  >
                                                    {item.table_categoryName}
                                                  </option>
                                                ))}
                                          </select>
                                        </div>
                                      </div>

                                      <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                          <label>
                                            Type
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            className="form-select"
                                            name="tableType"
                                            value={formData.tableType}
                                            onChange={handleChange}
                                          >
                                            <option value="">
                                              Select Table Type
                                            </option>
                                            <option value="0">Table</option>
                                            <option value="1">Counter</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <button
                                      type="submit"
                                      className="btn btn-primary me-2"
                                      onClick={handleAddTable}
                                    >
                                      Submit
                                    </button>
                                    <button
                                      className="btn btn btn-secondary"
                                      onClick={handleBackClick}
                                    >
                                      Cancel
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-12 grid-margin">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <h4>Add Table Category</h4>
                                  <form className="pt-3">
                                    {categoryError && (
                                      <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                        <div className="vsalign">
                                          {categoryError}
                                        </div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setCategoryError("")}
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    {categorySuccess && (
                                      <div className="d-flex justify-content-between align-items-start alert alert-success">
                                        <div className="vsalign">
                                          {categorySuccess}
                                        </div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              setCategorySuccess("")
                                            }
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Category Name"
                                        value={category}
                                        onChange={(e) => {
                                          setCategory(e.target.value);
                                          setCategoryError("");
                                          setCategorySuccess("");
                                        }}
                                      />
                                    </div>
                                    <div className="mt-3">
                                      <button
                                        type="submit"
                                        className="btn btn-primary me-2"
                                        onClick={handleAddCategory}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </form>
                                </div>

                                <div className="card-body">
                                  <div className="table-responsive">
                                    <table className="table select-table">
                                      <thead>
                                        <tr>
                                          <th>Table Category</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {categoryList &&
                                          categoryList.length === 0 ? (
                                          <tr>
                                            <td
                                              colSpan="3"
                                              className="text-center"
                                            >
                                              No Table Category data found
                                            </td>
                                          </tr>
                                        ) : (
                                          categoryList.map((item) => (
                                            <tr key={item.id}>
                                              <td>{item.table_categoryName}</td>
                                              <td className="togglecss">
                                                <div
                                                  className={`button r ${item.status === 1
                                                      ? "active"
                                                      : ""
                                                    }`}
                                                  id="button-1"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className="checkbox"
                                                    checked={item.status === 1}
                                                    onChange={(e) =>
                                                      handleStatusToggle(
                                                        e,
                                                        item.id
                                                      )
                                                    }
                                                  />
                                                  <div className="knobs"></div>
                                                  <div className="layer"></div>
                                                </div>
                                              </td>
                                              <td>
                                                <i
                                                  className="fs-20 mdi mdi-archive text-danger"
                                                  title="Delete"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) =>
                                                    handleDelete(e, item.id)
                                                  }
                                                ></i>
                                              </td>
                                            </tr>
                                          ))
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showDeleteModal && (
            <DeleteModal
              handleDelete={handleDeleteTableCategory}
              handleModal={setShowDeleteModal}
            />
          )}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default AddManageTables;
