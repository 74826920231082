import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
// import LoadingModal from "../loader/LoadingModal";
import {
  AddSupplierURL,
  ScriptId,
  ScriptSRC,
  // GetRawMaterialListForSuppliersURL,
} from "../../../assets/api/BaseURL";
import Notification from "../../other/Notification";

const AddSupplier = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  // const [rawMaterialsOptions, setRawMaterialsOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    status: true,
    address: "",
    gstin: "",
    fssaiNumber: "",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/suppliers");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setError("");
    setSuccess("");
  };

  const handleAddSupplier = async (e) => {
    e.preventDefault();

    const mobileNumberPattern = /^\d{10}$/;
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (formData.name === "") {
      setError("Name can not be empty");
      setSuccess("");
    } else if (
      formData.phoneNumber &&
      !mobileNumberPattern.test(formData.phoneNumber)
    ) {
      setError("Please enter a valid 10-digit Mobile number.");
      setSuccess("");
    } else if (formData.email && !email_pattern.test(formData.email)) {
      setError("Please Enter valid email");
      setSuccess("");
    } else {
      const data = {
        suppliers_name: formData.name,
        suppliers_phone_number: formData.phoneNumber,
        suppliers_email: formData.email,
        suppliers_address: formData.address,
        suppliers_gstin: formData.gstin,
        suppliers_fssai_number: formData.fssaiNumber,
        status: formData.status ? 1 : 0,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddSupplierURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFormData({
            name: "",
            phoneNumber: "",
            email: "",
            status: true,
            address: "",
            gstin: "",
            fssaiNumber: "",
          });
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  // useEffect(() => {
  //   if (token) {
  //     async function getData() {
  //       setIsLoading(true);
  //       try {
  //         const response = await axios.get(GetRawMaterialListForSuppliersURL, {
  //           headers: { authorization: `Bearer ${token}` },
  //         });

  //         if (response.status === 200 && response.data.rawMaterialsList) {
  //           setRawMaterialsOptions(response.data.rawMaterialsList);
  //         }
  //       } catch (error) {
  //         if (error.response && error.response.data.message) {
  //           alert(error.response.data.message);
  //         } else {
  //           alert(error.message);
  //         }
  //         console.log(error);
  //       }
  //       setIsLoading(false);
  //     }
  //     getData();
  //   }
  // }, [token]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="suppliers" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Suppliers</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    Add Supplier
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-danger mb-0 me-0"
                                    onClick={handleBackClick}
                                  >
                                    <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                    Go Back
                                  </span>
                                </div>
                              </div>
                              <form className="forms-sample">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label>
                                        Name
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-6">
                                <div className="form-group">
                                  <label>
                                    Select Raw Material
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    name="rawMaterial"
                                    value={formData.rawMaterial}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">
                                      Select Raw Material
                                    </option>
                                    {rawMaterialsOptions.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.material_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div> */}
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label>
                                        Mobile Number
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Mobile Number"
                                        maxLength={10}
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label>
                                        Email
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label>GSTIN</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter GSTIN"
                                        name="gstin"
                                        value={formData.gstin}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label> FSSAI Number </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter FSSAI Number"
                                        name="fssaiNumber"
                                        value={formData.fssaiNumber}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label>Address</label>
                                      <textarea
                                        className="form-control"
                                        placeholder="Enter Address"
                                        rows="1"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group togglecss">
                                      <label>Status</label>
                                      <div className="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          name="status"
                                          checked={formData.status}
                                          onChange={handleInputChange}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleAddSupplier}
                                >
                                  Submit
                                </button>
                                <button
                                  className="btn btn btn-secondary"
                                  onClick={handleBackClick}
                                >
                                  Cancel
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <LoadingModal isLoading={isLoading} /> */}

          <Notification />
        </>
      )}
    </>
  );
};

export default AddSupplier;
