import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import DeleteModal from "../../deleteModal/DeleteModal";
import {
  DeleteStaffURL,
  GetStaffListURL,
  ScriptId,
  ScriptSRC,
  SearchStaffURL,
  UpdateStaffStatusURL,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const Staff = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const availableRecordsPerPageOptions = [10, 25, 50, 100];
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [totalrecords, setTotalRecords] = useState(0);
  const [pageNumbersBatch, setPageNumbersBatch] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // const calculateStartSerialNumber = () => {
  //   return (currentPage - 1) * recordsPerPage + 1;
  // };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleStatusToggle = async (e, itemId) => {
    setIsLoading(true);

    const dataU = { id: itemId, status: e.target.checked ? 1 : 0 };

    try {
      const response = await axios({
        method: "put",
        url: UpdateStaffStatusURL,
        data: dataU,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setFlag(!flag);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleAddStaffClick = (e) => {
    e.preventDefault();
    navigate("/add_staff");
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("staffID", id);
    navigate(`/edit_staff`);
  };

  const handleAssignTableClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("staffID", id);
    navigate(`/assign_tables`);
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    setDeleteId([id]);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteStaff = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteStaffURL,
        data: deleteId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.message) {
        setFlag(!flag);
        setShowDeleteModal(!showDeleteModal);
        addToast("success", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetStaffListURL, {
            params: {
              page: currentPage,
              limit: recordsPerPage,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setTotalRecords(response.data.totalRecord);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
            setTotalRecords(0);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, currentPage, recordsPerPage, addToast]);

  useEffect(() => {
    if (totalrecords) {
      const totalPages = Math.ceil(totalrecords / recordsPerPage);
      setTotalPages(totalPages);

      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      } else if (currentPage < 1) {
        setCurrentPage(1);
      }

      const maxPageNumbers = 1;
      let startPage = Math.max(currentPage - maxPageNumbers, 1);
      let endPage = Math.min(currentPage + maxPageNumbers, totalPages);

      if (endPage - startPage + 1 <= maxPageNumbers * 2) {
        if (startPage === 1) {
          endPage = Math.min(
            endPage + (maxPageNumbers * 2 - endPage + startPage - 1),
            totalPages
          );
        } else if (endPage === totalPages) {
          startPage = Math.max(
            startPage - (maxPageNumbers * 2 - endPage + startPage - 1),
            1
          );
        }
      }

      if (currentPage === 1) {
        endPage = Math.min(3, totalPages);
      }

      if (currentPage === totalPages && totalPages >= 3) {
        startPage = Math.max(currentPage - 2, 1);
        endPage = totalPages;
      }

      const batch = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
      setPageNumbersBatch(batch);
    }
  }, [currentPage, recordsPerPage, totalrecords, totalPages]);

  useEffect(() => {
    const handleSearchChange = async () => {
      const searchTerm = searchInput.toLowerCase();

      if (searchTerm.length >= 3) {
        try {
          const response = await axios.get(SearchStaffURL, {
            params: {
              name: searchTerm,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setFilteredData(response.data.data);
          } else if (response.status === 200 && response.data.message) {
            setFilteredData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      } else {
        setFilteredData(null);
      }
    };
    handleSearchChange();
  }, [token, searchInput, flag, addToast]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
              <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_staff" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Staff</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    All Staff
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-primary text-white mb-0 me-0"
                                    onClick={handleAddStaffClick}
                                  >
                                    <i className="menu-icon mdi mdi-account vsalign"></i>
                                    Add Staff
                                  </span>
                                </div>
                              </div>
                              <div className="table-responsive mt-1">
                                <div className="d-flex justify-content-between align-items-start">
                                  <div className="vsalign">
                                    {searchInput === "" && (
                                      <>
                                        <label>Show records per page:</label>
                                        <select
                                          value={recordsPerPage}
                                          onChange={(e) =>
                                            setRecordsPerPage(
                                              parseInt(e.target.value)
                                            )
                                          }
                                        >
                                          {availableRecordsPerPageOptions.map(
                                            (option) => (
                                              <option
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </>
                                    )}
                                  </div>
                                  <div className="vsalign mt-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search Here"
                                      value={searchInput}
                                      onChange={(e) =>
                                        setSearchInput(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <table className="table select-table">
                                  <thead>
                                    <tr>
                                      <th>Image</th>
                                      <th>Name</th>
                                      <th>Type</th>
                                      <th>Phone No.</th>
                                      <th style={{ width: "20%" }}>
                                        Assigned Tables
                                      </th>
                                      <th>Status</th>
                                      <th>Assign</th>
                                      <th className="text-right">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchInput.length >= 3 ? (
                                      <>
                                        {filteredData ? (
                                          <>
                                            {filteredData.length === 0 ? (
                                              <tr>
                                                <td
                                                  colSpan="8"
                                                  className="text-center"
                                                >
                                                  No data found
                                                </td>
                                              </tr>
                                            ) : (
                                              filteredData.map(
                                                (item, index) => (
                                                  <tr key={item.id}>
                                                    <td>
                                                      {/* {calculateStartSerialNumber() +
                                                        index} */}
                                                      {item.staff_image ? (
                                                        <img
                                                          src={item.staff_image}
                                                          alt="Staff Img"
                                                        />
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                    <td>
                                                      <h6>{item.name}</h6>
                                                    </td>
                                                    <td>
                                                      {item.type.name
                                                        ? item.type.name
                                                        : ""}
                                                    </td>
                                                    <td>{item.phone_number}</td>
                                                    <td>
                                                      {/* {item.assignedTables.map(
                                                        (table, index) => (
                                                          <span key={index}>
                                                            {table.table_no}
                                                            {index <
                                                            item.assignedTables
                                                              .length -
                                                              1
                                                              ? ", "
                                                              : ""}
                                                          </span>
                                                        )
                                                      )} */}
                                                      {item.assignedTables.map(
                                                        (table, index) => (
                                                          <div
                                                            key={index}
                                                            className="badge badge-opacity-primary m-1"
                                                          >
                                                            {table.table_no}
                                                          </div>
                                                        )
                                                      )}
                                                    </td>
                                                    <td className="togglecss">
                                                      <div
                                                        className={`button r ${
                                                          item.status === 1
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                        id="button-1"
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          className="checkbox"
                                                          checked={
                                                            item.status === 1
                                                          }
                                                          onChange={(e) =>
                                                            handleStatusToggle(
                                                              e,
                                                              item.id
                                                            )
                                                          }
                                                        />
                                                        <div className="knobs"></div>
                                                        <div className="layer"></div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      {item.status === 1 && (
                                                        <span
                                                          className="btn btn-primary btn-sm"
                                                          onClick={(e) =>
                                                            handleAssignTableClick(
                                                              e,
                                                              item.id
                                                            )
                                                          }
                                                        >
                                                          Assign Tables
                                                        </span>
                                                      )}
                                                    </td>

                                                    <td className="text-right pe-0">
                                                      <i
                                                        className="fs-20 mdi mdi-content-save-edit-outline text-success"
                                                        title="Edit"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) =>
                                                          handleEditClick(
                                                            e,
                                                            item.id
                                                          )
                                                        }
                                                      ></i>
                                                      &nbsp;&nbsp;
                                                      <i
                                                        className="fs-20 mdi mdi-archive text-danger"
                                                        title="Delete"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) =>
                                                          handleDelete(
                                                            e,
                                                            item.id
                                                          )
                                                        }
                                                      ></i>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <tr>
                                              <td
                                                colSpan="8"
                                                className="text-center"
                                              >
                                                Loading...
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {data.length === 0 ? (
                                          <tr>
                                            <td
                                              colSpan="8"
                                              className="text-center"
                                            >
                                              No data found
                                            </td>
                                          </tr>
                                        ) : (
                                          data.map((item, index) => (
                                            <tr key={item.id}>
                                              <td>
                                                {/* {calculateStartSerialNumber() +
                                                  index} */}
                                                {item.staff_image ? (
                                                  <img
                                                    src={item.staff_image}
                                                    alt="Staff Img"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td>
                                                <h6>{item.name}</h6>
                                              </td>
                                              <td>
                                                {item.type.name
                                                  ? item.type.name
                                                  : ""}
                                              </td>
                                              <td>{item.phone_number}</td>
                                              <td>
                                                {/* {item.assignedTables.map(
                                                  (table, index) => (
                                                    <span key={index}>
                                                      {table.table_no}
                                                      {index <
                                                      item.assignedTables
                                                        .length -
                                                        1
                                                        ? ", "
                                                        : ""}
                                                    </span>
                                                  )
                                                )} */}
                                                {item.assignedTables.map(
                                                  (table, index) => (
                                                    <div
                                                      key={index}
                                                      className="badge badge-opacity-primary m-1"
                                                    >
                                                      {table.table_no}
                                                    </div>
                                                  )
                                                )}
                                              </td>
                                              <td className="togglecss">
                                                <div
                                                  className={`button r ${
                                                    item.status === 1
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  id="button-1"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className="checkbox"
                                                    checked={item.status === 1}
                                                    onChange={(e) =>
                                                      handleStatusToggle(
                                                        e,
                                                        item.id
                                                      )
                                                    }
                                                  />
                                                  <div className="knobs"></div>
                                                  <div className="layer"></div>
                                                </div>
                                              </td>
                                              <td>
                                                {item.status === 1 && (
                                                  <span
                                                    className="btn btn-primary btn-sm"
                                                    onClick={(e) =>
                                                      handleAssignTableClick(
                                                        e,
                                                        item.id
                                                      )
                                                    }
                                                  >
                                                    Assign Tables
                                                  </span>
                                                )}
                                              </td>

                                              <td className="text-right pe-0">
                                                <i
                                                  className="fs-20 mdi mdi-content-save-edit-outline text-success"
                                                  title="Edit"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) =>
                                                    handleEditClick(e, item.id)
                                                  }
                                                ></i>
                                                &nbsp;&nbsp;
                                                <i
                                                  className="fs-20 mdi mdi-archive text-danger"
                                                  title="Delete"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) =>
                                                    handleDelete(e, item.id)
                                                  }
                                                ></i>
                                              </td>
                                            </tr>
                                          ))
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              {searchInput === "" && (
                                <div className="row">
                                  <div className="col-6">
                                    {data.length > 0 && (
                                      <p>
                                        Showing{" "}
                                        {(currentPage - 1) * recordsPerPage + 1}{" "}
                                        to{" "}
                                        {(currentPage - 1) * recordsPerPage +
                                          data.length}{" "}
                                        of {totalrecords} entries
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-6">
                                    <div className="pagination">
                                      <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className={
                                          currentPage === 1 ? "disabled" : ""
                                        }
                                      >
                                        {`<<`}
                                      </button>
                                      {pageNumbersBatch.map((pageNumber) => (
                                        <button
                                          key={pageNumber}
                                          onClick={() =>
                                            handlePageClick(pageNumber)
                                          }
                                          className={
                                            pageNumber === currentPage
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          {pageNumber}
                                        </button>
                                      ))}
                                      <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        className={
                                          currentPage === totalPages
                                            ? "disabled"
                                            : ""
                                        }
                                      >
                                        {`>>`}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showDeleteModal && (
            <DeleteModal
              handleDelete={handleDeleteStaff}
              handleModal={setShowDeleteModal}
            />
          )}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default Staff;
