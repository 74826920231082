import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingModal from "../../loader/LoadingModal";
import "../../styles/posstyle.css";
import {
  AddProceedOrderURL,
  GetEaterDetailsURL,
  GetPOSDetailsURL,
  SettleDineOrderForCashPaymentURL,
  SettleDeliveryAndPickUpOrderForCashPaymentURL,
  GenerateKOTURL,
  SettleDeliveryAndPickUpOrderForUnpaidPaymentURL,
  DeleteKOTGeneratedItemURL,
  UpdateSaveOrderItemsURL,
  GetKOTDetailsListURL,
  UpdateKOTStatusURL,
  IncreaseKOTGeneratedItemQuantityURL,
  DecreaseKOTGeneratedItemQuantityURL,
  ScriptId,
  ScriptSRC,
  partialPaymentForDineIn,
  partialPaymentForDelivaryAndTakeAway
} from "../../assets/api/BaseURL";
import clickSound from "../../sound/button_click.wav";
import useSound from "use-sound";
import { useToast } from "../../../context/ToastContext";
import Select from "react-select";
import printJS from "print-js";
import Notification from "../other/Notification";

const POS = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const latestItemRef = useRef(null);
  const [playSound] = useSound(clickSound, {
    volume: 0.9,
    playbackRate: 1.5,
  });
  const [token, setToken] = useState("");
  const [planDetails, setPlanDetails] = useState(null);
  const [logoutModel, setLogoutModel] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState("");
  const [data, setData] = useState([]);
  const [menus, setMenus] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentMenu, setCurrentMenu] = useState("all");
  const [currentMenuProducts, setCurrentMenuProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orderList, setOrderList] = useState([]);


  // const [invoiceNumber, setInvoiceNumber] = useState('');


  const [isModal, setIsModal] = useState(false);
  const [isSettleModal, setIsSettleModal] = useState(false);
  const [isKOTItemDeleteModal, setIsKOTItemDeleteModal] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [viewKOT, setViewKOT] = useState(true);
  const [dataKOTDetails, setDataKOTDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrintBill, setIsPrintBill] = useState(false);
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedTableDetails, setSelectedTableDetails] = useState(null);
  const [tableDetailsByCategory, setTableDetailsByCategory] = useState([]);
  const [flag, setFlag] = useState(false);
  const [fooderId, setFooderId] = useState("");
  const [date, setDate] = useState("");
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const [orderType, setOrderType] = useState("DINE IN");
  const [noOfPeople, setNoOfPeople] = useState("1");
  const [activeTab, setActiveTab] = useState("tables");
  const [waiterList, setWaiterList] = useState([]);
  const [newFilteredOrders, setNewFilteredOrders] = useState([]);
  const [selectedWaiter, setSelectedWaiter] = useState(null);
  const [productNote, setProductNote] = useState("");
  const [deleteReason, setDeleteReason] = useState("");
  const [unpaidReason, setUnpaidReason] = useState("");
  const [deleteKOTItem, setDeleteKOTItem] = useState(null);
  const [customerSuggestion, setCustomerSuggestion] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const maxCharacterLimit = 200;
  const [customerForm, setCustomerForm] = useState({
    name: "",
    address: "",
    mobile: "",
  });
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("Cash");
  const [discountValues, setDiscountValues] = useState({
    type: "0",
    rate: "0",
  });

  const [printKOTDetails, setPrintKOTDetails] = useState({
    KOT_no: "",
    KOT_items: [],
  });

  const [printKOTDetails2, setPrintKOTDetails2] = useState(null);
  const [printBillDetails, setPrintBillDetails] = useState(null);

  const [subTotal, setSubTotal] = useState(0);
  const [SCHRate, setSCHRate] = useState(0);
  const [discountType, setDiscountType] = useState("0");
  const [discountRate, setDiscountRate] = useState("0");
  const [discountAmount, setDiscountAmount] = useState();
  const [CGSTRate, setCGSTRate] = useState(0);
  const [SGSTRate, setSGSTRate] = useState(0);
  const [schAmount, setSchAmount] = useState();
  const [cgstAmount, setCGSTAmount] = useState();
  const [sgstAmount, setSGSTAmount] = useState();
  const [totalAmountPaid, setTotalAmountPaid] = useState();

  const [cashPaymentDetails, setCashPaymentDetails] = useState({
    customerPaid: "",
    returnToCustomer: "0",
    tip: "0",
    amount: "",
    transaction_id: "",
    due: "0"
  });

  const [cardPaymentDetails, setCardPaymentDetails] = useState({
    customerPaid: "",
    tip: "0",
    note: "",
    amount: "",
    transaction_id: ""
  });

  const [neftPaymentDetails, setNeftPaymentDetails] = useState({
    customerPaid: "",
    tip: "0",
    note: "",
    amount: "",
    transaction_id: ""
  });

  const [upiPaymentDetails, setUpiPaymentDetails] = useState({
    method: "",
    other_method: "",
    tip: "0",
    note: "",
    amount: "",
    transaction_id: ""
  });

  const [paymentSettleTransactionId, setPaymentSettleTransactionId] = useState("")

  const filteredOrders = (orderList[selectedTable]?.orders || []).filter(
    (order) => order.fooder_id === fooderId
  );

  // const pickupOrders = Object.keys(orderList)
  //   .filter((key) => key.startsWith("pk-"))
  //   .map((key) => ({ id: key }));

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchInput(searchTerm);

    const filtered = products.filter(
      (product) =>
        product.name.toLowerCase().includes(searchTerm) &&
        ((product.dine_in_service && orderType === "DINE IN") ||
          (product.delivery_service && orderType === "DELIVERY") ||
          (product.pick_up_service && orderType === "TAKE AWAY"))
    );
    setFilteredProducts(filtered);
  };

  const handleLogout = (tableClear) => {
    // e.preventDefault();


    localStorage.removeItem("token");
    localStorage.removeItem("planDetails");
    localStorage.removeItem("userId");
    localStorage.removeItem("currencySymbol");
    if (tableClear) {
      localStorage.clear()
    }
    const existingScript = document.getElementById(ScriptId);
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
    // navigate("/");
    window.location.href = "/";
  };

  function isValidPrice(price) {
    const pricePattern = /^\d+(\.\d{1,2})?$/;

    if (!pricePattern.test(price)) {
      return parseFloat(price).toFixed(2);
    }
    return price;
  }

  const formatTimestampForDate = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const formatTimestampForTime = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    // const day = dateObject.getDate();
    // const month = dateObject.toLocaleString("default", { month: "short" });
    // const year = dateObject.getFullYear();
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    if (hours > 12) {
      hours -= 12;
    }

    return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  const handlePrintIconClick = (table) => {
    setSelectedTableDetails(table);
    const tableData = orderList[table.id];

    const tableOrderList = (orderList[table.id]?.orders || []).filter(
      (order) => order.fooder_id === fooderId
    );

    const amount = tableOrderList.reduce(
      (total, item) => total + item.newPrice * item.quantity,
      0
    );

    let discountedAmount = 0;
    let discount = 0;

    if (tableData.discountValues.type === "0") {
      discount = (amount * (tableData.discountValues.rate || 0)) / 100;
      discountedAmount = amount - discount;
    } else if (tableData.discountValues.type === "1") {
      discount = tableData.discountValues.rate;
      discountedAmount = amount - discount;
    } else {
      discountedAmount = amount;
    }

    const sch = (discountedAmount * (SCHRate || 0)) / 100;
    const taxableAmout = discountedAmount + sch;

    const cgst = (taxableAmout * (CGSTRate || 0)) / 100;
    const sgst = (taxableAmout * (SGSTRate || 0)) / 100;

    const totalPaid = taxableAmout + cgst + sgst;

    setSchAmount(sch.toFixed(2));
    setCGSTAmount(cgst.toFixed(2));
    setSGSTAmount(sgst.toFixed(2));
    setDiscountAmount(parseFloat(discount).toFixed(2));
    setTotalAmountPaid(totalPaid.toFixed(2));

    const result = {
      subTotal: amount.toFixed(2),
      discountRate: tableData.discountValues.rate,
      discountType: tableData.discountValues.type,
      discountAmount: parseFloat(discount).toFixed(2),
      cgstAmount: cgst.toFixed(2),
      schAmount: sch.toFixed(2),
      sgstAmount: sgst.toFixed(2),
      totalAmountPaid: totalPaid.toFixed(2),
      date: formatTimestampForDate(tableData.creation_date),
      time: formatTimestampForTime(tableData.creation_date),
    };



    let subTotal = 0;
    let tempDiscount = 0;
    let tempDiscountRow = 0;

    let tempServicCharge = 0;
    let tempServicChargeRow = 0;

    let tempTax = 0;
    let tempTaxRow = 0;

    let GSTTaxT1 = 0;
    let GSTTaxT2 = 0;
    let GSTTaxT3 = 0;
    let GSTTaxT4 = 0;
    let GSTTaxT5 = 0;



    let tempCartLen = 0;
    let billDiscountRate = tableData.discountValues.rate || 0;

    let packingCharges = 0;

    setDiscountRate(billDiscountRate)
    //  for flate amount discount

    let withOutTaxPriceForAmount = 0
    let subTotalForAmount = 0;
    let discountRateForAmount = 0;



    if (tableData.discountValues.type === "1") {
      tableOrderList.forEach((i) => {
        subTotalForAmount += (i.quantity) * i.withOutTaxPrice
        // alert(billDiscountRate)
      })

      discountRateForAmount = (parseFloat(tableData.discountValues.rate || 0) * 100) / subTotalForAmount

    }

    // end


    tableOrderList.forEach((i) => {

      packingCharges += i.quantity * parseInt(i.packaging_charges)
      tempCartLen += parseInt(i.quantity)

      tempDiscountRow = 0;


      subTotal += (i.quantity) * i.withOutTaxPrice

      if (tableData.discountValues.type === "0") {
        tempDiscount += (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
        tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
        setDiscountType('0')

      } else {
        tempDiscount += (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100
        tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100
        setDiscountType('1')
        // alert(tempDiscount)

      }





      tempServicCharge += ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100
      tempServicChargeRow = ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100



      tempTax += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
      tempTaxRow = ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100


      if (i.tax_percent === 0) {

        GSTTaxT1 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
        setCGSTTaxt1(GSTTaxT1 / 2)
        setSGSTTaxt1(GSTTaxT1 / 2)

      }

      if (i.tax_percent === 5) {

        GSTTaxT2 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

        setCGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
        setSGSTTaxt2((GSTTaxT2 / 2).toFixed(2))

      }

      if (i.tax_percent === 12) {

        GSTTaxT3 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

        setCGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
        setSGSTTaxt3((GSTTaxT3 / 2).toFixed(2))

      }

      if (i.tax_percent === 18) {

        GSTTaxT4 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
        setCGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
        setSGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
      }

      if (i.tax_percent === 28) {

        GSTTaxT5 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
        setCGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
        setSGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
      }

    })

    setCartProductSubTotal(subTotal.toFixed(2));



    setCartProductServiceCharge(tempServicCharge.toFixed(2))
    setCartProductDiscount(tempDiscount.toFixed(2))
    setCartProductTax(tempTax.toFixed(2))
    setCartProductTotal((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2));
    setCartDataLen(tempCartLen)

    setTotalAmountPaid((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))
    setCartProductTotal((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))







    // alert((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))





    setPrintBillDetails(result);

    setIsPrintBill(true);

    // dssdas
  };

  const handleMenuClick = (menuId) => {
    playSound();
    setCurrentMenu(menuId);
    if (menuId === "all") {
      setCurrentMenuProducts(products);
    } else {
      const menuProducts = products.filter(
        (product) => product.menu_id === menuId
      );
      setCurrentMenuProducts(menuProducts);
    }
    setTableView(false);
  };


  // work on variants

  const [selectedPrductForAddedVariants, setSelectedPrductForAddedVariants] = useState({});


  const [productVariantAddonsData, setProductVariantAddonsData] = useState([]);
  const [productVariantAttributesData, setProductVariantAttributesData] = useState([]);
  const [productVariantVariantData, setProductVariantVariantData] = useState([]);


  const [selectedProductVariantAttributesData, setSelectedProductVariantAttributesData] = useState([]);
  const [selectedProductVariant, setSelectedProductVariant] = useState({ 'variantId': "0" });
  const [productVariantPropertiesPriceArray, setProductVariantPropertiesPriceArray] = useState([]);
  const [selectedProductVariantAddonsData, setSelectedProductVariantAddonsData] = useState([]);
  const [selectedProductVariantAddonsIdArray, setSelectedProductVariantAddonsIdArray] = useState([]);

  const [selectedProductVariantAddonsPrice, setSelectedProductVariantAddonsPrice] = useState(0);




  useEffect(() => {

    // console.log(selectedProductVariantAddonsPrice)
    let tempSelectedAddonsPrice = 0
    selectedProductVariantAddonsData.forEach((i) => {
      tempSelectedAddonsPrice += parseFloat(i.addon_item_price)
    })
    setSelectedProductVariantAddonsPrice(tempSelectedAddonsPrice)
  }, [selectedProductVariantAddonsData]);

  const changeSelectedVariants = (index, value) => {
    // console.log(value, index)
    playSound();

    const tempSelectedProperties = [...selectedProductVariantAttributesData];

    // console.log("tempSelectedProperties")
    tempSelectedProperties[index] = value
    // console.log(tempSelectedProperties)
    setSelectedProductVariantAttributesData(tempSelectedProperties)
    setSelectedProductVariantAddonsData([])
    setSelectedProductVariantAddonsIdArray([])
    setSelectedProductVariantAddonsPrice(0)

  }


  const changeSelectedAddons = (checked, value, min, max, addonId) => {
    // console.log(value, checked)
    playSound();
    value.addonId = addonId
    value.minimum_item = min ? parseInt(min) : 0
    value.maximum_item = parseInt(max)





    let tempSelectedAddons = [...selectedProductVariantAddonsData]
    let tempSelectedAddonsIdArray = [...selectedProductVariantAddonsIdArray]

    // console.log(tempSelectedAddonsIdArray)
    let tempSelectedAddonsLen = 0

    tempSelectedAddons.forEach((i) => {
      if (i.addonId === addonId) {
        tempSelectedAddonsLen++
      }
    })


    if (max <= tempSelectedAddonsLen && checked) {
      // alert("hahaha...")
      addToast("error", "Max addons selected");
    } else {
      if (checked) {
        tempSelectedAddons.push(value)
        tempSelectedAddonsIdArray.push(value.addonItemId)

        setSelectedProductVariantAddonsData(tempSelectedAddons)
        setSelectedProductVariantAddonsIdArray(tempSelectedAddonsIdArray)

      }
      else {

        // console.log("remove value")
        let temp = []
        let tempIdArray = []

        tempSelectedAddons.forEach((i) => {
          // console.log(i)
          if (i.addonItemId !== value.addonItemId) {
            temp.push(i)
            tempIdArray.push(i.addonItemId)
          }

        })
        setSelectedProductVariantAddonsData(temp)
        setSelectedProductVariantAddonsIdArray(tempIdArray)




      }
    }




  }

  useEffect(() => {

    if (selectedProductVariantAttributesData.length > 0) {

      productVariantVariantData.forEach((i) => {

        let counter = 0

        i.combination_details.forEach((j, jCount) => {
          if (j.attribute_value_id == selectedProductVariantAttributesData[jCount]) {
            counter++;
          }

        })
        if (counter == selectedProductVariantAttributesData.length) {
          // console.log("variant finded")
          // console.log(i)
          setSelectedProductVariant(i)

        }

      })

      let tempPropertiesPriceArray = []


      productVariantVariantData.forEach((i) => {

        if (i.combination_details[0].attribute_value_id == selectedProductVariantAttributesData[0]) {
          tempPropertiesPriceArray.push(i.combination_price)
          // console.log(i.combination_price)
        }
      })
      setProductVariantPropertiesPriceArray(tempPropertiesPriceArray)



      if (productVariantVariantData.length === productVariantAttributesData[0].value.length) {
        tempPropertiesPriceArray = []
        productVariantVariantData.forEach((i) => {

          tempPropertiesPriceArray.push(i.combination_price)

        })
        setProductVariantPropertiesPriceArray(tempPropertiesPriceArray)
        // console.log("tempPropertiesPriceArray")
        // console.log(tempPropertiesPriceArray)
      }
    }


  }, [selectedProductVariantAttributesData]);





  // useEffect(() => {

  //   if (productVariantAddonsData.length <= 0) {

  //     console.log()
  //   }


  // }, [productVariantVariantData]);




  const handleProductClick = (product) => {

    playSound();
    if (selectedTable) {

      setSelectedProduct(product);
      setProductNote("");
      setIsModal(true);

      setSelectedPrductForAddedVariants(product)
      // console.log('handle Product click')
      // console.log(product)

      // setProductVariantAddonsData(product.addons)
      // setProductVariantAttributesData(product.attributes)

      setSelectedProductVariantAddonsData([])
      setSelectedProductVariantAddonsIdArray([])
      setSelectedProductVariant({ 'variantId': "0" })
      if (product.addons) {
        setProductVariantAddonsData(product.addons)
        // alert(product.addons)
        // console.log(product.addons)
      } else {
        setProductVariantAddonsData([])
      }

      if (product.variants) {
        setProductVariantVariantData(product.variants)
      } else {
        setProductVariantVariantData([])
      }



      if (product.attributes) {
        let tempAttributeArray = []
        let tempArrayUnick = []

        product.attributes.forEach((i) => {
          let count = 0;

          tempArrayUnick.forEach((j) => {
            if (i.attributeId === j.attributeId) {
              count++;
            }
          })
          if (count == 0) {
            tempArrayUnick.push({
              'attributeId': i.attributeId,
              'attributeName': i.attributeName,
            });
          }
        });

        tempArrayUnick.forEach((i) => {
          let temp = []
          product.attributes.forEach((j) => {
            if (i.attributeId === j.attributeId) {
              temp.push(j);
            }
          });

          tempAttributeArray.push({
            'attributeId': i.attributeId,
            'attributeName': i.attributeName,
            'value': temp,
          });
        });

        setProductVariantAttributesData(tempAttributeArray)
        // console.log("tempAttributeArray")
        // console.log(tempAttributeArray)
        let tempSelectedProperties = []
        tempAttributeArray.forEach((i) => {
          tempSelectedProperties.push(i.value[0].attributeValueId);
        })

        // console.log(tempSelectedProperties)
        setSelectedProductVariantAttributesData(tempSelectedProperties)
      } else {
        setProductVariantAttributesData([])
      }
      // setShowVariants(true)
    } else {
      addToast("error", "Please select a table first!");
      setSelectedTableDetails(null);
      setSelectedTable("");
      setTableView(true);
    }
  };

  const handleTableView = () => {
    playSound();
    // if (orderType === "DELIVERY") {
    //   clearTableData("dl");
    //   setFlag(!flag);
    // } else if (orderType === "TAKE AWAY") {
    //   clearTableData("pk");
    //   setFlag(!flag);
    // } else {
    //   setSelectedTableDetails(null);
    //   setSelectedTable("");
    //   setActiveTab("tables");
    //   setTableView(true);
    // }
    setSelectedTableDetails(null);
    setSelectedTable("");
    setActiveTab("tables");
    setTableView(true);
    setViewKOT(false);
  };

  const handleOrderTypeChange = (newOrderType) => {
    if (newOrderType === "DELIVERY") {
      setOrderType(newOrderType);
      setSelectedTable("dl");
      setTableView(false);
    } else if (newOrderType === "TAKE AWAY") {
      setOrderType(newOrderType);
      setSelectedTable("pk");
      setTableView(false);
    } else {
      if (selectedTableDetails) {
        setOrderType(newOrderType);
        setSelectedTable(selectedTableDetails ? selectedTableDetails.id : "");
      } else {
        setOrderType(newOrderType);
        setSelectedTable(selectedTableDetails ? selectedTableDetails.id : "");
        setTableView(true);
      }
    }
    setActiveTab("tables");
    setSelectedPaymentOption("Cash");
    setCustomerForm({
      name: "",
      address: "",
      mobile: "",
    });
    setNoOfPeople("1");
    setCustomerSuggestion("");
    setSelectedWaiter(null);
    setViewKOT(false);
  };

  const capitalizeFirstLetter = (str) => {
    if (str) {
      const words = str.split(" ");

      const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
          return "";
        }

        else
          if (word[0] === '(' || word[0] === '-') {
            return word.charAt(0).toUpperCase() + word.charAt(1).toUpperCase() + word.slice(2).toLowerCase();
          }
          else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
      });

      const capitalizedString = capitalizedWords.join(" ");

      return capitalizedString;
    }
  };

  const updateQuantity = (increment) => {
    playSound();
    if (selectedProduct) {
      const updatedProduct = { ...selectedProduct };
      if (increment) {
        updatedProduct.quantity += 1;
      } else if (updatedProduct.quantity > 1) {
        updatedProduct.quantity -= 1;
      }
      setSelectedProduct(updatedProduct);
    }
  };

  const handleAddToOrder = () => {
    playSound();

    // console.log("**** add items to orders ****")
    // console.log(selectedProductVariantAddonsData)
    // console.log(selectedProductVariant)

    let checkMinAddon = false


    productVariantAddonsData.forEach((i) => {
      let tempMin = 0
      selectedProductVariantAddonsData.forEach((j) => {
        if (i.addonId == j.addonId) {
          tempMin++
        }

      })
      // console.log(i)
      // alert(tempMin)
      // alert(i.minimum_item)

      if (i.minimum_item > tempMin) {
        // alert("hahaha...")
        checkMinAddon = true
      }

    })
    if (checkMinAddon) {
      addToast("error", "Please add more addons");

    } else {
      if (selectedTable) {
        //  if (orderList[selectedTable].orderId) {
        //    addToast("error", "Order already saved !");
        //  } else
        if (selectedProduct) {
          const orderItem = {
            ...selectedProduct,
            local_id: new Date().getTime(),
            newPrice: selectedProduct.proprice
              ? selectedProduct.proprice
              : selectedProduct.price,
            fooder_id: fooderId,
            table_id: selectedTable,
            fooder_name: data.fooder_name,
            product_special_note: productNote,
            isKOT: false,
            isSaved: false,
            KOT_id: "",
            KOT_no: "",
            KOT_time: "",
            selectedAddons: selectedProductVariantAddonsData,
            selectedvariants: selectedProductVariant,


            newPrice: (parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)) ?
              `${(parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)).toFixed(2)}` :
              `${selectedPrductForAddedVariants.proprice ? (parseFloat(selectedPrductForAddedVariants.proprice) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2) : (parseFloat(selectedPrductForAddedVariants.price) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2)}`,

            price: (parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)) ?
              `${(parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)).toFixed(2)}` :
              `${selectedPrductForAddedVariants.proprice ? (parseFloat(selectedPrductForAddedVariants.proprice) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2) : (parseFloat(selectedPrductForAddedVariants.price) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2)}`,


            proprice: ""

          };

          // console.log("8*********************************")
          // console.log((parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)) ?
          // `${(parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)).toFixed(2)}` :
          // `${selectedPrductForAddedVariants.proprice ? selectedPrductForAddedVariants.proprice : selectedPrductForAddedVariants.price}`)

          const existingOrderTable = selectedTable;
          const existingOrderList =
            JSON.parse(localStorage.getItem("orderList")) || {};

          if (!existingOrderList[existingOrderTable]) {
            existingOrderList[existingOrderTable] = {
              isPrinted: false,
              orderId: "",
              orderNo: "",
              creation_date: "",
              orders: [],
              discountValues: {
                type: "0",
                rate: "0",
              },
              customerDetails: {
                name: "",
                address: "",
                mobile: "",
              },
              totalPersons: "1",
              instructions: "",
              staff: null,
            };
          }

          // const itemIndex = existingOrderList[
          //   existingOrderTable
          // ].orders.findIndex((item) => item.id === selectedProduct.id);

          // if (itemIndex !== -1) {
          //   existingOrderList[existingOrderTable].orders[itemIndex] = orderItem;
          // } else {
          //   existingOrderList[existingOrderTable].orders.push(orderItem);
          // }

          // if (itemIndex !== -1) {
          //   existingOrderList[existingOrderTable].orders.splice(itemIndex, 1);
          // }

          existingOrderList[existingOrderTable].orders.push(orderItem);

          localStorage.setItem("orderList", JSON.stringify(existingOrderList));

          setSelectedProduct(null);
          setProductNote("");
          setFlag(!flag);
          setIsModal(false);
          setActiveTab("tables");
        }
      } else {
        addToast("error", "Please select a table first!");
      }
    }


  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const updateCustomerDetails = (obj) => {
    const updatedOrderList = { ...orderList };
    if (
      updatedOrderList[selectedTable] &&
      updatedOrderList[selectedTable].customerDetails
    ) {
      updatedOrderList[selectedTable].customerDetails = obj;
    }
    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const updateTotalPersons = (value) => {
    const updatedOrderList = { ...orderList };
    if (updatedOrderList[selectedTable]) {
      updatedOrderList[selectedTable].totalPersons = value;
    }
    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const handleCustomerMobileChange = async (e) => {
    const { name, value } = e.target;
    const mobileNumberPattern = /^\d{10}$/;

    if (name === "mobile" && value.length === 10) {
      setCustomerForm({
        ...customerForm,
        [name]: value,
      });

      updateCustomerDetails({
        ...customerForm,
        [name]: value,
      });

      if (!mobileNumberPattern.test(value)) {
        addToast("error", "Please Enter valid Mobile Number");
      } else {
        try {
          const response = await axios.get(GetEaterDetailsURL, {
            params: {
              eater_phonenumber: value,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (
            response.status === 200 &&
            response.data.data &&
            response.data.message
          ) {
            addToast("success", response.data.message);
            // setCustomerForm(response.data.data);
            // updateCustomerDetails(response.data.data);
            setCustomerForm({
              name: response.data.data.name
                ? response.data.data.name
                : customerForm.name,
              address: response.data.data.address
                ? response.data.data.address
                : customerForm.address,
              mobile: response.data.data.mobile
                ? response.data.data.mobile
                : customerForm.mobile,
            });
            updateCustomerDetails({
              name: response.data.data.name
                ? response.data.data.name
                : customerForm.name,
              address: response.data.data.address
                ? response.data.data.address
                : customerForm.address,
              mobile: response.data.data.mobile
                ? response.data.data.mobile
                : customerForm.mobile,
            });
          } else if (
            response.status === 200 &&
            !response.data.data &&
            response.data.message
          ) {
            addToast("success", response.data.message);
            setCustomerForm({
              ...customerForm,
              mobile: value,
            });
            updateCustomerDetails({
              ...customerForm,
              mobile: value,
            });
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      }
    } else {
      setCustomerForm({
        ...customerForm,
        [name]: value,
      });

      updateCustomerDetails({
        ...customerForm,
        [name]: value,
      });
    }
  };

  const handleCustomerFormChange = (e) => {
    const { name, value } = e.target;

    setCustomerForm({
      ...customerForm,
      [name]: value,
    });

    updateCustomerDetails({
      ...customerForm,
      [name]: value,
    });
  };

  const handleCashPaymentDetailsChange = (e) => {
    const { name, value } = e.target;

    if (name === "customerPaid") {
      const customerPaid = parseFloat(value) || 0;
      const amount = parseFloat(cashPaymentDetails.amount) || 0;


      const returnToCustomer = Math.abs(customerPaid - amount);



      if (!isNaN(returnToCustomer)) {
        setCashPaymentDetails({
          ...cashPaymentDetails,
          [name]: value,
          returnToCustomer: returnToCustomer.toFixed(2),
        });
      } else {
        setCashPaymentDetails({
          ...cashPaymentDetails,
          [name]: value,
        });
      }
    } else {
      setCashPaymentDetails({
        ...cashPaymentDetails,
        [name]: value,
      });
    }
  };

  const handleCardPaymentDetailsChange = (e) => {
    const { name, value } = e.target;

    setCardPaymentDetails({
      ...cardPaymentDetails,
      [name]: value,
    });
  };

  const handleNeftPaymentDetailsChange = (e) => {
    const { name, value } = e.target;

    setNeftPaymentDetails({
      ...neftPaymentDetails,
      [name]: value,
    });
  };

  const handleUPIPaymentDetailsChange = (e) => {
    const { name, value } = e.target;

    if (name === "method") {
      setUpiPaymentDetails({
        ...upiPaymentDetails,
        [name]: value,
        other_method: "",
      });
    } else {
      setUpiPaymentDetails({
        ...upiPaymentDetails,
        [name]: value,
      });
    }
  };

  // const calculateReturnToCustomer = () => {
  //   const customerPaid = parseFloat(cashPaymentDetails.customerPaid) || 0;
  //   const amount = parseFloat(cashPaymentDetails.amount) || 0;
  //   // const tip = parseFloat(cashPaymentDetails.tip) || 0;

  //   // const returnToCustomer = customerPaid - amount - tip;
  //   const returnToCustomer = Math.abs(customerPaid - amount);

  //   if (!isNaN(returnToCustomer)) {
  //     setCashPaymentDetails({
  //       ...cashPaymentDetails,
  //       returnToCustomer: returnToCustomer.toFixed(2),
  //     });
  //   }
  // };

  const calculateFinalAmountForCash = () => {
    const amount = parseFloat(totalAmountPaid) || 0;
    const tip = parseFloat(cashPaymentDetails.tip) || 0;

    const finalAmount = Math.abs(amount + tip);

    if (!isNaN(finalAmount)) {
      setCashPaymentDetails({
        ...cashPaymentDetails,
        amount: finalAmount.toFixed(2),
        customerPaid: finalAmount.toFixed(2),
        returnToCustomer: 0,
      });
    }
  };

  const calculateFinalAmountForCard = () => {
    const amount = parseFloat(totalAmountPaid) || 0;
    const tip = parseFloat(cardPaymentDetails.tip) || 0;

    const finalAmount = Math.abs(amount + tip);

    if (!isNaN(finalAmount)) {
      setCardPaymentDetails({
        ...cardPaymentDetails,
        amount: finalAmount.toFixed(2),
        customerPaid: finalAmount.toFixed(2),
      });
    }
  };


  const calculateFinalAmountForNeft = () => {
    const amount = parseFloat(totalAmountPaid) || 0;
    const tip = parseFloat(neftPaymentDetails.tip) || 0;

    const finalAmount = Math.abs(amount + tip);

    if (!isNaN(finalAmount)) {
      setNeftPaymentDetails({
        ...neftPaymentDetails,
        amount: finalAmount.toFixed(2),
        customerPaid: finalAmount.toFixed(2),
      });
    }
  };

  const calculateFinalAmountForUPI = () => {
    const amount = parseFloat(totalAmountPaid) || 0;
    const tip = parseFloat(upiPaymentDetails.tip) || 0;

    const finalAmount = Math.abs(amount + tip);

    if (!isNaN(finalAmount)) {
      setUpiPaymentDetails({
        ...upiPaymentDetails,
        amount: finalAmount.toFixed(2),
        customerPaid: finalAmount.toFixed(2),
      });
    }
  };

  const updateQuantityInList = (itemId, id, newQuantity, table) => {
    const updatedOrderList = { ...orderList };

    if (updatedOrderList[table] && updatedOrderList[table].orders) {
      updatedOrderList[table].orders = updatedOrderList[table].orders.map(
        (order) => {
          if (
            order.id === itemId &&
            order.fooder_id === fooderId &&
            order.local_id === id
          ) {
            return { ...order, quantity: newQuantity };
          }
          return order;
        }
      );
    }

    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const handlePlusClick = (itemId, id, table) => {
    playSound();
    const orderItem = (orderList[table]?.orders || []).find(
      (order) =>
        order.id === itemId &&
        order.fooder_id === fooderId &&
        order.local_id === id
    );

    if (orderItem) {
      const newQuantity = orderItem.quantity + 1;
      updateQuantityInList(itemId, id, newQuantity, table);
    }
  };

  const handlePlusClickAfterKOT = async (itemId, id, table) => {
    playSound();
    const orderItem = (orderList[table]?.orders || []).find(
      (order) =>
        order.id === itemId &&
        order.fooder_id === fooderId &&
        order.local_id === id
    );

    if (orderItem) {
      const newQuantity = orderItem.quantity + 1;

      setIsLoading(true);
      try {
        const response = await axios({
          method: "put",
          url: IncreaseKOTGeneratedItemQuantityURL,
          data: { id: orderItem.KOT_id, product_id: itemId },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          updateQuantityInList(itemId, id, newQuantity, table);
          if (viewKOT) {
            handleKOTClick();
          }
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const handleMinusClick = (itemId, id, table) => {
    playSound();
    const orderItem = (orderList[table]?.orders || []).find(
      (order) =>
        order.id === itemId &&
        order.fooder_id === fooderId &&
        order.local_id === id
    );

    if (orderItem && orderItem.quantity > 1) {
      const newQuantity = orderItem.quantity - 1;
      updateQuantityInList(itemId, id, newQuantity, table);
    }
  };

  const handleMinusClickAfterKOT = async (itemId, id, table) => {
    playSound();
    const orderItem = (orderList[table]?.orders || []).find(
      (order) =>
        order.id === itemId &&
        order.fooder_id === fooderId &&
        order.local_id === id
    );

    if (orderItem && orderItem.quantity > 1) {
      const newQuantity = orderItem.quantity - 1;

      setIsLoading(true);
      try {
        const response = await axios({
          method: "put",
          url: DecreaseKOTGeneratedItemQuantityURL,
          data: { id: orderItem.KOT_id, product_id: itemId },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          updateQuantityInList(itemId, id, newQuantity, table);
          if (viewKOT) {
            handleKOTClick();
          }
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const deleteOrderItem = (itemId, id, table) => {
    const updatedOrderList = { ...orderList };

    if (updatedOrderList[table] && updatedOrderList[table].orders) {
      updatedOrderList[table].orders = updatedOrderList[table].orders.filter(
        (order) =>
          !(
            order.id === itemId &&
            order.fooder_id === fooderId &&
            order.local_id === id
          )
      );

      localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
      setOrderList(updatedOrderList);
      setFlag(!flag);
    }
  };

  const handleDeleteClick = (itemId, id, table) => {
    playSound();
    deleteOrderItem(itemId, id, table);
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const updateInstructions = (value) => {
    const updatedOrderList = { ...orderList };
    if (updatedOrderList[selectedTable]) {
      updatedOrderList[selectedTable].instructions = value;
    }
    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const handleSaveCustomerSuggetions = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxCharacterLimit) {
      setCustomerSuggestion(inputValue);
      updateInstructions(inputValue);
    }
  };

  const handleSaveProductNote = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxCharacterLimit) {
      setProductNote(inputValue);
    }
  };

  const handleKOTItemDeleteReason = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxCharacterLimit) {
      setDeleteReason(inputValue);
    }
  };

  const handleKOTItemDeleteClick = (item) => {
    playSound();
    setDeleteKOTItem(item);
    setDeleteReason("");
    setIsKOTItemDeleteModal(true);
  };

  const handleKOTItemDelete = async (e) => {
    e.preventDefault();

    if (deleteReason === "" || deleteReason.trim() === "") {
      addToast("error", "Please enter reason first");
    } else {
      const dataToSend = {
        kot_id: deleteKOTItem.KOT_id,
        remove_item: {
          product_id: deleteKOTItem.id,
          reason: deleteReason,
        },
      };

      try {
        const response = await axios({
          method: "put",
          url: DeleteKOTGeneratedItemURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          deleteOrderItem(
            deleteKOTItem.id,
            deleteKOTItem.local_id,
            deleteKOTItem.table_id
          );
          setIsKOTItemDeleteModal(false);
          if (viewKOT) {
            handleKOTClick();
          }
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    }
  };

  const updateStaff = (value) => {
    const updatedOrderList = { ...orderList };
    if (updatedOrderList[selectedTable]) {
      updatedOrderList[selectedTable].staff = value;
    }
    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const handleAssignWaiter = (selectedOption) => {
    setSelectedWaiter(selectedOption);
    updateStaff(selectedOption);
  };









  const handleChangeKOTStatus = async (e, id, status) => {
    e.preventDefault();

    const data = { id: id, status: status };

    try {
      const response = await axios({
        method: "put",
        url: UpdateKOTStatusURL,
        data: data,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.message) {
        await handleKOTClick();
        addToast("success", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };











  const [onlySavePaymentData, setSavePaymentData] = useState([]);













  // ***********************************************************************************************************



  const handleKOTClick = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(GetKOTDetailsListURL, {
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data.data) {
        setDataKOTDetails(response.data.data);
        setViewKOT(true);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedHoursWithLeadingZero =
      formattedHours < 10 ? `0${formattedHours}` : formattedHours;

    return `${formattedHoursWithLeadingZero}:${formattedMinutes} ${ampm}`;
  }


  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (token)
      handleKOTClick()
    setToken(localStorage.getItem("token"));

    
  }, [token]);

  // useEffect(() => {
     
    
  //   const existingScript = document.getElementById(ScriptId);
  //   if (existingScript) {
  //     document.head.removeChild(existingScript);
  //   }
  // }, []);

  // useEffect(() => {
  //   const existingScript = document.getElementById(ScriptId);
  //   if (!existingScript && localStorage.getItem("userId")) {
  //     const userId = localStorage.getItem("userId");
  //     const script = document.createElement("script");
  //     script.src = ScriptSRC + userId.replace(/"/g, "");
  //     script.defer = true;
  //     script.id = ScriptId;
  //     document.head.appendChild(script);
  //   }
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("planDetails")) {
      setPlanDetails(JSON.parse(localStorage.getItem("planDetails")));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);








  async function getData() {
    setIsLoading(true);
    try {
      const response = await axios.get(GetPOSDetailsURL, {
        headers: { authorization: `Bearer ${token}` },
      });

      if (
        response.status === 200 &&
        response.data &&
        response.data.menus &&
        response.data.product
      ) {
        setData(response.data);
        setMenus(response.data.menus);
        setProducts(response.data.product);
        setCurrentMenu("all");
        setCurrentMenuProducts(response.data.product);
        setFooderId(response.data.fooder_id);
        setTableDetailsByCategory(response.data.tblCategoryDetails);

        if (
          response.data.waiterDetails &&
          response.data.waiterDetails.length !== 0
        ) {
          const options = response.data.waiterDetails.map((staff) => ({
            value: staff.id,
            label:
              staff.type === 0
                ? `${staff.name}(Waiter)`
                : staff.type === 3
                  ? `${staff.name}(Delivery Guy)`
                  : staff.name,
            name: staff.name,
            phone_number: staff.phone_number,
            email: staff.email,
            type: staff.type,
          }));

          setWaiterList(options);
        }

        if (
          response.data.service_charge_details.percentage !== "0" &&
          !isNaN(
            parseFloat(response.data.service_charge_details.percentage)
          ) &&
          isFinite(response.data.service_charge_details.percentage)
        ) {
          setSCHRate(
            parseFloat(response.data.service_charge_details.percentage)
          );
        }

        if (
          response.data.tax_Details &&
          response.data.tax_Details.length !== 0
        ) {
          response.data.tax_Details.forEach((item) => {
            if (item.name === "CGST") {
              setCGSTRate(item.percentage);
            } else if (item.name === "SGST") {
              setSGSTRate(item.percentage);
            }
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    if (token) {
      
      getData();
    }
  }, [token, addToast]);

  useEffect(() => {
    const storedOrderList = JSON.parse(localStorage.getItem("orderList")) || {};

    setOrderList(storedOrderList);
  }, [selectedTable, flag]);









  const [cartProductTotal, setCartProductTotal] = useState(0);

  const [cartProductPackingCharges, setCartProductPackingCharges] = useState(0);


  const [cartProductSubTotal, setCartProductSubTotal] = useState(0);
  const [cartProductServiceCharge, setCartProductServiceCharge] = useState(0);
  const [cartProductDiscount, setCartProductDiscount] = useState(0);

  const [cartProductTax, setCartProductTax] = useState(0); // total tax for bill api




  const [CGSTTaxT1, setCGSTTaxt1] = useState(0);
  const [CGSTTaxT2, setCGSTTaxt2] = useState(0);
  const [CGSTTaxT3, setCGSTTaxt3] = useState(0);
  const [CGSTTaxT4, setCGSTTaxt4] = useState(0);
  const [CGSTTaxT5, setCGSTTaxt5] = useState(0);


  const [SGSTTaxT1, setSGSTTaxt1] = useState(0);
  const [SGSTTaxT2, setSGSTTaxt2] = useState(0);
  const [SGSTTaxT3, setSGSTTaxt3] = useState(0);
  const [SGSTTaxT4, setSGSTTaxt4] = useState(0);
  const [SGSTTaxT5, setSGSTTaxt5] = useState(0);


  const [cartDataLen, setCartDataLen] = useState(0);










  useEffect(() => {
    if (data && filteredOrders) {




     
      if (newFilteredOrders.length > 0) {

        setCGSTTaxt1(0);
        setCGSTTaxt2(0);
        setCGSTTaxt3(0);
        setCGSTTaxt4(0);
        setCGSTTaxt5(0);


        setSGSTTaxt1(0);
        setSGSTTaxt2(0);
        setSGSTTaxt3(0);
        setSGSTTaxt4(0);
        setSGSTTaxt5(0);

        try {

          let subTotal = 0;
          let tempDiscount = 0;
          let tempDiscountRow = 0;

          let tempServicCharge = 0;
          let tempServicChargeRow = 0;

          let tempTax = 0;
          let tempTaxRow = 0;

          let GSTTaxT1 = 0;
          let GSTTaxT2 = 0;
          let GSTTaxT3 = 0;
          let GSTTaxT4 = 0;
          let GSTTaxT5 = 0;



          let tempCartLen = 0;
          let billDiscountRate = discountValues.rate;

          let packingCharges = 0;


          // console.log("newFilteredOrders")
          // console.log(newFilteredOrders)



          //  for flate amount discount

          let withOutTaxPriceForAmount = 0
          let subTotalForAmount = 0;
          let discountRateForAmount = 0;

          if (discountType === "1") {
            newFilteredOrders.forEach((res) => {
              res.items.forEach((i) => {
                subTotalForAmount += (i.quantity) * i.withOutTaxPrice
              })
            });
            discountRateForAmount = (parseFloat(billDiscountRate) * 100) / subTotalForAmount
          }

          // end

          newFilteredOrders.forEach((res) => {
            res.items.forEach((i) => {

              packingCharges += i.quantity * parseInt(i.packaging_charges) || 0
              tempCartLen += parseInt(i.quantity)
              // console.log("tempCartLen")

              // console.log(tempCartLen)

              tempDiscountRow = 0;


              subTotal += (i.quantity) * i.withOutTaxPrice

              // console.log(subTotal)

              if (discountType === "0") {
                tempDiscount += (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
                tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
              } else {
                // tempDiscount += ((i.quantity * i.withOutTaxPrice) - ((i.quantity * i.withOutTaxPrice) - billDiscountRate)) * i.quantity
                // tempDiscountRow = ((i.quantity * i.withOutTaxPrice) - ((i.quantity * i.withOutTaxPrice) - billDiscountRate)) * i.quantity
                tempDiscount += (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100
                tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100

              }





              tempServicCharge += ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100
              tempServicChargeRow = ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100



              tempTax += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
              tempTaxRow = ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100


              if (i.tax_percent === 0) {

                GSTTaxT1 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                setCGSTTaxt1(GSTTaxT1 / 2)
                setSGSTTaxt1(GSTTaxT1 / 2)

              }

              if (i.tax_percent === 5) {

                GSTTaxT2 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

                setCGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
                setSGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
              }

              if (i.tax_percent === 12) {

                GSTTaxT3 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

                setCGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
                setSGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
              }

              if (i.tax_percent === 18) {
                // console.log('tax %')
                // console.log(i.tax_percent)
                GSTTaxT4 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                setCGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
                setSGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
              }

              if (i.tax_percent === 28) {
                // console.log('tax %')
                // console.log(i.tax_percent)
                GSTTaxT5 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                setCGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
                setSGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
              }



            })
          });


          setCartProductSubTotal(subTotal.toFixed(2));
          // console.log(subTotal)
          // console.log(tempServicCharge)
          setCartProductServiceCharge(tempServicCharge.toFixed(2))
          setCartProductDiscount(tempDiscount.toFixed(2))
          setCartProductTax(tempTax.toFixed(2))
          setCartProductTotal((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2));
          setCartDataLen(tempCartLen)

          // setTotalAmountPaid((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2) + packingCharges)

          if (orderType !== "DINE IN") {
            setCartProductPackingCharges(packingCharges)
            setTotalAmountPaid((parseFloat(subTotal + tempServicCharge - tempDiscount + tempTax) + parseFloat(packingCharges)).toFixed(2))
            setCartProductTotal((parseFloat(subTotal + tempServicCharge - tempDiscount + tempTax) + parseFloat(packingCharges)).toFixed(2));

            // alert('dd')
          } else {
            setCartProductPackingCharges(0)
            setTotalAmountPaid((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))



            let updatedOrderList = JSON.parse(localStorage.getItem("orderList"));

            if (updatedOrderList && updatedOrderList[selectedTable] && (subTotal + tempServicCharge - tempDiscount + tempTax) > 0) {
              updatedOrderList[selectedTable].grandTotal = (subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2);
              localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
              // setOrderList(updatedOrderList);
              // alert("dd")

            }

          }


          // setProductCartData(productCartDataTemp)
        }
        catch (e) {

        }
      }








    }
  }, [
    filteredOrders,
    data,
    discountRate,
    discountType,
    // SCHRate,
    // CGSTRate,
    // SGSTRate,
  ]);


  useEffect(() => {
    function formatDateToCustomString() {
      const currentDate = new Date();

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const day = currentDate.getDate();
      const month = months[currentDate.getMonth()];
      const year = currentDate.getFullYear();

      let dayWithSuffix = day + "th";
      if (day === 1 || day === 21 || day === 31) {
        dayWithSuffix = day + "st";
      } else if (day === 2 || day === 22) {
        dayWithSuffix = day + "nd";
      } else if (day === 3 || day === 23) {
        dayWithSuffix = day + "rd";
      }

      const formattedDate = `${dayWithSuffix} ${month} ${year}`;

      return formattedDate;
    }

    const formattedDate = formatDateToCustomString();
    setDate(formattedDate);
  }, [currentTime]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    if (latestItemRef.current) {
      latestItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [orderList, newFilteredOrders]);

  useEffect(() => {
    if (selectedTableDetails) {
      setSelectedTable(selectedTableDetails.id);
    }
  }, [selectedTableDetails]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].discountValues) {
      setDiscountType(orderList[selectedTable].discountValues.type);
      setDiscountRate(orderList[selectedTable].discountValues.rate);
      setDiscountValues(orderList[selectedTable].discountValues);
    } else {
      setDiscountType("0");
      setDiscountRate("0");
      setDiscountValues({
        type: "0",
        rate: "0",
      });
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].customerDetails) {
      setCustomerForm(orderList[selectedTable].customerDetails);
    } else {
      setCustomerForm({
        name: "",
        address: "",
        mobile: "",
      });
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].totalPersons) {
      setNoOfPeople(orderList[selectedTable].totalPersons);
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].instructions) {
      setCustomerSuggestion(orderList[selectedTable].instructions);
    } else {
      setCustomerSuggestion("");
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].staff) {
      setSelectedWaiter(orderList[selectedTable].staff);
    } else {
      setSelectedWaiter(null);
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].orders) {
      const groupedData = {};

      orderList[selectedTable].orders.forEach((item) => {
        const { KOT_no, KOT_time } = item;

        const groupKey = `${KOT_no}_${KOT_time}`;

        if (!groupedData[groupKey]) {
          groupedData[groupKey] = {
            KOT_no: KOT_no,
            KOT_time: KOT_time,
            items: [],
          };
        }

        groupedData[groupKey].items.push(item);
      });

      const newResult = Object.values(groupedData);

      // console.log(orderList[selectedTable], "fds;jkadfsjkl;dfasjkladfsjkldfjadfsdfsadfsjkl;")
      newResult.forEach((main) => {
        // console.log(main)
        main.items.forEach((i) => {
          // console.log(i)
          // item.withOutTaxPrice = 0
          // item.withTaxPrice = 0

          // if (i.proprice) {

          //   i.withTaxPrice = i.tax_type === '1' ? parseInt(i.proprice) : parseInt(i.proprice) + (parseInt(i.proprice) * parseInt(i.tax_percent) / 100)
          //   i.withOutTaxPrice = i.tax_type === '0' ? parseInt(i.proprice) : (parseInt(i.proprice) / (1 + parseInt(i.tax_percent) / 100))
          // }
          // else {
          //   i.withTaxPrice = i.tax_type === '1' ? parseInt(i.price) : parseInt(i.price) + (parseInt(i.price) * parseInt(i.tax_percent) / 100)
          //   i.withOutTaxPrice = i.tax_type === '0' ? parseInt(i.price) : parseInt(i.price) - (parseInt(i.price) * parseInt(i.tax_percent) / 100)
          // }


          i.withTaxPrice = i.tax_type === 1 ? parseFloat(i.newPrice) : parseFloat(i.newPrice) + (parseFloat(i.newPrice) * parseFloat(i.tax_percent) / 100)
          // i.withOutTaxPrice = i.tax_type === 0 ? parseInt(i.newPrice) : parseInt(i.newPrice) - (parseInt(i.newPrice) * parseInt(i.tax_percent) / 100)
          i.withOutTaxPrice = i.tax_type === 0 ? parseFloat(i.newPrice) : (parseFloat(i.newPrice) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.tax_percent))

        })
      });
      setNewFilteredOrders(newResult);
    } else {
      setNewFilteredOrders([]);
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (selectedPaymentOption && selectedPaymentOption === "Cash") {
      setCashPaymentDetails((prev) => ({
        ...prev,
        customerPaid: totalAmountPaid ? totalAmountPaid : "",
        tip: "0",
        amount: totalAmountPaid ? totalAmountPaid : "",
        transaction_id: "",
        returnToCustomer: "0",
      }));
    }

    if (selectedPaymentOption && selectedPaymentOption === "Card") {
      setCardPaymentDetails((prev) => ({
        ...prev,
        customerPaid: totalAmountPaid ? totalAmountPaid : "",
        tip: "0",
        amount: totalAmountPaid ? totalAmountPaid : "",
        transaction_id: ""
      }));
    }

    if (selectedPaymentOption && selectedPaymentOption === "NEFT") {
      setNeftPaymentDetails((prev) => ({
        ...prev,
        customerPaid: totalAmountPaid ? totalAmountPaid : "",
        tip: "0",
        amount: totalAmountPaid ? totalAmountPaid : "",
        transaction_id: ""
      }));
    }

    if (selectedPaymentOption && selectedPaymentOption === "UPI") {
      setUpiPaymentDetails((prev) => ({
        ...prev,
        amount: totalAmountPaid ? totalAmountPaid : "",
        tip: "0",
        transaction_id: ""
      }));
    }
  }, [selectedPaymentOption, totalAmountPaid, selectedTable, onlySavePaymentData]);

  useEffect(() => {
    if (printKOTDetails.KOT_no) {
      const handlePrintKOT = async () => {
        if (printKOTDetails.KOT_no !== "") {
          printJS({
            printable: "contentToPrintKOT",
            type: "html",
            targetStyles: ["*"],

          });

          setPrintKOTDetails({
            KOT_no: "",
            KOT_items: [],
          });
        }
      };
      handlePrintKOT();
    }
  }, [printKOTDetails.KOT_no]);

  useEffect(() => {
    if (printKOTDetails2) {
      const handlePrintKOT = async () => {
        if (printKOTDetails2) {
          printJS({
            printable: "contentToPrintKOT2",
            type: "html",
            targetStyles: ["*"],
          });

          setPrintKOTDetails2(null);
        }
      };
      handlePrintKOT();
    }
  }, [printKOTDetails2]);

  useEffect(() => {
    if (
      isPrintBill &&
      filteredOrders.length !== 0 &&
      // subTotal &&
      printBillDetails
    ) {
      const handlePrintBill = async () => {
        if (
          isPrintBill &&
          filteredOrders.length !== 0 &&
          // subTotal &&
          printBillDetails
        ) {
          // alert(CGSTTaxT1)
          // alert(CGSTTaxT2)
          // alert(CGSTTaxT3)
          // alert(CGSTTaxT4)


          printJS({
            printable: "contentToPrintBill2",
            type: "html",
            targetStyles: ["*"],
            font_size: '8pt'

          });

          setIsPrintBill(false);
          setSelectedTableDetails(null);
          setSelectedTable("");
        }
      };
      handlePrintBill();
    }
  }, [isPrintBill, filteredOrders, subTotal, printBillDetails]);


  const [seconds, setSeconds] = useState(0);
  const [gettimer, satTimer] = useState(30);




  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(seconds + 1);
   
       satTimer(gettimer - 1)
       if(gettimer < 1){
        // alert("dfdf")
        satTimer(30)
       
        handleKOTClick()
       }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);


  return (
    <>
      {token && (
        <>
          <div className="main-panel layout">
            <div className="content-wrapper contentdiv">
              <div className="row">
                <div
                  className={
                    viewKOT
                      ? "col-lg-12 d-flex flex-column"
                      : "col-lg-9 d-flex flex-column leftdiv"
                  }
                >
                  <div className="headernav">
                    <div className="row">
                      
                      <div className="col-lg-6 text-left">
                        <h4 className="welcome-text">
                          Hello,{" "}
                          <span className="text-black fw-bold">
                            {data && capitalizeFirstLetter(data.fooder_name)}

                           
                          </span>
                        </h4>
                      </div>

                      <div className="col-lg-6 text-right">
                        <h4 className="welcome-text  px-3">
                         
                        
                           
                           This page will refresh in <i className="menu-icon mdi mdi-timer menu-icon vsalign" style={{fontSize:"20px"}}></i>  <span className="text-danger fw-bold"> {gettimer}</span>  seconds
                        
                        

                        </h4>
                      </div>

                    </div>
                  </div>


                  {viewKOT ? (
                    <>
                      <div className="row flex-grow">
                        <div className="col-lg-12 d-flex flex-column">
                          <div className="kotslr ps-4 mt-4">
                            <div className="tablenames">
                              <>
                                <div>
                                  <div className="row me-2 mb-2">
                                    <div className="col-8">
                                      <div className="customchk mb-3">
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox6"></span>{" "}
                                            Queue KOT
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox7"></span>{" "}
                                            Cooking KOT
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox3"></span>{" "}
                                            Ready KOT
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox5"></span>{" "}
                                            Cancel KOT
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4 text-right">

                                      <span
                                        className="btn btn-danger p-2"
                                        onClick={() => {
                                          window.location.href = "/";
                                        }}
                                      >
                                        <i className="mdi mdi-close vsalign"></i>
                                        &nbsp;&nbsp;Close
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row flex-grow">
                                    {dataKOTDetails.length === 0 ? (
                                      <>
                                        <h4>No KOT Data Found</h4>
                                      </>
                                    ) : (
                                      <div class="row">
                                      <div class="col-sm-12 kottabs">
                                        <div class="home-tab">
                                          <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                                            <ul
                                              class="nav nav-tabs"
                                              role="tablist"
                                            >
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link active ps-0"
                                                  id="home-tab"
                                                  data-bs-toggle="tab"
                                                  href="#allkot"
                                                  role="tab"
                                                  aria-controls="overview"
                                                  aria-selected="true"
                                                >
                                                  All
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link"
                                                  id="profile-tab"
                                                  data-bs-toggle="tab"
                                                  href="#quenekot"
                                                  role="tab"
                                                  aria-selected="false"
                                                >
                                                  Queue
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link"
                                                  id="contact-tab"
                                                  data-bs-toggle="tab"
                                                  href="#cookingkot"
                                                  role="tab"
                                                  aria-selected="false"
                                                >
                                                  Cooking
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link border-0"
                                                  id="more-tab"
                                                  data-bs-toggle="tab"
                                                  href="#readykot"
                                                  role="tab"
                                                  aria-selected="false"
                                                >
                                                  Ready
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                          <div class="tab-content tab-content-basic">
                                            <div
                                              class="tab-pane fade show active"
                                              id="allkot"
                                              role="tabpanel"
                                              aria-labelledby="allkot"
                                            >
                                              <div class="row flex-grow">
                                                <div>
                                                  <div className="row mr10">
                                                    {dataKOTDetails.map(
                                                      (item, index) => (
                                                        <div
                                                          key={index}
                                                          className="col-lg-2 col-md-6 col-12 stretch-card"
                                                        >
                                                          <div className="card card-rounded grid-margin">
                                                            <div className="card-body kotcrd">
                                                              <div
                                                                className={
                                                                  item.status ===
                                                                    2
                                                                    ? "row ready"
                                                                    : item.status ===
                                                                      1
                                                                      ? "row inprocess"
                                                                      : item.status ===
                                                                        0
                                                                        ? "row inque"
                                                                        : item.status ===
                                                                          4 ? "row cancel" : "row"
                                                                }
                                                              >
                                                                <div
                                                                  className={
                                                                    item.status ===
                                                                      0
                                                                      ? "d-sm-flex justify-content-between mt-1 mb-1"
                                                                      : "d-sm-flex justify-content-between mt-1 mb-1 text-white"
                                                                  }
                                                                >
                                                                  <div className="">
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          item.kot
                                                                        }
                                                                      </strong>
                                                                      {/* &nbsp;&nbsp;
                                                        <span>
                                                          Sambilor Thal
                                                        </span> */}
                                                                    </p>
                                                                    <p className="mb-0">
                                                                      {item.table_no
                                                                        ? `${item.table_no} : `
                                                                        : item.order_number
                                                                          ? `#${item.order_number} : `
                                                                          : ""}{" "}
                                                                      {
                                                                        item.order_type
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                  <div className="text-right">
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          item.time
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                    {item.status ===
                                                                      0 && (
                                                                        <div className="badge bg-white">
                                                                          Queue
                                                                        </div>
                                                                      )}
                                                                    {item.status ===
                                                                      1 && (
                                                                        <div className="badge bg-white">
                                                                          Cooking
                                                                        </div>
                                                                      )}
                                                                    {item.status ===
                                                                      2 && (
                                                                        <div className="badge bg-white">
                                                                          Ready
                                                                        </div>
                                                                      )}

                                                                    {item.status ===
                                                                      4 && (
                                                                        <div className="badge bg-white">
                                                                          Cancel
                                                                        </div>
                                                                      )}


                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <hr />
                                                              <div className="crdscrl">
                                                                {item.final_kot_details.map(
                                                                  (
                                                                    kotItem,
                                                                    index
                                                                  ) => (
                                                                    <div
                                                                      key={
                                                                        index
                                                                      }
                                                                      className="row"
                                                                    >
                                                                      <p>
                                                                        <strong>
                                                                          {
                                                                            kotItem.quantity
                                                                          }
                                                                        </strong>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {capitalizeFirstLetter(
                                                                          kotItem.product_name
                                                                        )}
                                                                      </p>
                                                                    </div>
                                                                  )
                                                                )}
                                                              </div>
                                                              <div className="row mt-2">
                                                                <div className="d-sm-flex justify-content-between">
                                                                  <div className="col-9">
                                                                    {item.status ===
                                                                      0 && (
                                                                        <button
                                                                          type="submit"
                                                                          className="btn btn-inque text-center finishbtn"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            handleChangeKOTStatus(
                                                                              e,
                                                                              item.id,
                                                                              1
                                                                            )
                                                                          }
                                                                        >
                                                                          Start
                                                                          Cooking
                                                                        </button>
                                                                      )}
                                                                    {item.status ===
                                                                      1 && (
                                                                        <button
                                                                          type="submit"
                                                                          className="btn btn-warning text-center finishbtn"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            handleChangeKOTStatus(
                                                                              e,
                                                                              item.id,
                                                                              2
                                                                            )
                                                                          }
                                                                        >
                                                                          Finish
                                                                          Cooking
                                                                        </button>
                                                                      )}
                                                                    {item.status ===
                                                                      2 && (
                                                                        <button
                                                                          type="submit"
                                                                          className="btn btn-ready text-center finishbtn"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            handleChangeKOTStatus(
                                                                              e,
                                                                              item.id,
                                                                              3
                                                                            )
                                                                          }
                                                                        >
                                                                          Sent
                                                                        </button>
                                                                      )}

                                                                    {item.status ===
                                                                      4 && (
                                                                        <button
                                                                          type="submit"
                                                                          className="btn btn-cancel text-center finishbtn"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            handleChangeKOTStatus(
                                                                              e,
                                                                              item.id,
                                                                              5
                                                                            )
                                                                          }
                                                                        >
                                                                          Cancel
                                                                        </button>
                                                                      )}
                                                                  </div>
                                                                  <div className="text-right col-3">
                                                                    <button
                                                                      type="submit"
                                                                      className="btn btn-outline-dark btn-fw printbtn"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();
                                                                        setPrintKOTDetails2(
                                                                          item
                                                                        );
                                                                      }}
                                                                    >
                                                                      <i className="fa fa-print"></i>
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              class="tab-pane fade"
                                              id="quenekot"
                                              role="tabpanel"
                                              aria-labelledby="quenekot"
                                            >
                                              <div class="row flex-grow">
                                                <div>
                                                  <div className="row mr10">
                                                    {dataKOTDetails.filter(
                                                      (item) =>
                                                        item.status === 0
                                                    ).length === 0 ? (
                                                      <>
                                                        <h4>
                                                          Record Not Found!
                                                        </h4>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {dataKOTDetails
                                                          .filter(
                                                            (item) =>
                                                              item.status ===
                                                              0
                                                          )
                                                          .map(
                                                            (item, index) => (
                                                              <div
                                                                key={index}
                                                                className="col-lg-2 stretch-card"
                                                              >
                                                                <div className="card card-rounded grid-margin">
                                                                  <div className="card-body kotcrd">
                                                                    <div
                                                                      className={
                                                                        item.status ===
                                                                          2
                                                                          ? "row ready"
                                                                          : item.status ===
                                                                            1
                                                                            ? "row inprocess"
                                                                            : item.status ===
                                                                              0
                                                                              ? "row inque"
                                                                              : "row"
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          item.status ===
                                                                            0
                                                                            ? "d-sm-flex justify-content-between mt-1 mb-1"
                                                                            : "d-sm-flex justify-content-between mt-1 mb-1 text-white"
                                                                        }
                                                                      >
                                                                        <div className="">
                                                                          <p className="mb-0">
                                                                            <strong>
                                                                              {
                                                                                item.kot
                                                                              }
                                                                            </strong>
                                                                            {/* &nbsp;&nbsp;
                                                        <span>
                                                          Sambilor Thal
                                                        </span> */}
                                                                          </p>
                                                                          <p className="mb-0">
                                                                            {item.table_no
                                                                              ? `Table No - ${item.table_no} : `
                                                                              : item.order_number
                                                                                ? `#${item.order_number} : `
                                                                                : ""}{" "}
                                                                            {
                                                                              item.order_type
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="text-right">
                                                                          <p className="mb-0">
                                                                            <strong>
                                                                              {
                                                                                item.time
                                                                              }
                                                                            </strong>
                                                                          </p>
                                                                          {item.status ===
                                                                            0 && (
                                                                              <div className="badge bg-white">
                                                                                Queue
                                                                              </div>
                                                                            )}
                                                                          {item.status ===
                                                                            1 && (
                                                                              <div className="badge bg-white">
                                                                                Cooking
                                                                              </div>
                                                                            )}
                                                                          {item.status ===
                                                                            2 && (
                                                                              <div className="badge bg-white">
                                                                                Ready
                                                                              </div>
                                                                            )}
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="crdscrl">
                                                                      {item.final_kot_details.map(
                                                                        (
                                                                          kotItem,
                                                                          index
                                                                        ) => (
                                                                          <div
                                                                            key={
                                                                              index
                                                                            }
                                                                            className="row"
                                                                          >
                                                                            <p>
                                                                              <strong>
                                                                                {
                                                                                  kotItem.quantity
                                                                                }
                                                                              </strong>
                                                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                                                              {capitalizeFirstLetter(
                                                                                kotItem.product_name
                                                                              )}
                                                                            </p>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                      <div className="d-sm-flex justify-content-between">
                                                                        <div className="col-9">
                                                                          {item.status ===
                                                                            0 && (
                                                                              <button
                                                                                type="submit"
                                                                                className="btn btn-inque text-center finishbtn"
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeKOTStatus(
                                                                                    e,
                                                                                    item.id,
                                                                                    1
                                                                                  )
                                                                                }
                                                                              >
                                                                                Start
                                                                                Cooking
                                                                              </button>
                                                                            )}
                                                                          {item.status ===
                                                                            1 && (
                                                                              <button
                                                                                type="submit"
                                                                                className="btn btn-warning text-center finishbtn"
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeKOTStatus(
                                                                                    e,
                                                                                    item.id,
                                                                                    2
                                                                                  )
                                                                                }
                                                                              >
                                                                                Finish
                                                                                Cooking
                                                                              </button>
                                                                            )}
                                                                          {item.status ===
                                                                            2 && (
                                                                              <button
                                                                                type="submit"
                                                                                className="btn btn-ready text-center finishbtn"
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeKOTStatus(
                                                                                    e,
                                                                                    item.id,
                                                                                    3
                                                                                  )
                                                                                }
                                                                              >
                                                                                Sent
                                                                              </button>
                                                                            )}
                                                                        </div>
                                                                        <div className="text-right col-3">
                                                                          <button
                                                                            type="submit"
                                                                            className="btn btn-outline-dark btn-fw printbtn"
                                                                            onClick={(
                                                                              e
                                                                            ) => {
                                                                              e.preventDefault();
                                                                              setPrintKOTDetails2(
                                                                                item
                                                                              );
                                                                            }}
                                                                          >
                                                                            <i className="fa fa-print"></i>
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              class="tab-pane fade"
                                              id="cookingkot"
                                              role="tabpanel"
                                              aria-labelledby="cookingkot"
                                            >
                                              <div class="row flex-grow">
                                                <div>
                                                  <div className="row mr10">
                                                    {dataKOTDetails.filter(
                                                      (item) =>
                                                        item.status === 1
                                                    ).length === 0 ? (
                                                      <>
                                                        <h4>
                                                          Record Not Found!
                                                        </h4>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {dataKOTDetails
                                                          .filter(
                                                            (item) =>
                                                              item.status ===
                                                              1
                                                          )
                                                          .map(
                                                            (item, index) => (
                                                              <div
                                                                key={index}
                                                                className="col-lg-2 stretch-card"
                                                              >
                                                                <div className="card card-rounded grid-margin">
                                                                  <div className="card-body kotcrd">
                                                                    <div
                                                                      className={
                                                                        item.status ===
                                                                          2
                                                                          ? "row ready"
                                                                          : item.status ===
                                                                            1
                                                                            ? "row inprocess"
                                                                            : item.status ===
                                                                              0
                                                                              ? "row inque"
                                                                              : "row"
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          item.status ===
                                                                            0
                                                                            ? "d-sm-flex justify-content-between mt-1 mb-1"
                                                                            : "d-sm-flex justify-content-between mt-1 mb-1 text-white"
                                                                        }
                                                                      >
                                                                        <div className="">
                                                                          <p className="mb-0">
                                                                            <strong>
                                                                              {
                                                                                item.kot
                                                                              }
                                                                            </strong>
                                                                            {/* &nbsp;&nbsp;
                                                        <span>
                                                          Sambilor Thal
                                                        </span> */}
                                                                          </p>
                                                                          <p className="mb-0">
                                                                            {item.table_no
                                                                              ? `Table No - ${item.table_no} : `
                                                                              : item.order_number
                                                                                ? `#${item.order_number} : `
                                                                                : ""}{" "}
                                                                            {
                                                                              item.order_type
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="text-right">
                                                                          <p className="mb-0">
                                                                            <strong>
                                                                              {
                                                                                item.time
                                                                              }
                                                                            </strong>
                                                                          </p>
                                                                          {item.status ===
                                                                            0 && (
                                                                              <div className="badge bg-white">
                                                                                Queue
                                                                              </div>
                                                                            )}
                                                                          {item.status ===
                                                                            1 && (
                                                                              <div className="badge bg-white">
                                                                                Cooking
                                                                              </div>
                                                                            )}
                                                                          {item.status ===
                                                                            2 && (
                                                                              <div className="badge bg-white">
                                                                                Ready
                                                                              </div>
                                                                            )}
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="crdscrl">
                                                                      {item.final_kot_details.map(
                                                                        (
                                                                          kotItem,
                                                                          index
                                                                        ) => (
                                                                          <div
                                                                            key={
                                                                              index
                                                                            }
                                                                            className="row"
                                                                          >
                                                                            <p>
                                                                              <strong>
                                                                                {
                                                                                  kotItem.quantity
                                                                                }
                                                                              </strong>
                                                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                                                              {capitalizeFirstLetter(
                                                                                kotItem.product_name
                                                                              )}
                                                                            </p>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                      <div className="d-sm-flex justify-content-between">
                                                                        <div className="col-9">
                                                                          {item.status ===
                                                                            0 && (
                                                                              <button
                                                                                type="submit"
                                                                                className="btn btn-inque text-center finishbtn"
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeKOTStatus(
                                                                                    e,
                                                                                    item.id,
                                                                                    1
                                                                                  )
                                                                                }
                                                                              >
                                                                                Start
                                                                                Cooking
                                                                              </button>
                                                                            )}
                                                                          {item.status ===
                                                                            1 && (
                                                                              <button
                                                                                type="submit"
                                                                                className="btn btn-warning text-center finishbtn"
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeKOTStatus(
                                                                                    e,
                                                                                    item.id,
                                                                                    2
                                                                                  )
                                                                                }
                                                                              >
                                                                                Finish
                                                                                Cooking
                                                                              </button>
                                                                            )}
                                                                          {item.status ===
                                                                            2 && (
                                                                              <button
                                                                                type="submit"
                                                                                className="btn btn-ready text-center finishbtn"
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeKOTStatus(
                                                                                    e,
                                                                                    item.id,
                                                                                    3
                                                                                  )
                                                                                }
                                                                              >
                                                                                Sent
                                                                              </button>
                                                                            )}
                                                                        </div>
                                                                        <div className="text-right col-3">
                                                                          <button
                                                                            type="submit"
                                                                            className="btn btn-outline-dark btn-fw printbtn"
                                                                            onClick={(
                                                                              e
                                                                            ) => {
                                                                              e.preventDefault();
                                                                              setPrintKOTDetails2(
                                                                                item
                                                                              );
                                                                            }}
                                                                          >
                                                                            <i className="fa fa-print"></i>
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              class="tab-pane fade"
                                              id="readykot"
                                              role="tabpanel"
                                              aria-labelledby="readykot"
                                            >
                                              <div class="row flex-grow">
                                                <div>
                                                  <div className="row mr10">
                                                    {dataKOTDetails.filter(
                                                      (item) =>
                                                        item.status === 2
                                                    ).length === 0 ? (
                                                      <>
                                                        <h4>
                                                          Record Not Found!
                                                        </h4>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {dataKOTDetails
                                                          .filter(
                                                            (item) =>
                                                              item.status ===
                                                              2
                                                          )
                                                          .map(
                                                            (item, index) => (
                                                              <div
                                                                key={index}
                                                                className="col-lg-2 stretch-card"
                                                              >
                                                                <div className="card card-rounded grid-margin">
                                                                  <div className="card-body kotcrd">
                                                                    <div
                                                                      className={
                                                                        item.status ===
                                                                          2
                                                                          ? "row ready"
                                                                          : item.status ===
                                                                            1
                                                                            ? "row inprocess"
                                                                            : item.status ===
                                                                              0
                                                                              ? "row inque"
                                                                              : "row"
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          item.status ===
                                                                            0
                                                                            ? "d-sm-flex justify-content-between mt-1 mb-1"
                                                                            : "d-sm-flex justify-content-between mt-1 mb-1 text-white"
                                                                        }
                                                                      >
                                                                        <div className="">
                                                                          <p className="mb-0">
                                                                            <strong>
                                                                              {
                                                                                item.kot
                                                                              }
                                                                            </strong>
                                                                            {/* &nbsp;&nbsp;
                                                        <span>
                                                          Sambilor Thal
                                                        </span> */}
                                                                          </p>
                                                                          <p className="mb-0">
                                                                            {item.table_no
                                                                              ? `Table No - ${item.table_no} : `
                                                                              : item.order_number
                                                                                ? `#${item.order_number} : `
                                                                                : ""}{" "}
                                                                            {
                                                                              item.order_type
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="text-right">
                                                                          <p className="mb-0">
                                                                            <strong>
                                                                              {
                                                                                item.time
                                                                              }
                                                                            </strong>
                                                                          </p>
                                                                          {item.status ===
                                                                            0 && (
                                                                              <div className="badge bg-white">
                                                                                Queue
                                                                              </div>
                                                                            )}
                                                                          {item.status ===
                                                                            1 && (
                                                                              <div className="badge bg-white">
                                                                                Cooking
                                                                              </div>
                                                                            )}
                                                                          {item.status ===
                                                                            2 && (
                                                                              <div className="badge bg-white">
                                                                                Ready
                                                                              </div>
                                                                            )}
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="crdscrl">
                                                                      {item.final_kot_details.map(
                                                                        (
                                                                          kotItem,
                                                                          index
                                                                        ) => (
                                                                          <div
                                                                            key={
                                                                              index
                                                                            }
                                                                            className="row"
                                                                          >
                                                                            <p>
                                                                              <strong>
                                                                                {
                                                                                  kotItem.quantity
                                                                                }
                                                                              </strong>
                                                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                                                              {capitalizeFirstLetter(
                                                                                kotItem.product_name
                                                                              )}
                                                                            </p>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                      <div className="d-sm-flex justify-content-between">
                                                                        <div className="col-9">
                                                                          {item.status ===
                                                                            0 && (
                                                                              <button
                                                                                type="submit"
                                                                                className="btn btn-inque text-center finishbtn"
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeKOTStatus(
                                                                                    e,
                                                                                    item.id,
                                                                                    1
                                                                                  )
                                                                                }
                                                                              >
                                                                                Start
                                                                                Cooking
                                                                              </button>
                                                                            )}
                                                                          {item.status ===
                                                                            1 && (
                                                                              <button
                                                                                type="submit"
                                                                                className="btn btn-warning text-center finishbtn"
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeKOTStatus(
                                                                                    e,
                                                                                    item.id,
                                                                                    2
                                                                                  )
                                                                                }
                                                                              >
                                                                                Finish
                                                                                Cooking
                                                                              </button>
                                                                            )}
                                                                          {item.status ===
                                                                            2 && (
                                                                              <button
                                                                                type="submit"
                                                                                className="btn btn-ready text-center finishbtn"
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  handleChangeKOTStatus(
                                                                                    e,
                                                                                    item.id,
                                                                                    3
                                                                                  )
                                                                                }
                                                                              >
                                                                                Sent
                                                                              </button>
                                                                            )}
                                                                        </div>
                                                                        <div className="text-right col-3">
                                                                          <button
                                                                            type="submit"
                                                                            className="btn btn-outline-dark btn-fw printbtn"
                                                                            onClick={(
                                                                              e
                                                                            ) => {
                                                                              e.preventDefault();
                                                                              setPrintKOTDetails2(
                                                                                item
                                                                              );
                                                                            }}
                                                                          >
                                                                            <i className="fa fa-print"></i>
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {tableView ? (
                        <>

                        </>
                      ) : (
                        <>

                        </>
                      )}
                    </>
                  )}
                </div>

              </div>
            </div>
          </div>












          <div style={{ display: "none" }}>
            <div id="contentToPrintKOT">
              <div className="printcss">
                {/* <div className="col-12">
                  <p className="name">
                    <strong>
                      {data && capitalizeFirstLetter(data.fooder_name)}
                    </strong>
                  </p>
                </div>
                <div className="col-12">
                  {data && data.fooder_gstin && (
                    <p className="gst thank">
                      GST Number : {data && data.fooder_gstin}
                    </p>
                  )}
                </div> */}
                <div className="col-12">
                  {printKOTDetails.KOT_no && (
                    <p className="gst thank">
                      <strong>{printKOTDetails.KOT_no}</strong>
                    </p>
                  )}
                </div>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date : <span>{date}</span>
                      </td>
                      <td className="txtrg">
                        Time : <span>{currentTime}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{orderType}</strong>
                      </td>
                      {orderType === "DINE IN" && (
                        <td className="txtrg">
                          {/* Table No:{" "} */}
                          {selectedTableDetails
                            ? selectedTableDetails.table_no
                            : ""}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      {/* <th className="thcss qtyth txtct">Special Note</th> */}
                      <th className="thcss qtyth txtrg">Qty</th>
                    </tr>
                  </thead>

                  <tbody>
                    {printKOTDetails.KOT_items.map((order, index) => (
                      <tr key={index}>
                        <td className="tdcss nametd">
                          {capitalizeFirstLetter(order.product_name)}
                          {order.product_special_note ? <> <i>
                            <br />
                            {order.product_special_note}
                          </i></> : <></>}

                          {order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                            <> <br /><i>{i.attribute_name} :  {i.attribute_value_name} </i> </>
                          ))}
                          {order.selectedAddons && order.selectedAddons.map((i) => (
                            <> <br /> <i>{i.addon_item_name}</i></>
                          ))}
                        </td>
                        {/* <td className="tdcss qtytd txtct">
                          {order.product_special_note}
                        </td> */}
                        <td className="tdcss qtytd txtrg">{order.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintKOT2">
              <div className="printcss">
                {/* <div className="col-12">
                  <p className="name">
                    <strong>
                      {data && capitalizeFirstLetter(data.fooder_name)}
                    </strong>
                  </p>
                </div>
                <div className="col-12">
                  {data && data.fooder_gstin && (
                    <p className="gst thank">
                      GST Number : {data && data.fooder_gstin}
                    </p>
                  )}
                </div> */}
                <div className="col-12">
                  {printKOTDetails2 && (
                    <p className="gst thank">
                      <strong>{printKOTDetails2.kot}
                        {printKOTDetails2.status === 4 ? "(Cancelled)" : ""}
                      </strong>
                    </p>

                    
                  )}
                </div>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date : <span>{date}</span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>{printKOTDetails2 && printKOTDetails2.time}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          {printKOTDetails2 && printKOTDetails2.order_type}
                        </strong>
                      </td>
                      {printKOTDetails2 && printKOTDetails2.table_no ? (
                        <td className="txtrg">
                          {/* Table No:  */}
                          {printKOTDetails2.table_no}
                        </td>
                      ) : (
                        <td className="txtrg">
                          {printKOTDetails2 &&
                            `Order : #${printKOTDetails2.order_number}`}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      {/* <th className="thcss qtyth txtct">Special Note</th> */}
                      <th className="thcss qtyth txtrg">Qty</th>
                    </tr>
                  </thead>

                  <tbody>
                    {printKOTDetails2 &&
                      printKOTDetails2.final_kot_details.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}

                            {order.selectedvariants && order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                              <> <br /><i>{i.attribute_name} :  {i.attribute_value_name} </i> </>
                            ))}

                            {order.selectedAddons && order.selectedAddons.map((i) => (
                              <> <br /> <i>{i.addon_item_name}</i></>
                            ))}


                            {order.product_special_note ? <>
                              <br />
                              <i>
                                {order.product_special_note}
                              </i>
                            </> : <></>}


                          </td>
                          {/* <td className="tdcss qtytd txtct">
                            {order.product_special_note}
                          </td> */}
                          <td className="tdcss qtytd txtrg">
                            {order.quantity}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintBill">

              {/* {orderList ? {selectedTable} :<></>} */}

              {selectedTable && orderList[selectedTable] && orderList[selectedTable].invoice_number ? (
                <p id="invoice_number_on_bill" className="name" style={{ textAlign: "end" }}>
                  {orderList[selectedTable].invoice_number}
                </p>
              ) : (
                <p id="invoice_number_on_bill" className="name" style={{ textAlign: "end" }}>

                </p>
              )}





              <div className="printcss">
                <div className="col-12">
                  <p className="name" >
                    <strong>
                      {data && capitalizeFirstLetter(data.fooder_name)}
                    </strong>
                  </p>

                  <p className="name">

                    {data &&
                      capitalizeFirstLetter(data.Fooder_name2)}

                  </p>

                </div>

                <p className="name">
                  {data && capitalizeFirstLetter(data.f_address)}{', '}
                  {data &&
                    (data.f_city || data.f_state || data.f_zipcode) && (
                      <>
                        {data.f_city && `${data.f_city}, `}
                        {data.f_state && capitalizeFirstLetter(data.f_state)}
                        {data.f_zipcode && `, ${data.f_zipcode}`}
                      </>
                    )}
                </p>
                {data.f_landline ? <p className="name">Phone No :
                  {data.f_landline}

                </p> : <></>}




                <div className="col-12">
                  {data && data.fooder_gstin && (
                    <p className="gst thank">
                      GST Number : {data && data.fooder_gstin}
                    </p>
                  )}
                </div>

                <table className="tblecss info">

                  <tbody>


                    <tr>
                      <td>
                        Name :{" "}
                        {customerForm.name}
                      </td>

                    </tr>

                    {customerForm.mobile ? <tr>
                      <td>
                        Phone : {" "}
                        {capitalizeFirstLetter(customerForm.mobile)}
                      </td>
                    </tr> : <></>}


                    {customerForm.address && customerForm.address !== '' ? <tr>
                      <td>
                        Address : {" "}
                        {capitalizeFirstLetter(customerForm.address)}
                      </td>
                    </tr> : <></>}
                  </tbody>

                </table>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date : <span>{date}</span>
                      </td>
                      <td className="txtrg">
                        Time : <span>{currentTime}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{orderType}</strong>
                      </td>
                      {orderType === "DINE IN" && (
                        <td className="txtrg">
                          Table No : #
                          <span>
                            {selectedTableDetails
                              ? selectedTableDetails.table_no
                              : ""}
                          </span>
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th colSpan="2">
                        <p
                          style={{
                            textAlign: "center",
                            padding: "5px 2px",
                            margin: "0",
                            fontSize: "medium",
                          }}
                        >
                          Original Receipt
                        </p>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      <th className="thcss qtyth txtct">Qty</th>
                      <th className="thcss rateth txtrg">Rate</th>
                      <th className="thcss amtth txtrg">Amt</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredOrders.map((order, index) => (
                      <tr key={index}>
                        <td className="tdcss nametd">
                          {capitalizeFirstLetter(order.name)}

                          {order.selectedvariants && <>
                            {order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                              <><br /><b>{i.attribute_name}: </b> {i.attribute_value_name} </>
                            ))}
                          </>}

                          {order.selectedAddons && order.selectedAddons.map((i, no) => (
                            <><br /><b>{no + 1}.</b> {i.addon_item_name} </>
                          ))}

                        </td>
                        <td className="tdcss qtytd txtct">{order.quantity}</td>
                        <td className="tdcss ratetd txtrg">
                          {currencySymbol}
                          {isValidPrice(order.newPrice)}
                        </td>
                        <td className="tdcss amttd txtrg">
                          {currencySymbol}
                          {isValidPrice(order.quantity * order.newPrice)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className="tblecss2">
                  <tbody>
                    <tr>
                      <td className="txtrg brtp subtl">Subtotal</td>
                      <td className="txtrg brtp">
                        {currencySymbol}
                        {cartProductSubTotal}
                      </td>
                    </tr>
                    {discountRate &&
                      discountRate !== "0" &&
                      discountType === "0" && (
                        <tr>
                          <td className="txtrg subtl">
                            Discount ({discountRate}%)
                          </td>
                          <td className="txtrg">
                            {currencySymbol}
                            {cartProductDiscount}
                          </td>
                        </tr>
                      )}
                    {discountRate &&
                      discountRate !== "0" &&
                      discountType === "1" && (
                        <tr>
                          <td className="txtrg subtl">
                            Discount ({currencySymbol})
                          </td>
                          <td className="txtrg">
                            {currencySymbol}
                            {cartProductDiscount}
                          </td>
                        </tr>
                      )}
                    {SCHRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">
                          {data.service_charge_details &&
                            data.service_charge_details.name}
                          ({SCHRate}
                          %)
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {/* {schAmount} */}
                          {cartProductServiceCharge}
                        </td>
                      </tr>
                    )}
                    {/* {CGSTRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">CGST({CGSTRate}%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {cgstAmount}
                        </td>
                      </tr>
                    )}
                    {SGSTRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">SGST({SGSTRate}%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {sgstAmount}
                        </td>
                      </tr>
                    )} */}

                    {CGSTTaxT2 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(2.5%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT2}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT2 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(2.5%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT2}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT3 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(6%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT3}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT3 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(6%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT3}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT4 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(9%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT4}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT4 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(9%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT4}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT5 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(14%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT5}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT5 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(14%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT5}
                        </td>
                      </tr>)
                    }


                    {cartProductPackingCharges !== 0 && orderType !== "DINE IN" &&
                      (<tr>
                        <td className="txtrg subtl">Packaging Charges</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {cartProductPackingCharges}
                        </td>
                      </tr>)
                    }

                    <tr>
                      <th className="tlt">Total</th>
                      <th className="tlt txtrg pd0">
                        {currencySymbol}
                        {totalAmountPaid}
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss">
                  <tbody>
                    <tr>
                      <td>
                        {data && data.fssai_number && (
                          <p className="thank">
                            FSSAI Number : {data.fssai_number}
                          </p>
                        )}
                      </td>
                    </tr>
                    {data.payment_type ? <tr>
                      <td>
                        <p className="thank">Paid By {data.payment_type}</p>
                      </td>
                    </tr> : <></>}
                    <tr>
                      <td>
                        <p className="thank">{data.billing_notes}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="thank">Thank you for your visit!d</p>


                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintBill2">
              <div className="printcss">
                {selectedTable && orderList[selectedTable] && orderList[selectedTable].invoice_number ? (
                  <p id="invoice_number_on_bill_counter_delivery" className="name" style={{ textAlign: "end" }}>
                    {orderList[selectedTable].invoice_number}
                  </p>
                ) : (
                  <p id="invoice_number_on_bill_counter_delivery" className="name" style={{ textAlign: "end" }}>

                  </p>
                )}
                <div className="col-12">
                  <p className="name">
                    <strong>
                      {data && capitalizeFirstLetter(data.fooder_name)}
                    </strong>
                  </p>

                  <p className="name">

                    {data &&
                      capitalizeFirstLetter(data.Fooder_name2)}

                  </p>

                </div>

                <p className="name">
                  {data && capitalizeFirstLetter(data.f_address)}{', '}
                  {data &&
                    (data.f_city || data.f_state || data.f_zipcode) && (
                      <>
                        {data.f_city && `${data.f_city}, `}
                        {data.f_state && capitalizeFirstLetter(data.f_state)}
                        {data.f_zipcode && `, ${data.f_zipcode}`}
                      </>
                    )}
                </p>
                {data.f_landline ? <p className="name">Phone No :
                  {data.f_landline}

                </p> : <></>}
                <div className="col-12">
                  {data && data.fooder_gstin && (
                    <p className="gst thank">
                      GST Number : {data && data.fooder_gstin}
                    </p>
                  )}
                </div>

                <table className="tblecss info">

                  <tbody>


                    <tr>
                      <td>
                        Name :{" "}
                        {customerForm.name}
                      </td>

                    </tr>

                    {customerForm.mobile ? <tr>
                      <td>
                        Phone : {" "}
                        {capitalizeFirstLetter(customerForm.mobile)}
                      </td>
                    </tr> : <></>}


                    {customerForm.address && customerForm.address !== '' ? <tr>
                      <td>
                        Address : {" "}
                        {capitalizeFirstLetter(customerForm.address)}
                      </td>
                    </tr> : <></>}
                  </tbody>

                </table>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date :{" "}
                        <span>{printBillDetails && printBillDetails.date}</span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>{printBillDetails && printBillDetails.time}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{orderType}</strong>
                      </td>
                      {orderType === "DINE IN" && (
                        <td className="txtrg">
                          Table No : #
                          <span>
                            {selectedTableDetails
                              ? selectedTableDetails.table_no
                              : ""}
                          </span>
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th colSpan="2">
                        <p
                          style={{
                            textAlign: "center",
                            padding: "5px 2px",
                            margin: "0",
                            fontSize: "medium",
                          }}
                        >
                          Original Receipt
                        </p>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      <th className="thcss qtyth txtct">Qty</th>
                      <th className="thcss rateth txtrg">Rate</th>
                      <th className="thcss amtth txtrg">Amt</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredOrders.map((order, index) => (
                      <tr key={index}>
                        <td className="tdcss nametd">
                          {capitalizeFirstLetter(order.name)}
                          {order.selectedvariants && <>
                            {order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                              <><br /><b>{i.attribute_name}: </b> {i.attribute_value_name} </>
                            ))}
                          </>}

                          {order.selectedAddons && order.selectedAddons.map((i, no) => (
                            <><br /><b>{no + 1}.</b> {i.addon_item_name} </>
                          ))}
                        </td>
                        <td className="tdcss qtytd txtct">{order.quantity}</td>
                        <td className="tdcss ratetd txtrg">
                          {currencySymbol}
                          {isValidPrice(order.newPrice)}
                        </td>
                        <td className="tdcss amttd txtrg">
                          {currencySymbol}
                          {isValidPrice(order.quantity * order.newPrice)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className="tblecss2">
                  <tbody>
                    <tr>
                      <td className="txtrg brtp subtl">Subtotal</td>
                      <td className="txtrg brtp">
                        {currencySymbol}
                        {cartProductSubTotal}
                      </td>
                    </tr>
                    {discountRate &&
                      discountRate !== "0" &&
                      discountType === "0" && (
                        <tr>
                          <td className="txtrg subtl">
                            Discount ({discountRate}%)
                          </td>
                          <td className="txtrg">
                            {currencySymbol}
                            {cartProductDiscount}
                          </td>
                        </tr>
                      )}
                    {discountRate &&
                      discountRate !== "0" &&
                      discountType === "1" && (
                        <tr>
                          <td className="txtrg subtl">
                            Discount ({currencySymbol})
                          </td>
                          <td className="txtrg">
                            {currencySymbol}
                            {cartProductDiscount}
                          </td>
                        </tr>
                      )}
                    {SCHRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">
                          {data.service_charge_details &&
                            data.service_charge_details.name}
                          ({SCHRate}
                          %)
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {/* {schAmount} */}
                          {cartProductServiceCharge}
                        </td>
                      </tr>
                    )}
                    {/* {CGSTRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">CGST({CGSTRate}%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {cgstAmount}
                        </td>
                      </tr>
                    )}
                    {SGSTRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">SGST({SGSTRate}%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {sgstAmount}
                        </td>
                      </tr>
                    )} */}

                    {CGSTTaxT2 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(2.5%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT2}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT2 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(2.5%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT2}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT3 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(6%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT3}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT3 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(6%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT3}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT4 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(9%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT4}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT4 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(9%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT4}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT5 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(14%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT5}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT5 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(14%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT5}
                        </td>
                      </tr>)
                    }

                    {cartProductPackingCharges !== 0 && orderType !== "DINE IN" &&
                      (<tr>
                        <td className="txtrg subtl">Packaging Charges</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {cartProductPackingCharges}
                        </td>
                      </tr>)
                    }

                    <tr>
                      <th className="tlt">Total</th>
                      <th className="tlt txtrg pd0">
                        {currencySymbol}
                        {totalAmountPaid}
                        {/* {cartProductTotal} */}

                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss">
                  <tbody>
                    <tr>
                      <td>
                        {data && data.fssai_number && (
                          <p className="thank">
                            FSSAI Number : {data.fssai_number}
                          </p>
                        )}
                      </td>
                    </tr>
                    {data.payment_type ? <tr>
                      <td>
                        <p className="thank">Paid By {data.payment_type}</p>
                      </td>
                    </tr> : <></>}
                    <tr>
                      <td>
                        <p className="thank">{data.billing_notes}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="thank">Thank you for your visit!</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default POS;