import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import LoadingModal from "../../loader/LoadingModal";
import {
  GetUserDetailsURL,
  ScriptId,
  UpdateFooderStatusURL,
} from "../../assets/api/BaseURL";
import { useToast } from "../../../context/ToastContext";
// import LiveChat from "./LiveChat";

const NavBar = ({ handleToggle }) => {
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [logoutModel, setLogoutModel] = useState(false);

  const [user, setUser] = useState({
    contact_name: "",
    login_email: "",
    notification_email: "",
    username: "",
    notification_mobile: "",
    login_mobile_number: "",
  });

  const capitalizeFirstLetter = (str) => {
    if (str) {
      const words = str.split(" ");

      const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
          return "";
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

      const capitalizedString = capitalizedWords.join(" ");

      return capitalizedString;
    }
  };

  const handleLogout = (tableClear) => {
    // e.preventDefault();

    localStorage.removeItem("token");
    localStorage.removeItem("planDetails");
    localStorage.removeItem("userId");
    localStorage.removeItem("currencySymbol");
    if (tableClear) {
      localStorage.clear()
    }
    const existingScript = document.getElementById(ScriptId);
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
    window.location.href = "/";
  };

  const handleToggleStatus = async (e) => {
    const dataU = { status: e.target.checked ? 1 : 0 };

    setIsLoading(true);
    try {
      const response = await axios({
        method: "put",
        url: UpdateFooderStatusURL,
        data: dataU,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setStatus(dataU.status);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("planDetails")) {
      setPlanDetails(JSON.parse(localStorage.getItem("planDetails")));
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getUser() {
        try {
          const response = await axios.get(GetUserDetailsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setUser(response.data.data);
            setStatus(response.data.status);
            // response.data.data
            localStorage.setItem("restaurantName", response.data.data.contact_name)
            localStorage.setItem("no_of_bill_printing", response.data.data.no_of_bill_printing)
            localStorage.setItem("round_off_amount", response.data.data.round_off_amount)
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      }
      getUser();
    }
  }, [token, addToast]);

  return (
    <>
      <div
        className={logoutModel ? "modal fade show d-block" : "modal fade"}
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content w-85">
            <div className="modal-header py-2">
              <h5 className="modal-title" id="exampleModalLabel">
                Logout
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => { setLogoutModel(false) }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body py-2">
              <div className="clear_and_logout_text_padding">
                <h6>
                  Clicking on the “Clear and Logout” buttons will clear the tables.
                </h6>
              </div>
            </div>


            <div className="modal-footer" style={{ padding: "15px 0px" }}>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-3 valign">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => { handleLogout(false) }}
                  >
                    Logout
                  </button>{' '}

                </div>
                <div className="col-8 valign">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={() => { handleLogout(true) }}
                  >
                    Clear and Logout
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-top">
        <ul className="navbar-nav hwdth">
          <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
            <h1 className="welcome-text">
              Hello,{" "}
              <span className="text-white fw-bold">
                {capitalizeFirstLetter(user.contact_name)}
              </span>
            </h1>
          </li>
        </ul>

        <ul className="navbar-nav ms-auto">
          <li className="d-none d-lg-block">
            <div className="me-4 text-white support">
              <a href="tel:+918885556536" style={{ color: "#fff" }}>
                <i className="fa fa-phone vsalign"></i>
                &nbsp;&nbsp;888-555-6536
              </a>
            </div>
          </li>
          {planDetails && planDetails.plan_name && (
            <li className=" d-none d-lg-block">
              <div className="btn btn-white text-black plannm me-2">
                Current Plan - {planDetails.plan_name}
              </div>
            </li>
          )}
          {planDetails && planDetails.pos === 1 && (
            <>

              <Link to="/pos" className="nav-item  d-none d-md-block d-sm-block">
                <span className="btn btn-primary posbtn me-2">
                  <i className="mdi mdi-monitor-dashboard menu-icon vsalign"></i>{" "}
                  POS
                </span>
              </Link>

              <a href="/kds" className="nav-item  d-none d-md-block d-sm-block">
                <span className="btn btn-dark posbtn me-2">
                  <i className="mdi mdi-silverware-variant menu-icon vsalign"></i>{" "}
                  KDS
                </span>
              </a>
            </>

          )}
          &nbsp;&nbsp;
          {status !== null && (
            <div className="form-group togglecss mb-0 htoggle">
              <label className="text-white">Are you open ?&nbsp;&nbsp;</label>
              <div className="button r" id="button-1">
                <input
                  type="checkbox"
                  className="checkbox"
                  name="status"
                  checked={status === 1 ? true : false}
                  onChange={(e) => handleToggleStatus(e)}
                />
                <div className="knobs"></div>
                <div className="layer"></div>
              </div>
            </div>
          )}
          {/* <li className="nav-item">
            <form className="search-form" action="#">
              <i className="icon-search"></i>
              <input
                type="search"
                className="form-control"
                placeholder="Search Here"
                title="Search here"
              />
            </form>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator"
              id="countDropdown"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="icon-mail icon-lg"></i>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="countDropdown"
            >
              <a className="dropdown-item py-3">
                <p className="mb-0 font-weight-medium float-left">
                  You have 7 unread mails{" "}
                </p>
                <span className="badge badge-pill badge-primary float-right">
                  View all
                </span>
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <img
                    src="images/faces/face10.jpg"
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis font-weight-medium text-dark">
                    Marian Garner{" "}
                  </p>
                  <p className="fw-light small-text mb-0">
                    {" "}
                    The meeting is cancelled{" "}
                  </p>
                </div>
              </a>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <img
                    src="images/faces/face12.jpg"
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis font-weight-medium text-dark">
                    David Grey{" "}
                  </p>
                  <p className="fw-light small-text mb-0">
                    {" "}
                    The meeting is cancelled{" "}
                  </p>
                </div>
              </a>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <img
                    src="images/faces/face1.jpg"
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis font-weight-medium text-dark">
                    Travis Jenkins{" "}
                  </p>
                  <p className="fw-light small-text mb-0">
                    {" "}
                    The meeting is cancelled{" "}
                  </p>
                </div>
              </a>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator"
              id="notificationDropdown"
              href="#"
              data-bs-toggle="dropdown"
            >
              <i className="icon-bell"></i>
              <span className="count"></span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="notificationDropdown"
            >
              <a className="dropdown-item py-3 border-bottom">
                <p className="mb-0 font-weight-medium float-left">
                  You have 4 new notifications{" "}
                </p>
                <span className="badge badge-pill badge-primary float-right">
                  View all
                </span>
              </a>
              <a className="dropdown-item preview-item py-3">
                <div className="preview-thumbnail">
                  <i className="mdi mdi-alert m-auto text-primary"></i>
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject fw-normal text-dark mb-1">
                    Application Error
                  </h6>
                  <p className="fw-light small-text mb-0"> Just now </p>
                </div>
              </a>
              <a className="dropdown-item preview-item py-3">
                <div className="preview-thumbnail">
                  <i className="mdi mdi-settings m-auto text-primary"></i>
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject fw-normal text-dark mb-1">
                    Settings
                  </h6>
                  <p className="fw-light small-text mb-0"> Private message </p>
                </div>
              </a>
              <a className="dropdown-item preview-item py-3">
                <div className="preview-thumbnail">
                  <i className="mdi mdi-airballoon m-auto text-primary"></i>
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject fw-normal text-dark mb-1">
                    New user registration
                  </h6>
                  <p className="fw-light small-text mb-0"> 2 days ago </p>
                </div>
              </a>
            </div>
          </li> */}
          <li className="nav-item dropdown d-none d-lg-block user-dropdown">
            <span
              className="nav-link"
              id="UserDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ cursor: "pointer" }}
            >
              <img
                className="img-xs rounded-circle"
                src="images/faces/profile_face.jpg"
                alt="Profile img"
              />{" "}
            </span>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="UserDropdown"
            >
              <div className="dropdown-header text-center">
                {/* <img className="img-md rounded-circle" src="images/faces/face8.jpg" alt="Profile image"> */}
                <p className="mb-1 mt-3 font-weight-semibold">
                  {capitalizeFirstLetter(user.contact_name)}
                </p>
                {user && user.fooder_uniqueid && (
                  <p className="fw-light text-muted mb-0">
                    {user.fooder_uniqueid}
                  </p>
                )}
                <p className="fw-light text-muted mb-0">{user.login_email}</p>
              </div>
              <Link to="/account_management" className="dropdown-item">
                <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i>{" "}
                My Profile{" "}
                {/* <span className="badge badge-pill badge-danger">1</span> */}
              </Link>
              {/* <a className="dropdown-item">
                <i className="dropdown-item-icon mdi mdi-message-text-outline text-primary me-2"></i>{" "}
                Messages
              </a>
              <a className="dropdown-item">
                <i className="dropdown-item-icon mdi mdi-calendar-check-outline text-primary me-2"></i>{" "}
                Activity
              </a>
              <a className="dropdown-item">
                <i className="dropdown-item-icon mdi mdi-help-circle-outline text-primary me-2"></i>{" "}
                FAQ
              </a> */}
              <Link className="dropdown-item"
                // onClick={handleLogout}
                onClick={() => { setLogoutModel(true) }}
              >
                <i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i>
                Sign Out
              </Link>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center text-white"
          type="button"
          onClick={handleToggle}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>

      <LoadingModal isLoading={isLoading} />

      {/* <LiveChat /> */}
    </>
  );
};

export default NavBar;
