import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import DeleteModal from "../../deleteModal/DeleteModal";
import {
  AddOnlineDeliverySettingsURL,
  DeleteOnlineDeliverySettingsURL,
  GetOnlineDeliverySettingsURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const OnlineOrdering = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [areaCoverage, setAreaCoverage] = useState("10");
  const [formData, setFormData] = useState({
    minRange: "",
    maxRange: "",
    deliveryCharge: "",
    packingCharge: "",
    orderAmount: "",
    maxRangeOptions: [],
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    setDeleteId([id]);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteDeliverySetting = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteOnlineDeliverySettingsURL,
        data: deleteId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setFlag(!flag);
        setShowDeleteModal(!showDeleteModal);
        addToast("success", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.minRange === "") {
      setError("Min Range can not be empty");
      setSuccess("");
    } else if (isNaN(formData.minRange)) {
      setError("Min Range must be a valid number");
      setSuccess("");
    } else if (formData.maxRange === "") {
      setError("Max Range can not be empty");
      setSuccess("");
    }
    // else if (formData.deliveryCharge === "") {
    //   setError("Delivery charge can not be empty");
    //   setSuccess("");
    // }
    else if (parseInt(formData.minRange) > parseInt(formData.maxRange)) {
      setError("Min range should be less then max range");
      setSuccess("");
    } else if (formData.deliveryCharge && isNaN(formData.deliveryCharge)) {
      setError("Delivery charge must be a valid number");
      setSuccess("");
    }
    // else if (formData.packingCharge === "") {
    //   setError("Packing Charges can not be empty");
    //   setSuccess("");
    // }
    else if (formData.packingCharge && isNaN(formData.packingCharge)) {
      setError("Packing Charges must be a valid number");
      setSuccess("");
    }
    // else if (formData.orderAmount === "") {
    //   setError("Order Amount can not be empty");
    //   setSuccess("");
    // }
    else if (formData.orderAmount && isNaN(formData.orderAmount)) {
      setError("Order Amount must be a valid number");
      setSuccess("");
    } else {
      const data = {
        area_coverage: areaCoverage,
        min_range: formData.minRange,
        max_range: formData.maxRange,
        delivery_charge: formData.deliveryCharge,
        packing_charges: formData.packingCharge,
        min_order_amount: formData.orderAmount,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddOnlineDeliverySettingsURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetOnlineDeliverySettingsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setAreaCoverage(response.data.areaCoverage);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
            setAreaCoverage(response.data.areaCoverage);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, addToast]);

  useEffect(() => {
    if (parseInt(areaCoverage) > 0 && areaCoverage !== "") {
      if (!isNaN(areaCoverage)) {
        const maxRangeOptions = Array.from(
          { length: parseInt(areaCoverage) },
          (_, i) => i + 1
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          maxRange: "",
          maxRangeOptions,
        }));
        setError("");
      } else {
        setError("Area Coverage must be a valid number and greater than 0");
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        maxRange: "",
        maxRangeOptions: [],
      }));
      setError("Area Coverage must be a valid number and greater than 0");
    }
  }, [areaCoverage]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="online_ordering" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Delivery Settings</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              {/* <h4 className="card-title card-title-dash mb-3">
                                Delivery Areas
                              </h4> */}
                              <form className="forms-sample mt-2">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-3">
                                    <div className="form-group">
                                      <label>Area Coverage</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Area Coverage"
                                          name="areaCoverage"
                                          value={areaCoverage}
                                          onChange={(e) => {
                                            setAreaCoverage(e.target.value);
                                            setError("");
                                            setSuccess("");
                                          }}
                                        />
                                        <div className="input-group-append">
                                          <span
                                            className="input-group-text text-white"
                                            id="basic-addon2"
                                          >
                                            kms.
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <h4 className="card-title card-title-dash mt-2 mb-3">
                                  Manage Delivery Charges
                                  <small className="text-danger">
                                    (Max 5 Delivery Range Allowed!)
                                  </small>
                                </h4>
                                <div className="row">
                                  <div className="col-4">
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="form-group">
                                          <label>
                                            Min Range
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Min Range"
                                            name="minRange"
                                            value={formData.minRange}
                                            onChange={handleInputChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-group">
                                          <label>
                                            Max Range
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            className="form-select"
                                            name="maxRange"
                                            value={formData.maxRange}
                                            onChange={handleInputChange}
                                          >
                                            <option value="">Max Range</option>
                                            {formData.maxRangeOptions &&
                                              formData.maxRangeOptions.map(
                                                (option, index) => (
                                                  <option
                                                    key={index}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </option>
                                                )
                                              )}
                                          </select>
                                        </div>
                                      </div>

                                      <div className="col-6">
                                        <div className="form-group">
                                          <label>
                                            Delivery Charges
                                            {/* <span className="text-danger">
                                              *
                                            </span> */}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0.00"
                                            name="deliveryCharge"
                                            value={formData.deliveryCharge}
                                            onChange={handleInputChange}
                                          />
                                        </div>
                                      </div>

                                      {/* <div className="col-6">
                                        <div className="form-group">
                                          <label>
                                            Packing Charges
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0.00"
                                            name="packingCharge"
                                            value={formData.packingCharge}
                                            onChange={handleInputChange}
                                          />
                                        </div>
                                      </div> */}

                                      <div className="col-6">
                                        <div className="form-group">
                                          <label>
                                            Order Amount(Min)
                                            {/* <span className="text-danger">
                                              *
                                            </span> */}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0.00"
                                            name="orderAmount"
                                            value={formData.orderAmount}
                                            onChange={handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-8">
                                    <div className="table-responsive">
                                      <table className="table select-table">
                                        <thead>
                                          <tr>
                                            <th>S.No.</th>
                                            <th>Min Range(km)</th>
                                            <th>Max Range(km)</th>
                                            <th>Delivery Charges</th>
                                            {/* <th>Packing Charges</th> */}
                                            <th>Order Amount(Min)</th>
                                            <th className="text-right">
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {data.length === 0 ? (
                                            <tr>
                                              <td
                                                colSpan="6"
                                                className="text-center"
                                              >
                                                No data found
                                              </td>
                                            </tr>
                                          ) : (
                                            data.map((item, index) => (
                                              <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.min_range}</td>
                                                <td>{item.max_range}</td>
                                                <td>
                                                  {item.delivery_charge && (
                                                    <>
                                                      {currencySymbol}{" "}
                                                      {item.delivery_charge}
                                                    </>
                                                  )}
                                                </td>
                                                {/* <td>
                                                  {item.packing_charges && (
                                                    <>
                                                      {currencySymbol}{" "}
                                                      {item.packing_charges}
                                                    </>
                                                  )}
                                                </td> */}
                                                <td>
                                                  {item.min_order_amount && (
                                                    <>
                                                      {currencySymbol}{" "}
                                                      {item.min_order_amount}
                                                    </>
                                                  )}
                                                </td>
                                                <td className="text-right pe-0">
                                                  <i
                                                    className="fs-20 mdi mdi-archive text-danger"
                                                    title="Delete"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) =>
                                                      handleDelete(e, item.id)
                                                    }
                                                  ></i>
                                                </td>
                                              </tr>
                                            ))
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleSubmit}
                                >
                                  Submit
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showDeleteModal && (
            <DeleteModal
              handleDelete={handleDeleteDeliverySetting}
              handleModal={setShowDeleteModal}
            />
          )}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default OnlineOrdering;
