import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import LoadingModal from "../../../loader/LoadingModal";
import DeleteModal from "../../../deleteModal/DeleteModal";
import {
  ScriptId,
  ScriptSRC,
  DeleteInvoiceURL,
  GetInvoiceListURL,
  ApproveInvoiceURL,
  PaymentInvoiceURL,
  GetSingleInvoiceDataURL,
} from "../../../assets/api/BaseURL";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Purchase = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const availableRecordsPerPageOptions = [10, 25, 50, 100];
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [totalrecords, setTotalRecords] = useState(0);
  const [pageNumbersBatch, setPageNumbersBatch] = useState([]);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isSettleModal, setIsSettleModal] = useState(false);
  const [paymentData, setPaymentData] = useState({
    remainingAmount: "",
    paidAmount: "",
    paymentDate: getToday(),
    paymentMode: "Cash",
  });
  const [purchaseData, setPurchaseData] = useState({});
  const [isModal, setIsModal] = useState(false);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateStartSerialNumber = () => {
    return (currentPage - 1) * recordsPerPage + 1;
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  function getToday() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (mm < 10) mm = "0" + mm;
    if (dd < 10) dd = "0" + dd;

    return `${yyyy}-${mm}-${dd}`;
  }

  function convertDateFormat(inputDate) {
    if (!inputDate || inputDate.trim() === "") {
      return "";
    }

    const parts = inputDate.split("-");
    const outputDate = parts[2] + "-" + parts[1] + "-" + parts[0];

    return outputDate;
  }

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleCreateInvoiceClick = (e) => {
    e.preventDefault();
    navigate("/create_purchase");
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("invoiceID", id);
    navigate(`/edit_purchase`);
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    setDeleteId([id]);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteInvoice = async () => {
    try {
      const response = await axios({
        method: "put",
        url: DeleteInvoiceURL,
        data: deleteId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setFlag(!flag);
        setShowDeleteModal(!showDeleteModal);
        addToast("success", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const handleApproveInvoiceClick = (item) => {
    setSelectedInvoice(item);
    setIsApproveModal(true);
  };

  const handleApproveInvoice = async () => {
    try {
      const response = await axios({
        method: "put",
        url: ApproveInvoiceURL,
        data: { id: selectedInvoice.id },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setFlag(!flag);
        setIsApproveModal(false);
        addToast("success", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const handleInvoicePaymentClick = (item) => {
    setSelectedInvoice(item);
    setPaymentData({
      remainingAmount: item.remaining_amount,
      paidAmount: item.remaining_amount,
      paymentDate: getToday(),
      paymentMode: "Cash",
    });
    setIsSettleModal(true);
  };

  const handlePaymentOptionChange = (e) => {
    const { name, value } = e.target;
    setPaymentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInvoicePayment = async () => {
    if (paymentData.paidAmount === "") {
      addToast("error", "Please enter Paid Amount");
    } else if (!isValidNumber(paymentData.paidAmount)) {
      addToast("error", "Please enter valid Paid Amount");
    } else if (paymentData.paymentDate === "") {
      addToast("error", "Please select Payment Date");
    } else if (
      parseFloat(paymentData.paidAmount) >
      parseFloat(paymentData.remainingAmount)
    ) {
      addToast("error", "Paid Amount can not be greater than Remaining Amount");
    } else {
      const dataToSend = {
        id: selectedInvoice.id,
        paid_amount: paymentData.paidAmount,
        payment_date: paymentData.paymentDate,
        payment_mode: paymentData.paymentMode,
      };

      try {
        const response = await axios({
          method: "post",
          url: PaymentInvoiceURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setFlag(!flag);
          setIsSettleModal(false);
          addToast("success", response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    }
  };

  const handleViewDetailsClick = async (id) => {
    try {
      const response = await axios.get(GetSingleInvoiceDataURL, {
        params: {
          id: id,
        },
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data.data) {
        setPurchaseData(response.data.data);
        setIsModal(true);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const downloadPdfDocument = () => {
    const doc = new jsPDF();

    doc.autoTable({
      html: "#pdfContent",
      theme: "grid",
      styles: {
        font: "times",
        fontSize: 9,
        cellPadding: 1,
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: [0, 0, 0],
        halign: "left",
        valign: "middle",
        fontStyle: "bold",
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        halign: "left",
        valign: "middle",
      },
      footStyles: {
        fillColor: [220, 220, 220],
        textColor: [0, 0, 0],
        halign: "left",
        valign: "middle",
        fontStyle: "bold",
      },
      columnStyles: {
        0: { halign: "left" },
        1: { halign: "left" },
        2: { halign: "left" },
        3: { halign: "left" },
        4: { halign: "left" },
        5: { halign: "left" },
      },
      margin: { top: 10, left: 10, right: 10 },
      didParseCell: function (data) {
        if (data.row.section === "body" && data.column.dataKey === 0) {
          data.cell.styles.halign = "left";
        }
      },
    });

    doc.save(`Purchase_Order-${purchaseData.invoiceNumber}.pdf`);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetInvoiceListURL, {
            params: {
              page: currentPage,
              limit: recordsPerPage,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setTotalRecords(response.data.totalRecord);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
            setTotalRecords(0);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, currentPage, recordsPerPage, addToast]);

  useEffect(() => {
    if (totalrecords) {
      const totalPages = Math.ceil(totalrecords / recordsPerPage);
      setTotalPages(totalPages);

      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      } else if (currentPage < 1) {
        setCurrentPage(1);
      }

      const maxPageNumbers = 1;
      let startPage = Math.max(currentPage - maxPageNumbers, 1);
      let endPage = Math.min(currentPage + maxPageNumbers, totalPages);

      if (endPage - startPage + 1 <= maxPageNumbers * 2) {
        if (startPage === 1) {
          endPage = Math.min(
            endPage + (maxPageNumbers * 2 - endPage + startPage - 1),
            totalPages
          );
        } else if (endPage === totalPages) {
          startPage = Math.max(
            startPage - (maxPageNumbers * 2 - endPage + startPage - 1),
            1
          );
        }
      }

      if (currentPage === 1) {
        endPage = Math.min(3, totalPages);
      }

      if (currentPage === totalPages && totalPages >= 3) {
        startPage = Math.max(currentPage - 2, 1);
        endPage = totalPages;
      }

      const batch = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
      setPageNumbersBatch(batch);
    }
  }, [currentPage, recordsPerPage, totalrecords, totalPages]);


 
  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_purchase" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Purchases</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    All Purchases
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-primary text-white mb-0 me-0"
                                    onClick={handleCreateInvoiceClick}
                                  >
                                    <i className="menu-icon mdi mdi-wallet-outline vsalign"></i>
                                    &nbsp;Create Purchase
                                  </span>
                                </div>
                              </div>
                              <div className="table-responsive mt-1">
                                <div>
                                  <label>Show records per page:</label>
                                  <select
                                    value={recordsPerPage}
                                    onChange={(e) =>
                                      setRecordsPerPage(
                                        parseInt(e.target.value)
                                      )
                                    }
                                  >
                                    {availableRecordsPerPageOptions.map(
                                      (option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <table className="table select-table">
                                  <thead>
                                    <tr>
                                      <th>S.No.</th>
                                      <th>Purchase Number</th>
                                      <th>Supplier</th>
                                      <th>Purchase order Date</th>
                                      <th>Due Date</th>
                                      <th>Status</th>
                                      <th>Payment Status</th>
                                      <th>Total</th>
                                      <th className="text-right">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data.length === 0 ? (
                                      <tr>
                                        <td colSpan="7" className="text-center">
                                          No data found
                                        </td>
                                      </tr>
                                    ) : (
                                      data.map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            {calculateStartSerialNumber() +
                                              index}
                                          </td>
                                          <td>
                                            <h6>{item.invoice_number}</h6>
                                          </td>
                                          <td>
                                            <h6>{item.suppliers_name}</h6>
                                          </td>
                                          <td>
                                            <p>
                                              {convertDateFormat(
                                                item.invoice_date
                                              )}
                                            </p>
                                          </td>
                                          <td>
                                            <p>
                                              {convertDateFormat(item.due_date)}
                                            </p>
                                          </td>
                                          <td>
                                            {item.status === 0 && (
                                              <div className="badge badge-opacity-secondary">
                                                Pending
                                              </div>
                                            )}

                                            {item.status === 1 && (
                                              <div className="badge badge-info">
                                                Approved
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            {item.payment_status ? (
                                              <div className="badge badge-info">
                                                Paid
                                              </div>
                                            ) : (
                                              <div className="badge badge-opacity-danger">
                                                Unpaid
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            <p>
                                              {currencySymbol}{" "}
                                              {item.total_amount}
                                            </p>
                                          </td>
                                          <td className="text-right pe-0">
                                            {item.status === 0 && (
                                              <>
                                                <i
                                                  className="fs-20 mdi mdi-check-decagram text-info vsalign"
                                                  title="Approve"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleApproveInvoiceClick(
                                                      item
                                                    );
                                                  }}
                                                ></i>
                                                &nbsp;&nbsp;
                                                <i
                                                  className="fs-20 mdi mdi-content-save-edit-outline text-success vsalign"
                                                  title="Edit"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) =>
                                                    handleEditClick(e, item.id)
                                                  }
                                                ></i>
                                                &nbsp;&nbsp;
                                                <i
                                                  className="fs-20 mdi mdi-archive text-danger vsalign"
                                                  title="Delete"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) =>
                                                    handleDelete(e, item.id)
                                                  }
                                                ></i>
                                              </>
                                            )}
                                            {item.status === 1 &&
                                              item.payment_status === 0 && (
                                                <>
                                                  <i
                                                    className="fs-20 mdi-24px mdi mdi-cash text-primary vsalign"
                                                    title="Payment"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handleInvoicePaymentClick(
                                                        item
                                                      );
                                                    }}
                                                  ></i>
                                                </>
                                              )}

                                            {item.status === 1 && (
                                              <>
                                                &nbsp;&nbsp;
                                                <i
                                                  className="fs-20 mdi-20px mdi mdi-eye text-dark vsalign"
                                                  title="View"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleViewDetailsClick(
                                                      item.id
                                                    );
                                                  }}
                                                ></i>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  {data.length > 0 && (
                                    <p>
                                      Showing{" "}
                                      {(currentPage - 1) * recordsPerPage + 1}{" "}
                                      to{" "}
                                      {(currentPage - 1) * recordsPerPage +
                                        data.length}{" "}
                                      of {totalrecords} entries
                                    </p>
                                  )}
                                </div>
                                <div className="col-6">
                                  <div className="pagination">
                                    <button
                                      onClick={handlePrevPage}
                                      disabled={currentPage === 1}
                                      className={
                                        currentPage === 1 ? "disabled" : ""
                                      }
                                    >
                                      {`<<`}
                                    </button>
                                    {pageNumbersBatch.map((pageNumber) => (
                                      <button
                                        key={pageNumber}
                                        onClick={() =>
                                          handlePageClick(pageNumber)
                                        }
                                        className={
                                          pageNumber === currentPage
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        {pageNumber}
                                      </button>
                                    ))}
                                    <button
                                      onClick={handleNextPage}
                                      disabled={currentPage === totalPages}
                                      className={
                                        currentPage === totalPages
                                          ? "disabled"
                                          : ""
                                      }
                                    >
                                      {`>>`}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              isApproveModal ? "modal fade show d-block" : "modal fade"
            }
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h6 className="modal-title" id="exampleModalLabel">
                    Approve Purchase Order
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsApproveModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div className="container">
                    <h6 className="modal-title" id="exampleModalLabel">
                      Are you sure ? You want to approve{" "}
                      {selectedInvoice && selectedInvoice.invoice_number}{" "}
                      Purchase Order.
                    </h6>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={(e) => {
                      e.preventDefault();
                      handleApproveInvoice();
                    }}
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsApproveModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className={isSettleModal ? "modal fade show d-block" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Payment [{selectedInvoice && selectedInvoice.invoice_number}
                    ]
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsSettleModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div className="container">
                    <form className="forms-sample">
                      <h6 className="card-title m-3 fw-bold ms-0">
                        Remaining Amount : {currencySymbol}{" "}
                        {paymentData &&
                          parseFloat(paymentData.remainingAmount).toFixed(2)}
                      </h6>
                      <div className="row">
                        <div className="col-6">
                          <h5 className="card-title m-3 fw-bold ms-0">
                            Paid Amount
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Amount"
                              name="paidAmount"
                              value={paymentData.paidAmount}
                              onChange={handlePaymentOptionChange}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <h5 className="card-title m-3 fw-bold ms-0">
                            Payment Date
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="date"
                              name="paymentDate"
                              max={getToday()}
                              value={paymentData.paymentDate}
                              onChange={handlePaymentOptionChange}
                            />
                          </div>
                        </div>
                      </div>
                      <h5 className="card-title m-3 fw-bold ms-0 mt-0">
                        Select Payment Mode
                        <span className="text-danger">*</span>
                      </h5>
                      <div className="row">
                        <div className="">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="paymentMode"
                              value="Cash"
                              checked={paymentData.paymentMode === "Cash"}
                              onChange={handlePaymentOptionChange}
                            />
                            <label className="form-check-label">Cash</label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="paymentMode"
                              value="Card"
                              checked={paymentData.paymentMode === "Card"}
                              onChange={handlePaymentOptionChange}
                            />
                            <label className="form-check-label">Card</label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="paymentMode"
                              value="UPI"
                              checked={paymentData.paymentMode === "UPI"}
                              onChange={handlePaymentOptionChange}
                            />
                            <label className="form-check-label">UPI</label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsSettleModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleInvoicePayment();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className={isModal ? "modal fade show d-block" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Purchase Order Details
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setPurchaseData({});
                      setIsModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div className="table-responsive">
                    <table id="pdfContent" className="table table-bordered">
                      <thead>
                        <tr>
                          <td colSpan={3}>
                            <strong>Purchase Number : </strong>
                            {purchaseData && purchaseData.invoiceNumber}
                          </td>
                          <td colSpan={3}>
                            <strong>Purchase Date : </strong>
                            {purchaseData &&
                              convertDateFormat(purchaseData.invoiceDate)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <strong>Supplier : </strong>
                            {purchaseData && purchaseData.suppliers_name}
                          </td>
                          <td colSpan={3}>
                            <strong>Due Date : </strong>
                            {purchaseData &&
                              convertDateFormat(purchaseData.dueDate)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <strong>Reference Number : </strong>
                            {purchaseData && purchaseData.referenceNumber}
                          </td>
                          <td colSpan={3}>
                            <strong>Note : </strong>
                            {purchaseData && purchaseData.note}
                          </td>
                        </tr>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Purchase Price</th>
                          <th>Expiry Date</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchaseData &&
                          purchaseData.items &&
                          purchaseData.items.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.productName}</td>
                              <td>{item.quantity}</td>
                              <td>
                                Rs. {parseFloat(item.purchasePrice).toFixed(2)}
                              </td>
                              <td>{convertDateFormat(item.expiryDate)}</td>
                              <td>Rs. {parseFloat(item.amount).toFixed(2)}</td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" className="text-end">
                            Total
                          </th>
                          <th>
                            Rs. {parseFloat(purchaseData.total).toFixed(2)}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={downloadPdfDocument}
                  >
                    Generate PDF
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setPurchaseData({});
                      setIsModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          {showDeleteModal && (
            <DeleteModal
              handleModal={setShowDeleteModal}
              handleDelete={handleDeleteInvoice}
            />
          )}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default Purchase;
