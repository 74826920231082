import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import {
  GetFoodersCPDetailsURL,
  UpdateFoodersCPDetailsURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';


const FoodersCP = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const logoRef = useRef(null);
  const qrImageRef = useRef(null);
  const featureImageRef = useRef(null);
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [qrImage, setQrImage] = useState("");
  const [featureImage, setFeatureImage] = useState("");
  const [logo, setLogo] = useState("");
  const maxImageSize = 1024 * 1024;
  const maxImageWidth = 500;
  const maxImageHeight = 500;
  const [flag, setFlag] = useState(false);
  const [stateList, setStateList] = useState([]);


  const [openingTime, setOpeningTime] = useState('');
  const [closingTime, setClosingTime] = useState('');


  const [formData, setFormData] = useState({
    fooderName: "",
    fooderName2: "",
    startedOn: "",
    mobile: "",
    landline: "",
    upiAddress: "",
    fssaiNumber: "",
    qrImage: "",
    gstin: "",
    gst_type: 0,
    cgstSgst: "0",
    taxName2: "",
    tax2Value: "0",
    delivery_time: "",
    averageMealPrice: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    logo: "",
    feature_image: "",
    billing_notes: "",
    tax_type: "",
    no_of_bill_printing: "1",
    round_off_amount:0,

    facilities: [
      false, // 0: delivery
      false, // 1: takeOut
      false, // 2: servesVeg
      false, // 3: dineIn
      false, // 4: parking
      false, // 5: bar
      false, // 6: wifi
      false, // 7: acceptsCC
      false, // 8: tableBooking
    ],
    daily_hrs: {
      0: {
        open: "11:00",
        close: "23:00",
      },
      1: {
        open: "11:00",
        close: "23:00",
      },
      2: {
        open: "11:00",
        close: "23:00",
      },
      3: {
        open: "11:00",
        close: "23:00",
      },
      4: {
        open: "11:00",
        close: "23:00",
      },
      5: {
        open: "11:00",
        close: "23:00",
      },
      6: {
        open: "11:00",
        close: "23:00",
      },
    },
  });

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("FOR ONE");

  const handleTagInputChange = (e) => {
    setInputValue(e.target.value);
    setError("");
    setSuccess("");
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
      setError("");
      setSuccess("");
    }
  };

  const handleTagClick = (tagIndex) => {
    const newTags = [...tags];
    newTags.splice(tagIndex, 1);
    setTags(newTags);
    setError("");
    setSuccess("");
  };

  const handleDropdownSelect = (option) => {
    setSelectedOption(option);
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  function findTrueIndices(arr) {
    const trueIndices = [];

    for (let i = 0; i < arr.length; i++) {
      if (arr[i]) {
        trueIndices.push(`${i}`);
      }
    }

    return trueIndices;
  }

  function createArrayFromIndexes(indexes) {
    const resultArray = new Array(9).fill(false);

    for (const index of indexes) {
      if (index >= 0 && index < 9) {
        resultArray[index] = true;
      }
    }

    return resultArray;
  }

  function formatDateToYYYYMMDD(inputDate) {
    const parsedDate = new Date(inputDate);
    const year = parsedDate.getFullYear();
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = parsedDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }


  function formatDate(inputDate) {
    const dateParts = inputDate.split("-");
    if (dateParts.length === 3) {
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const formattedDate = `${day} ${months[parseInt(month) - 1]} ${year}`;
      return formattedDate;
    }

    return inputDate;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(e.target.value)
    setFormData({
      ...formData,
      [name]: value,
    });
    

    setError("");
    setSuccess("");
  };

  const handleChangeFacility = (index, isChecked) => {
    const newFacilities = [...formData.facilities];
    newFacilities[index] = isChecked;
    setFormData({
      ...formData,
      facilities: newFacilities,
    });
    setError("");
    setSuccess("");
  };

  // const handleWorkingHoursChange = (day, timeType, newValue) => {
  //   setFormData((prevData) => {
  //     const updatedData = { ...prevData };
  //     updatedData.daily_hrs[day][timeType] = newValue;
  //     return updatedData;
  //   });
  //   setError("");
  //   setSuccess("");
  // };

  function isClosingBeforeOpening(obj) {
    for (let day in obj) {
      const { open, close } = obj[day];
      const openTime = new Date(`1970-01-01T${open}`);
      const closeTime = new Date(`1970-01-01T${close}`);

      if (closeTime < openTime) {
        return true;
      }
    }

    return false;
  }

  function isImageSizeValid(file) {
    if (file && file.size) {
      return file.size <= maxImageSize;
    }
    return false;
  }

  function isImageDimensionsValid(file) {
    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      return new Promise((resolve) => {
        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const isValidDimensions =
            width <= maxImageWidth && height <= maxImageHeight;

          resolve(isValidDimensions);
        };
      });
    }

    return false;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData.billing_notes)
    const deliveryTimeRegex = /^([0-9]{2})-([0-9]{2})$/;
    const mobileNumberPattern = /^\d{10}$/;
    const zipcodePattern = /^[1-9][0-9]{5}$/;

    if (formData.fooderName === "" || formData.fooderName.trim() === "") {
      setError("Fooder's Name can not be empty");
      setSuccess("");
    }
    // else if (formData.startedOn === "") {
    //   setError("Started On can not be empty");
    //   setSuccess("");
    // } else if (formData.landline === "") {
    //   setError("Landline can not be empty");
    //   setSuccess("");
    // }

    else if (formData.mobile === "" || formData.mobile.trim() === "") {
      setError("Mobile Number can not be empty");
      setSuccess("");
    } else if (!mobileNumberPattern.test(formData.mobile)) {
      setError("Please enter a valid 10-digit mobile number.");
      setSuccess("");
    } else if (
      formData.delivery_time &&
      !deliveryTimeRegex.test(formData.delivery_time)
    ) {
      setError("Delivery Time should be in 00-00 format");
      setSuccess("");
    } else if (
      formData.averageMealPrice &&
      !isValidNumber(formData.averageMealPrice)
    ) {
      setError("Please enter valid Average Meal Price");
      setSuccess("");
    } else if (formData.address === "" || formData.address.trim() === "") {
      setError("Address can not be empty");
      setSuccess("");
    } else if (formData.city === "" || formData.city.trim() === "") {
      setError("City can not be empty");
      setSuccess("");
    } else if (formData.state === "" || formData.state.trim() === "") {
      setError("State can not be empty");
      setSuccess("");
    } else if (formData.zipcode === "" || formData.zipcode.trim() === "") {
      setError("Zip Code can not be empty");
      setSuccess("");
    } else if (!zipcodePattern.test(formData.zipcode)) {
      setError("Please Enter a valid 6-digit Zip Code");
      setSuccess("");
    } else if (logo && !isImageSizeValid(logo)) {
      setError(`Image size can not be greater than ${maxImageSize / 1024}kb`);
      logoRef.current.value = "";
      setLogo("");
      setSuccess("");
    } else if (logo && !(await isImageDimensionsValid(logo))) {
      setError(
        `Image Dimensions can not be greater than ${maxImageWidth}*${maxImageHeight}`
      );
      logoRef.current.value = "";
      setLogo("");
      setSuccess("");
    } else if (qrImage && !isImageSizeValid(qrImage)) {
      setError(`Image size can not be greater than ${maxImageSize / 1024}kb`);
      qrImageRef.current.value = "";
      setQrImage("");
      setSuccess("");
    } else if (qrImage && !(await isImageDimensionsValid(qrImage))) {
      setError(
        `Image Dimensions can not be greater than ${maxImageWidth}*${maxImageHeight}`
      );
      qrImageRef.current.value = "";
      setQrImage("");
      setSuccess("");
    } else if (featureImage && !isImageSizeValid(featureImage)) {
      setError(`Image size can not be greater than ${maxImageSize / 1024}kb`);
      featureImageRef.current.value = "";
      setFeatureImage("");
      setSuccess("");
    } else if (featureImage && !(await isImageDimensionsValid(featureImage))) {
      setError(
        `Image Dimensions can not be greater than ${maxImageWidth}*${maxImageHeight}`
      );
      featureImageRef.current.value = "";
      setFeatureImage("");
      setSuccess("");
    } else if (isClosingBeforeOpening(formData.daily_hrs)) {
      setError(
        "Please Check Working Hrs.Closing Time Must be Greater Than Opening Time"
      );
      setSuccess("");
    }
    else if (parseInt(formData.no_of_bill_printing) <= 0) {
      setError(
        "no of bill printing value more then 0"
      );
      setSuccess("");
    }
    else {

      const dataToSend = new FormData();
      dataToSend.append("name", formData.fooderName);
      dataToSend.append("name2", formData.fooderName2);

      dataToSend.append("started_on", formatDate(formData.startedOn));
      dataToSend.append("mobile", formData.mobile);
      dataToSend.append("landline", formData.landline);
      dataToSend.append("fssai_number", formData.fssaiNumber);
      dataToSend.append("upi_image", qrImage ? qrImage : formData.qrImage);
      dataToSend.append("gstin", formData.gstin);
      dataToSend.append("tax1_value", formData.cgstSgst);
      dataToSend.append("tax2_name", formData.taxName2);
      dataToSend.append("tax2_value", formData.tax2Value);
      dataToSend.append("delivery_time", formData.delivery_time);
      dataToSend.append("average_meal_price", formData.averageMealPrice);
      dataToSend.append("average_meal_person", selectedOption);
      dataToSend.append("specialization", tags.join(", "));
      dataToSend.append("address", formData.address);
      dataToSend.append("city", formData.city);
      dataToSend.append("state", formData.state);
      dataToSend.append("zipcode", formData.zipcode);
      dataToSend.append("billing_notes", formData.billing_notes);
      dataToSend.append("tax_type", formData.tax_type);
      dataToSend.append("no_of_bill_printing", formData.no_of_bill_printing);
      dataToSend.append("round_off_amount", formData.round_off_amount);






      dataToSend.append("logo", logo ? logo : formData.logo);
      dataToSend.append(
        "feature_image",
        featureImage ? featureImage : formData.feature_image
      );
      dataToSend.append(
        "facilities",
        JSON.stringify(findTrueIndices(formData.facilities))
      );
      dataToSend.append("daily_hrs", JSON.stringify(formData.daily_hrs));

      dataToSend.append("open_time", openingTime);
      dataToSend.append("close_time", closingTime);

    
      

      setIsLoading(true);

      try {
        const response = await axios({
          method: "put",
          url: UpdateFoodersCPDetailsURL,
          data: dataToSend,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        });
        setIsLoading(false);

        if (response.status === 200 && response.data.message) {
          setError("");
          setQrImage("");
          setLogo("");
          setFeatureImage("");
          qrImageRef.current.value = "";
          logoRef.current.value = "";
          featureImageRef.current.value = "";
          setSuccess(response.data.message);
          setTimeout(() => {
            setFlag(!flag);
          }, 3000);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
        setIsLoading(false);

      }
    }
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };




  function convertTimeString(timeString) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':');

    // Add leading zeros if necessary
    const formattedTime = [
      hours.padStart(2, '0'),
      minutes.padStart(2, '0'),
      seconds.padStart(2, '0')
    ].join(':');

    return formattedTime;
  }




  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetFoodersCPDetailsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            // console.log(response.data.data.close_time )
            setClosingTime(response.data.data.close_time)
            setOpeningTime(response.data.data.open_time)

            localStorage.setItem('no_of_bill_printing', response.data.data.no_of_bill_printing)
            localStorage.setItem('round_off_amount', response.data.data.round_off_amount)


            


            // alert(response.data.data.no_of_bill_printing)
            setFormData({
              fooderName: response.data.data.name
                ? response.data.data.name
                : "",
              fooderName2: response.data.data.name2
                ? response.data.data.name2
                : "",
              startedOn: response.data.data.started_on
                ? formatDateToYYYYMMDD(response.data.data.started_on)
                : "",
              mobile: response.data.data.mobile
                ? response.data.data.mobile
                : "",
              landline: response.data.data.landline
                ? response.data.data.landline
                : "",
              upiAddress: response.data.data.upi_address
                ? response.data.data.upi_address
                : "",
              fssaiNumber: response.data.data.fssai_number
                ? response.data.data.fssai_number
                : "",
              qrImage: response.data.data.upi_image
                ? response.data.data.upi_image
                : "",
              gstin: response.data.data.gstin ? response.data.data.gstin : "",
              gst_type: response.data.data.gst_type,
              cgstSgst: response.data.data.tax1_value
                ? response.data.data.tax1_value
                : "0",
              taxName2: response.data.data.tax2_name
                ? response.data.data.tax2_name
                : "",
              tax2Value: response.data.data.tax2_value
                ? response.data.data.tax2_value
                : "0",
              delivery_time: response.data.data.delivery_time
                ? response.data.data.delivery_time
                : "",
              averageMealPrice: response.data.data.average_meal_price
                ? response.data.data.average_meal_price
                : "",
              address: response.data.data.address
                ? response.data.data.address
                : "",
              city: response.data.data.city ? response.data.data.city : "",
              state: response.data.data.state ? response.data.data.state : "",
              billing_notes: response.data.data.billing_notes ? response.data.data.billing_notes : "",



              tax_type: response.data.data.tax_type,
              no_of_bill_printing: response.data.data.no_of_bill_printing,
              round_off_amount: response.data.data.round_off_amount,



              zipcode: response.data.data.zipcode
                ? response.data.data.zipcode
                : "",
              logo: response.data.data.logo ? response.data.data.logo : "",
              feature_image: response.data.data.feature_image
                ? response.data.data.feature_image
                : "",
              facilities: response.data.data.facilities
                ? createArrayFromIndexes(response.data.data.facilities)
                : [
                  false, // 0: delivery
                  false, // 1: takeOut
                  false, // 2: servesVeg
                  false, // 3: dineIn
                  false, // 4: parking
                  false, // 5: bar
                  false, // 6: wifi
                  false, // 7: acceptsCC
                  false, // 8: tableBooking
                ],
              daily_hrs: response.data.data.daily_hrs
                ? response.data.data.daily_hrs
                : {
                  0: {
                    open: "11:00",
                    close: "23:00",
                  },
                  1: {
                    open: "11:00",
                    close: "23:00",
                  },
                  2: {
                    open: "11:00",
                    close: "23:00",
                  },
                  3: {
                    open: "11:00",
                    close: "23:00",
                  },
                  4: {
                    open: "11:00",
                    close: "23:00",
                  },
                  5: {
                    open: "11:00",
                    close: "23:00",
                  },
                  6: {
                    open: "11:00",
                    close: "23:00",
                  },
                },
            });
            setStateList(
              response.data.data.state_list ? response.data.data.state_list : []
            );
            setTags(
              response.data.data.specialization
                ? response.data.data.specialization.split(", ")
                : []
            );
            setSelectedOption(
              response.data.data.average_meal_person
                ? response.data.data.average_meal_person
                : "FOR ONE"
            );
            setError("");
            setSuccess("");
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="fooders_cp" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Fooders Cp</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash mb-3">
                                    Add Fooders Cp Details
                                  </h4>
                                </div>
                                <div className="vsalign"></div>
                              </div>
                              <form
                                className="forms-sample"
                                onSubmit={handleSubmit}
                              >
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Fooder's Name
                                        <span className="text-danger">*</span>
                                        <span
                                          className="mx-2"
                                          data-title="This Name will appears on bill"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Fooder Name"
                                        name="fooderName"
                                        value={formData.fooderName}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>


                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Fooder's Name 2

                                        <span
                                          className="mx-2"
                                          data-title="This Name will appears on bill below the Fooder's Name"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Fooder Name"
                                        name="fooderName2"
                                        value={formData.fooderName2}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Started On
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Started On"
                                        name="startedOn"
                                        value={formData.startedOn}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Mobile
                                        <span className="text-danger">*</span>
                                        <span
                                          className="mx-2"
                                          data-title="This Number will appears on customers screen"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>
                                      <div className="input-group mb-3">
                                        <div className="input-group-append">
                                          <span className="input-group-text">
                                            <i className="fa fa-phone text-white"></i>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Mobile"
                                          maxLength={10}
                                          name="mobile"
                                          value={formData.mobile}
                                          onChange={handleInputChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Landline

                                        <span
                                          className="mx-2"
                                          data-title="This Number will appears on bill"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>
                                      <div className="input-group mb-3">
                                        <div className="input-group-append">
                                          <span className="input-group-text">
                                            <i className="fa fa-phone text-white"></i>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Landline"
                                          name="landline"
                                          value={formData.landline}
                                          onChange={handleInputChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label> UPI Address </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter UPI Address"
                                        name="upiAddress"
                                        value={formData.upiAddress}
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label> FSSAI Number

                                        <span
                                          className="mx-2"
                                          data-title="This FSSAI Number will appears on bill"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>



                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter FSSAI Number"
                                        name="fssaiNumber"
                                        value={formData.fssaiNumber}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label> GSTIN
                                        <span
                                          className="mx-2"
                                          data-title="This GSTIN Number will appears on bill"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>

                                      </label>

                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter GSTIN"
                                        name="gstin"
                                        value={formData.gstin}
                                        readOnly={true}
                                      />
                                      <label>
                                        Composition Scheme -{" "}
                                        {formData.gst_type === 0 ? "No" : "Yes"}{" "}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        {" "}
                                        CGST + SGST{" "}
                                        <span
                                          className="mx-2"
                                          data-title="This tax will be consider while calculating the bill"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>
                                      <div className="input-group">
                                        {/* <input
                                          type="text"
                                          className="form-control"
                                          placeholder="CGST + SGST"
                                          name="cgstSgst"
                                          value={formData.cgstSgst}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          %
                                        </span> */}
                                        <select
                                          className="form-select"
                                          name="cgstSgst"
                                          value={formData.cgstSgst}
                                          onChange={handleInputChange}
                                        >
                                          <option value="">Select Tax</option>
                                          <option value="0">0</option>
                                          <option value="5">5</option>
                                          <option value="12">12</option>
                                          <option value="18">18</option>
                                          <option value="28">28</option>


                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        {" "}
                                        Service Charge{" "}
                                        <span
                                          className="mx-2"
                                          data-title="This Name will appears on bill"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Service Charge"
                                        name="taxName2"
                                        value={formData.taxName2}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        {" "}
                                        Service Charge Value{" "}
                                        <span
                                          className="mx-2"
                                          data-title="This tax will be consider while calculating the bill"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Service Charge Value"
                                          name="tax2Value"
                                          value={formData.tax2Value}
                                          onChange={handleInputChange}
                                        />
                                        <span className="input-group-text">
                                          %
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Tax Type
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="veg"
                                            name="tax_type"
                                            value="0"
                                            checked={
                                              parseInt(formData.tax_type) ===
                                              0
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="veg"
                                          >
                                            Single Tax
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id="nonVeg"
                                            name="tax_type"
                                            value="1"
                                            checked={
                                              parseInt(formData.tax_type) ===
                                              1
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="nonVeg"
                                          >
                                            Product Tax
                                          </label>
                                        </div>



                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Average Meal Price</label>
                                      <div className="input-group mb-3 disselect">
                                        <input
                                          type="text"
                                          className="form-control discnt"
                                          placeholder="Enter Average Meal Price"
                                          name="averageMealPrice"
                                          value={formData.averageMealPrice}
                                          onChange={handleInputChange}
                                        />
                                        <button
                                          className="btn btn-outline-secondary dropdown-toggle"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          {selectedOption}
                                        </button>
                                        <ul className="dropdown-menu">
                                          {[
                                            "FOR ONE",
                                            "FOR TWO",
                                            "FOR THREE",
                                            "FOR FOUR",
                                            "FOR FIVE",
                                          ].map((option, index) => (
                                            <li key={index}>
                                              <span
                                                className="dropdown-item"
                                                onClick={() =>
                                                  handleDropdownSelect(option)
                                                }
                                              >
                                                {option}
                                              </span>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Address
                                        <span className="text-danger">*</span>
                                      </label>
                                      <textarea
                                        className="form-control"
                                        placeholder="Enter Address"
                                        rows="1"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        City
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter City"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        State
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">Select State</option>
                                        {stateList &&
                                          stateList.map((item) => (
                                            <option
                                              key={item.id}
                                              value={`${item.id}`}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Zip Code
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Zip Code"
                                        name="zipcode"
                                        value={formData.zipcode}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Delivery Time (min){" "}
                                        <span
                                          className="mx-2"
                                          data-title="Average delivery time range to deliver food in minutes"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="eg. 10-30"

                                        name="delivery_time"
                                        value={formData.delivery_time}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>


                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        No of pages bill print{" "}
                                        <span
                                          className="mx-2"
                                          data-title="No of pages bill print"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-info-circle"></i>
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"

                                        name="no_of_bill_printing"
                                        value={formData.no_of_bill_printing}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Round Off Amount{" "}
                                         
                                      </label>
                                      <br/>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={formData.round_off_amount == 1 ? 0 : 1}
                                        name="round_off_amount"
                                        checked={formData.round_off_amount == 1 ? true : false}
                                        onChange={ 
                                          handleInputChange
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Specialization</label>
                                      <div id="tags">
                                        {tags.map((tag, index) => (
                                          <span
                                            key={index}
                                            onClick={() =>
                                              handleTagClick(index)
                                            }
                                          >
                                            {tag}
                                          </span>
                                        ))}
                                        <input
                                          type="text"
                                          className=""
                                          value={inputValue}
                                          placeholder="Add a tag & hit Enter"
                                          onChange={handleTagInputChange}
                                          onKeyDown={handleInputKeyPress}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label> Choose Logo </label>
                                      <br />
                                      {formData.logo && (
                                        <img
                                          src={formData.logo}
                                          className="mb-2"
                                          alt="logo"
                                          style={{
                                            width: "120px",
                                            height: "auto",
                                          }}
                                        />
                                      )}

                                      <input
                                        className="form-control"
                                        type="file"
                                        accept=".jpeg, .jpg, .png"
                                        id="formFile"
                                        ref={logoRef}
                                        name="logo"
                                        onChange={(e) =>
                                          setLogo(e.target.files[0])
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Upload Payment QR Image </label>
                                      <br />
                                      {formData.qrImage && (
                                        <img
                                          src={formData.qrImage}
                                          className="mb-2"
                                          alt="qrImage"
                                          style={{
                                            width: "120px",
                                            height: "auto",
                                          }}
                                        />
                                      )}

                                      <input
                                        className="form-control"
                                        type="file"
                                        accept=".jpeg, .jpg, .png"
                                        id="formFile"
                                        ref={qrImageRef}
                                        name="qrImage"
                                        onChange={(e) =>
                                          setQrImage(e.target.files[0])
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Upload Feature Image </label>
                                      <br />
                                      {formData.feature_image && (
                                        <img
                                          src={formData.feature_image}
                                          className="mb-2"
                                          alt="feature_image"
                                          style={{
                                            width: "120px",
                                            height: "auto",
                                          }}
                                        />
                                      )}

                                      <input
                                        className="form-control"
                                        type="file"
                                        accept=".jpeg, .jpg, .png"
                                        id="formFile"
                                        ref={featureImageRef}
                                        name="feature_image"
                                        onChange={(e) =>
                                          setFeatureImage(e.target.files[0])
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <span className="card-title card-title-dash mb-3"></span>
                                  {/* <div className="col-3">
                                    <div className="form-group">
                                      <label>
                                        Type
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="type"
                                        value={formData.type}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">Select Type</option>
                                        <option value="1">Restaurant</option>
                                        <option value="2">
                                          Confectioners (Sweets Shop)
                                        </option>
                                        <option value="3">Bakers</option>
                                        <option value="4">Multiplex</option>
                                      </select>
                                    </div>
                                  </div> */}

                                  <div className="col-12">
                                    <div className="form-group">
                                      <h4 className="card-title card-title-dash mb-3">
                                        Billing Notes
                                      </h4>
                                      <div>
                                        <textarea value={formData.billing_notes} name={'billing_notes'} onChange={handleInputChange} className="form-control" id="" cols="30" rows="5"></textarea>





                                      </div>
                                    </div>
                                  </div>



                                  <div className="col-12">
                                    <div className="form-group">
                                      <h4 className="card-title card-title-dash mb-3">
                                        Facilities
                                      </h4>
                                      <div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="delivery"
                                            name="delivery"
                                            checked={formData.facilities[0]}
                                            onChange={(e) =>
                                              handleChangeFacility(
                                                0,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label className="form-check-label">
                                            Delivery
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="takeOut"
                                            name="takeOut"
                                            checked={formData.facilities[1]}
                                            onChange={(e) =>
                                              handleChangeFacility(
                                                1,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label className="form-check-label">
                                            Take Out
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="servesVeg"
                                            name="servesVeg"
                                            checked={formData.facilities[2]}
                                            onChange={(e) =>
                                              handleChangeFacility(
                                                2,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label className="form-check-label">
                                            Serves Veg (only)
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="dineIn"
                                            name="dineIn"
                                            checked={formData.facilities[3]}
                                            onChange={(e) =>
                                              handleChangeFacility(
                                                3,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label className="form-check-label">
                                            Dine In
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="parking"
                                            name="parking"
                                            checked={formData.facilities[4]}
                                            onChange={(e) =>
                                              handleChangeFacility(
                                                4,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label className="form-check-label">
                                            Parking
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="bar"
                                            name="bar"
                                            checked={formData.facilities[5]}
                                            onChange={(e) =>
                                              handleChangeFacility(
                                                5,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label className="form-check-label">
                                            Bar
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="wifi"
                                            name="wifi"
                                            checked={formData.facilities[6]}
                                            onChange={(e) =>
                                              handleChangeFacility(
                                                6,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label className="form-check-label">
                                            Wifi
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="acceptsCC"
                                            name="acceptsCC"
                                            checked={formData.facilities[7]}
                                            onChange={(e) =>
                                              handleChangeFacility(
                                                7,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label className="form-check-label">
                                            Accepts CC
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="tableBooking"
                                            name="tableBooking"
                                            checked={formData.facilities[8]}
                                            onChange={(e) =>
                                              handleChangeFacility(
                                                8,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label className="form-check-label">
                                            Table Booking
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <h4 className="card-title card-title-dash mb-3">
                                  Working Hours
                                </h4>


                                <div className="row">
                                  {/* <div className="col-3">
                                    <div className="form-group">
                                      <p>Opening Time</p>
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={openingTime}
                                        onChange={(e) => { setOpeningTime(e.target.value) }}


                                      />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <p>Closing Time</p>
                                      <input
                                        type="time"
                                        className="form-control"
                                        step="any"
                                        placeholder=""
                                        value={closingTime}
                                        onChange={(e) => { setClosingTime(e.target.value) }}
                                      // min={openingTime}

                                      />
                                    </div>
                                  </div> */}

                                  <div className="col-3">
                                    <div className="form-group">

                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['TimePicker']}>
                                          <TimePicker
                                            views={['hours', 'minutes', 'seconds']}
                                            label="Opening Time"
                                            onChange={(e) => { setOpeningTime(convertTimeString(`${e.$H}:${e.$m}:${e.$s}`)) }}
                                            value={dayjs('2022-04-17T' + openingTime)} />
                                        </DemoContainer>
                                      </LocalizationProvider>

                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">

                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['TimePicker']}>
                                          <TimePicker views={['hours', 'minutes', 'seconds']}
                                            label="Closing Time"
                                            onChange={(e) => { setClosingTime(convertTimeString(`${e.$H}:${e.$m}:${e.$s}`)) }}
                                            // minTime={dayjs('2022-04-17T' + openingTime)}
                                            value={dayjs('2022-04-17T' + closingTime)} />
                                        </DemoContainer>
                                      </LocalizationProvider>

                                    </div>
                                  </div>
                                </div>




                                {/* <div className="row">
                                  <div className="col-1 vsalign">
                                    <div className="form-group">
                                      <label> Sunday </label>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["0"].open}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "0",
                                            "open",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["0"].close}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "0",
                                            "close",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-1 vsalign">
                                    <div className="form-group">
                                      <label> Monday </label>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["1"].open}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "1",
                                            "open",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["1"].close}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "1",
                                            "close",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-1 vsalign">
                                    <div className="form-group">
                                      <label> Tuesday </label>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["2"].open}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "2",
                                            "open",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["2"].close}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "2",
                                            "close",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-1 vsalign">
                                    <div className="form-group">
                                      <label> Wednesday </label>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["3"].open}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "3",
                                            "open",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["3"].close}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "3",
                                            "close",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-1 vsalign">
                                    <div className="form-group">
                                      <label> Thursday </label>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["4"].open}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "4",
                                            "open",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["4"].close}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "4",
                                            "close",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-1 vsalign">
                                    <div className="form-group">
                                      <label> Friday </label>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["5"].open}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "5",
                                            "open",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["5"].close}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "5",
                                            "close",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-1 vsalign">
                                    <div className="form-group">
                                      <label> Saturday </label>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["6"].open}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "6",
                                            "open",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        placeholder=""
                                        value={formData.daily_hrs["6"].close}
                                        onChange={(e) =>
                                          handleWorkingHoursChange(
                                            "6",
                                            "close",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div> */}

                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                >
                                  Submit
                                </button>
                                {/* <button className="btn btn btn-secondary">
                              Cancel
                            </button> */}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default FoodersCP;
